import React from 'react';
import CheckCredential from "./login_components/CheckCredential";
import {UserContext} from "./context/UserContext";
import {Form, Container, Row, Col, Button, InputGroup, Modal, Card} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {Redirect} from "react-router";
import {Page404Context} from "./context/Page404Context";



//Interfaccia delle Credenziali
export interface ICredential {
    email: string,
    password: string
}

export interface IDialog {
    show: boolean,
    message: string
}

const LoginForm = () => {

    //User Context
    const { infos, setInfos }:any = React.useContext(UserContext)
    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false

    //State per le credenziali
    const [credential, setCredential] = React.useState<ICredential>({
        email: "",
        password: ""
    })

    //Gestione Errori
    const [dialog, setDialog] = React.useState<IDialog>({
        show: false,
        message: ""
    })

    //Handler sulla submit della Form
    const OnSubmitHandler = (event: React.FormEvent) => {
        event.preventDefault()
    }

    //Funzione CallBack di Login
    const loginHandler = () => {
        if(credential.email !== "" && credential.password !== "") {
            CheckCredential({
                isLogged: infos.isLogged,
                username: infos.username,
                email: credential.email,
                password: credential.password,
                token: "",
                name: "",
                surname: "",
                message: ""
            }).then((result) => {
                setInfos({
                    isLogged: result.isLogged,
                    username: result.username,
                    email: result.email,
                    token: result.token,
                    name: result.name,
                    surname: result.surname
                })
                if (result.error) {
                    setDialog({
                        show: true,
                        message: result.message
                    })
                    setCredential({
                        email: "",
                        password: ""
                    })
                } else {
                    window.location.href = "/login"
                    // refreshPage()
                }
            })
        }else {
            setDialog({
                show: true,
                message: 'Un campo è vuoto.'
            })
        }
    }

    // Chiudi il messaggio
    const hideDialogHandler =  () => {
        setDialog({
            show: false,
            message: dialog.message
        })
    }

    //Refresh della pagina
    const refreshPage = () => {
        window.location.href = '/'
    }

    //All'invio
    const onKeyPressHandler = (event: React.KeyboardEvent) => {
        if(event.key === 'Enter') {
            loginHandler()
        }
    }

    return(
        <Container fluid={"xl"}>
            {(dialog.show) ? (
                <Modal show={dialog.show} onHide={ () => hideDialogHandler() } centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Attenzione!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {dialog.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={"shadow rounded-0"} variant="primary" onClick={ () => hideDialogHandler() }>
                            CHIUDI
                        </Button>
                    </Modal.Footer>
                </Modal>
            ): null}
            { !infos.isLogged ? (
                    <div className={"mt-5"}>
                        <Row>
                            <Col sm={3} />
                            <Col sm={6} >
                                <Card className={"shadow"}>
                                    <Card.Header className={"py-3"}>
                                        <h6 className={"m-0 font-weight-bold text-secondary"}>CREDENZIALI DI ACCESSO</h6>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form onSubmit={OnSubmitHandler}>
                                            <Form.Group controlId={"formEmail"}>
                                                <Form.Label>Email</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faEnvelope} />
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"email"}
                                                        placeholder={"Inserisci l'indirizzo mail"}
                                                        onChange={(event) => {
                                                            setCredential({
                                                                email: event.target.value,
                                                                password: credential.password
                                                            });
                                                        }}
                                                        value={credential.email}
                                                        onKeyPress={onKeyPressHandler}
                                                    ></Form.Control>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group controlId={"formPassword"} >
                                                <Form.Label>Password</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faKey} />
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"password"}
                                                        placeholder={"Inserisci la password"}
                                                        onChange={(event) => {
                                                            setCredential({
                                                                email: credential.email,
                                                                password: event.target.value
                                                            });
                                                        }}
                                                        value={credential.password}
                                                        onKeyPress={onKeyPressHandler}
                                                    ></Form.Control>
                                                </InputGroup>
                                            </Form.Group>
                                            <div className={"mt-4"}>
                                                <Button
                                                    className={"shadow rounded-0"}
                                                    variant="lg btn-primary btn-block"
                                                    onClick={ (loginEvent) => loginHandler() }
                                                >ENTRA</Button>
                                            </div>
                                            <div className={"mt-4 float-right"}>
                                                <label>Se vuoi Registrarti, clicca <a href={"/registration"}>qui</a></label>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={3} />
                        </Row>
                    </div>
                ):
                (
                    <Redirect push to={'/'}/>
                )
            }
        </Container>
    )

}

export default LoginForm;