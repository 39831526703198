import Axios from "axios";

interface IProps {
    name: string,
    idprogram: number,
    filterid: number,
    affidate2: string,
    endpoint: string,
}

async function AjaxRetrievePipeData(props:IProps) {

    let results = "";

    try {
        await
            Axios.request({
                method: "GET",
                url: props.endpoint,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                params: {
                    name: props.name,
                    idprogram: props.idprogram,
                    filterid: props.filterid,
                    affidate2: props.affidate2,
                }
            }).then((response) => {
                if(response.status == 200) {
                    results = response.data;
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default AjaxRetrievePipeData;