import React from 'react';
import CheckTokenExpired from "../AjaxCheckTokenExpired";
import {UserContext} from "./context/UserContext";
import {TokenContext} from "./context/TokenContext";
import {Redirect} from "react-router";

const CheckToken = () => {
    // User Context
    const { infos, setInfos }:any = React.useContext(UserContext)
    const { isValidToken, setIsValidToken }:any = React.useContext(TokenContext)

    // Check Token Validity
    const TokenValidityCheck = () => {
        CheckTokenExpired({
            token: infos.token
        }).then( response => {
            // If token is invalid, redirect to login
            if(response.TokenIsValid === false) {
                localStorage.removeItem("credential")
                setIsValidToken({
                    isValidToken: false
                })
                setInfos({
                    isLogged: false,
                    username: "",
                    email: "",
                    token: "",
                    loggin: true,
                    registration: false
                })
            }
            if(response.TokenIsValid === true) {
                setIsValidToken({
                    isValidToken: true
                })
            }
        })
    }

    React.useEffect( TokenValidityCheck,[])
    return(
        (isValidToken ? null : <Redirect push to={"/login"} />)
    )
}

export default CheckToken