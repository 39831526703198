import React from 'react';
import {Badge, Button, Grid, Tooltip} from "@material-ui/core";
import {DeleteForever, Edit, Info, Web} from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import GetFieldsList from "./AjaxFieldsList";
import {UserContext} from "../context/UserContext";
import Modal from "react-bootstrap/cjs/Modal";
import FieldEdit from "./FieldEdit";
import updateFieldEdit from "./AjaxUpdateFieldEdit";
import deleteExtraField from "./AjaxDeleteExtraField";

interface IProps {
    formsList:  IForm[]
}

interface IForm {
    label:  string,
    value:  any,
    key:    string
}

interface IField {
    id:             number,
    id_api:         string,
    name:           string,
    type:           string,
    label:          string,
    pholder:        string,
    extra_options:  string,
    forms_list:     string,
}

interface IDialogOpt {
    show:       boolean,
    message:    string,
    response:   string,
    value:      any,
    onlyInfo:   boolean
}

const TableFields = (props: IProps) => {

    //User Context
    const {infos, setInfos}: any = React.useContext(UserContext)
    //Fields UseState
    const [fieldsList, setFieldsList] = React.useState<IField[]>([])

    const RetrieveFieldsList = () => {
        GetFieldsList({
            token: infos.token
        }).then((response) => {
            if (response !== undefined) {
                response.map(function (value, index) {
                    setFieldsList(prevState => [
                        ...prevState,
                        {
                            id:             value.id,
                            id_api:         value.id_api,
                            type:           value.type,
                            name:           value.name,
                            label:          value.label,
                            pholder:        value.pholder,
                            extra_options:  value.extra_options,
                            forms_list:     value.forms_list
                        }
                    ])
                    return index
                })
            }
        })
    }

    React.useEffect(RetrieveFieldsList, [])

    //Data
    const [dataEdit, setDataEdit] = React.useState<IField>()

    //Selected Data Row
    const [selectedRow, setSelectedRow] = React.useState({
        id:             0,
        id_api:         "",
        name:           "",
        type:           "",
        label:          "",
        pholder:        "",
        extra_options:  "",
        forms_list:     []
    })

    //Modal
    const [dialogOpt, setDialogOpt] = React.useState<IDialogOpt>({
        show: false,
        message: "",
        response: "",
        value: "",
        onlyInfo: false
    })

    //Edid Dialog
    const [dialogEdit, setDialogEdit] = React.useState({
        show: false,
        index: 0
    })

    const handleClose = () => {
        setDialogOpt(prevState => {
            return {
                show: false,
                message: "",
                response: "",
                value: "",
                onlyInfo: prevState.onlyInfo
            }
        })
        setDialogEdit({
            show: false,
            index: 0
        })
    }

    const handleYes = () => {
        switch (dialogOpt.response) {
            case "deleteRecord":
                handleClose();
                    deleteExtraField({
                        token: infos.token,
                        data: {
                            id: dialogOpt.value.id,
                            name: dialogOpt.value.name
                        }
                    }).then( (response) => {
                        if (response.type === 'Success') {
                            setDialogOpt({
                                show: true,
                                onlyInfo: true,
                                message: response.message,
                                response: "deleteExtraField",
                                value: ""
                            })
                            setFieldsList(fieldsList.filter( (field:IField) => field.id !== dialogOpt.value.id ));
                        }else {
                            setDialogOpt({
                                show: true,
                                onlyInfo: true,
                                message: response.message,
                                response: "deleteExtraField",
                                value: ""
                            })
                        }
                    })
                break;
            default:
                handleClose()
                return
        }
    }

    const handleList = (value: any) => {
        let message: string = ""
        if (value.length > 0) {
            value.map((value: number, index: number) => {
                props.formsList.filter(name => parseInt(name.value) === value).map(filteredName => {
                    if (index === 0) {
                        message += filteredName.label
                    } else {
                        message += ", " + filteredName.label
                    }
                    return filteredName;
                })
            })
        } else {
            message = "Nessuna maschera selezionata"
        }
        openDialog(
            message,
            "listRecord",
            0,
            true
        )
    }

    const openDialog = (message: string, response: string, value: any, onlyInfo: boolean) => {
        setDialogOpt(prevState => {
            return {
                show: true,
                message: message,
                response: response,
                value: value,
                onlyInfo: onlyInfo
            }
        })
    }

    const deleteRecord = (index: number, name: string) => {
        openDialog(
            "Vuoi davvero eliminare il campo selezionato?",
            "deleteRecord",
            {
                id: index,
                name: name
            },
            false
        )
    }

    const editRecord = (index: number, value: any) => {
        //Recupero dalla riga selezionata gli id
        let formsListSelected = JSON.parse(value.rowData[7]);
        let ids: any = []
        if (formsListSelected.id.length > 0) {
            formsListSelected.id.map( (value: number, index: number) => {
                props.formsList.filter( name => parseInt(name.value) === value ).map(
                    filteredName => {
                        ids.push(filteredName);
                        return filteredName;
                    }
                )
                return index;
            })
        }
        setSelectedRow({
            id:             value.rowData[0],
            id_api:         value.rowData[1],
            name:           value.rowData[2],
            type:           value.rowData[3],
            label:          value.rowData[4],
            pholder:        value.rowData[5],
            extra_options:  value.rowData[6],
            forms_list:     ids
        })
        setDialogEdit({
            show: true,
            index: index
        })
    }

    const updateField = (data:any) => {
        if(data !== undefined) {
            fieldsList.map((field: IField, index: number) => {
                if (field.id == data.id) {
                    field.label         = data.label;
                    field.type          = data.type;
                    field.name          = data.name;
                    field.extra_options = data.extra_options;
                    field.pholder       = data.pholder;
                    field.forms_list    = fromHookForm2Array(data.forms_list);
                }
                return index;
            });
            updateFieldEdit({
                token: infos.token,
                data: {
                    id: parseInt(data.id),
                    name: data.name,
                    type: data.type,
                    forms_list: data.forms_list,
                    label: data.label,
                    pholder: data.pholder,
                    extra_options: data.extra_options
                }
            }).then( (result) => {
                setDialogOpt({
                    show: true,
                    onlyInfo: true,
                    message: result.message,
                    response: "updateField",
                    value: ""
                })
            })
        }
        setDataEdit(undefined)
    }

    const showApi = (api:string) => {
        setDialogOpt({
            show: true,
            onlyInfo: true,
            message: api,
            response: "showApi",
            value: ""
        })
    }

    const fromHookForm2Array = (formsList: []): string => {
        let jsonString: string = "";
        let ids = {id: [0]};
        ids.id = [];
        if(formsList !== undefined) {
            formsList.map( (form: IForm, index: number) => {
                ids.id.push(parseInt(form.value));
                return ids;
            })
        }
        jsonString = JSON.stringify(ids);
        return jsonString;
    }

    const columns = [
        {name: "id", label: "ID"},
        {name: "id_api", label: "API", options:{display: false}},
        {name: "name", label: "Nome"},
        {name: "type", label: "Tipo"},
        {name: "label", label: "Etichetta", options:{display: false}},
        {name: "pholder", label: "Segnaposto", options:{display: false}},
        {name: "extra_options", label: "Opzioni"},
        {
            name: "forms_list", label: "Presente in",
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    let nforms: number = (JSON.parse(value).id).length;
                    return (
                        <Grid>
                            <Tooltip title={"Lista Maschere"} onClick={() => {
                                handleList(JSON.parse(value).id)
                            }}>
                                <Badge badgeContent={nforms} color="error">
                                    <Web/>
                                </Badge>
                            </Tooltip>
                        </Grid>
                    )
                }
            }
        },
        {
            name: "Azioni",
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <Grid
                            container
                            item
                            xs={8}
                            alignItems={"center"}
                            justify={"space-between"}
                            direction={"row"}
                        >
                            <Tooltip title={"Modifica"} onClick={() => editRecord(tableMeta.rowData[0], tableMeta)}>
                                <Edit/>
                            </Tooltip>
                            <Tooltip title={"Elimina"} onClick={() => deleteRecord(tableMeta.rowData[0], tableMeta.rowData[2])}>
                                <DeleteForever color={"error"}/>
                            </Tooltip>
                            <Tooltip title={"Mostra Api"} onClick={() => showApi(tableMeta.rowData[1])}>
                                <Info color={"inherit"} />
                            </Tooltip>
                        </Grid>
                    )
                },
            },
        },
    ]

    return (
        <div>
            <Modal show={dialogOpt.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{dialogOpt.message}</p>
                </Modal.Body>
                <Modal.Footer>
                    {dialogOpt.onlyInfo ?
                        <Button onClick={handleYes}>OK</Button>
                        : (<div>
                            <Button onClick={handleYes}>SI</Button>
                            <Button onClick={handleClose}> NO </Button>
                        </div>)
                    }
                </Modal.Footer>
            </Modal>
            <Modal show={dialogEdit.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modifica Campo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FieldEdit
                        formsList={props.formsList}
                        rowData={selectedRow}
                        data={setDataEdit}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        updateField(dataEdit)
                        handleClose();
                    }}>MODIFICA</Button>
                    <Button onClick={() => {
                        setDataEdit(undefined)
                        handleClose();
                    }}>ANNULLA</Button>
                </Modal.Footer>
            </Modal>
            <MUIDataTable
                title={"Lista Campi Extra"}
                data={fieldsList}
                columns={columns}
                options={{
                    selectableRows: "none",
                    filter: true,
                    filterType: 'dropdown',
                    responsive: 'vertical',
                    fixedHeader: true,
                    fixedSelectColumn: true,
                    tableBodyHeight: '400px',
                    draggableColumns: {
                        enabled: true
                    },
                    expandableRowsOnClick: true,
                    textLabels: {
                        body: {
                            noMatch: "Mi dispiace, nessuna corrispondenza trovata",
                            toolTip: "Filtra",
                            columnHeaderTooltip: column => `Sort for ${column.label}`
                        },
                        pagination: {
                            next: "Pagina Seguente",
                            previous: "Pagina Precedente",
                            rowsPerPage: "Righe per pagina:",
                            displayRows: "di",
                        },
                        toolbar: {
                            search: "Cerca",
                            downloadCsv: "Download CSV",
                            print: "Stampa",
                            viewColumns: "Schermata Colonne",
                            filterTable: "Filtra Tabella",
                        },
                        filter: {
                            all: "Tutto",
                            title: "FILTRI",
                            reset: "RESET",
                        },
                        viewColumns: {
                            title: "Mostra Colonne",
                            titleAria: "Mostra/Nascondi Colonne Tabella",
                        },
                        selectedRows: {
                            text: "riga(righe) selezionata/e",
                            // delete: "Elimina",
                            deleteAria: "Elimina righe selezionate",
                        },
                    }
                }}
            ></MUIDataTable>
        </div>
    )
}

export default TableFields