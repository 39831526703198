import React from 'react';
import {Navbar, Nav, NavDropdown} from "react-bootstrap";
import {faUserCog} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {UserContext} from "./context/UserContext";
import LogOut from "./login_components/LogOut";
import "./css/dropdown.css";
import GetFormsList from "./forms_components/AjaxFormsList";
import {Redirect} from "react-router";
import Axios from "axios";
import {sosglobal} from "../sosGlobal";

interface IForm {
    id:        number,
    name:      string,
    position:  string,
    hide_to:   string
}

const NavBar = () => {

    //User Context
    const { infos, setInfos }:any = React.useContext(UserContext)

    //Masks Array
    const [masks, setMasks] = React.useState<IForm[]>([])

    //Funzione CallBack di LogOut
    const logOutHandler = () => {
        LogOut({
            isLogged: infos.isLogged,
            username: infos.username,
            email:    infos.email,
            token:    infos.token
        }).then((result) => {
            setInfos({
                isLogged: false,
                username: "",
                email: "",
                token: "",
                loggin: true,
                registration: false
            })
            localStorage.removeItem('credential')
            window.location.href = '/login'
        });
    }

    async function UpdateRecords() {
        alert("Aggiornamento in corso in modalità sileziosa, verrà mostrato un avviso automatico sull'esito dell'aggiornamento.");
        try {
            await Axios.request({
                method: 'GET',
                url: sosglobal.apiUrl + 'test/get-pipedrive',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
            }).then( (response:any) => {
                if(response.status === 200) {
                    alert(response.data.message);
                }
            }).catch( error => {
                console.error(error)
            }).finally(

            )
        } catch (err) {
            console.log(err);
        }
    }

    const RetrieveFormsList = () => {
        if(infos.token !== "") {
            GetFormsList({
                token: infos.token
            }).then((results: IForm[]) => {
                results.map(function (mask, index) {
                    setMasks(prev => ([
                        ...prev,
                        mask
                    ]))
                    return index
                })
            })
        }
    }

    React.useEffect(RetrieveFormsList, [])

    return(
        (infos.isLogged) ? (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand href="/">SOS-Avvocato</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Item id={"dropdown-center"}>
                            <NavDropdown title="Pratiche" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/records-list">Lista Pratiche</NavDropdown.Item>
                                <Nav.Item className={"nav-item dropright"}>
                                    <NavDropdown title={"Maschere"} id={"dropdown-sub"}>
                                        {
                                            (masks.length === 0) ? <NavDropdown.Item href={""}>Nessuna Maschera Presente</NavDropdown.Item> :
                                                masks.map( (mask, index) => {
                                                    return(
                                                        <NavDropdown.Item key={index} href={"/forms-view/"+mask.id}>{mask.name}</NavDropdown.Item>
                                                    )
                                                })
                                        }
                                    </NavDropdown>
                                </Nav.Item>
                            </NavDropdown>
                        </Nav.Item>
                        <Nav.Link href="/search">Ricerca Avanzata</Nav.Link>
                        <Nav.Link href="/agende">Agende</Nav.Link>
                        <Nav.Link href="#" onSelect={UpdateRecords}>Aggiorna Record</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href="/profile">Ciao {infos.username}</Nav.Link>
                        <Nav.Item className={"nav-item dropdown-left"}>
                            <NavDropdown title={<FontAwesomeIcon icon={faUserCog}/>} id="dropdown-user">
                                <Nav.Item className={"nav-item dropleft dropdown-left"}>
                                    <NavDropdown title={"Impostazioni"} id={"dropdown-sett"}>
                                        <NavDropdown.Item href="/team">Gestione Team</NavDropdown.Item>
                                        <NavDropdown.Item href="/forms">Gestione Maschere</NavDropdown.Item>
                                        <NavDropdown.Item href="/fields">Gestione Campi Extra</NavDropdown.Item>
                                        <NavDropdown.Item href="/order-fields">Gestione Ordine Campi</NavDropdown.Item>
                                        <NavDropdown.Item href="/esapi">Gestione Api Esterne</NavDropdown.Item>
                                        <NavDropdown.Item href="/qbuilder">Gestione Estrazioni</NavDropdown.Item>
                                        <NavDropdown.Item href="/pipedrive">Gestione PipeDrive</NavDropdown.Item>
                                        <NavDropdown.Item href="/options">Opzioni</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav.Item>
                                <NavDropdown.Item href="">Fatturazione</NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item href="" onClick={(e) => logOutHandler()}>ESCI</NavDropdown.Item>
                            </NavDropdown>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        ) : <Redirect push to={"/login"} />
    )
}

export default NavBar