export interface IStaticFieldsArray {
    api:       string,
    name:      string,
    label:     string,
    inputType: string,
    extraf:    boolean
}

export const staticFieldsArray:IStaticFieldsArray[] = [
    {
        api: "",
        name: "affida_pratica",
        label: "Affida Pratica",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "data_affidamento",
        label: "Data Affidamento",
        inputType: "date",
        extraf: false
    },
    {
        api: "",
        name: "avvocato",
        label: "Avvocato",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "domiciliatario",
        label: "Domiciliatario",
        inputType: "dropdown",
        extraf: false
    },
    {
        api: "",
        name: "referente",
        label: "Referente",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "data_acquisizione",
        label: "Data Acquisizione",
        inputType: "date",
        extraf: false
    },
    {
        api: "",
        name: "tematica",
        label: "Tematica",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "problematica",
        label: "Problematica",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "provenienza",
        label: "Provenienza",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "stato_pratica",
        label: "Stato Pratica",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "nome",
        label: "Nome",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "cognome",
        label: "Cognome",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "controparte",
        label: "Controparte",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "codice_fiscale",
        label: "Codice Fiscale",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "data_nascita",
        label: "Data di Nascita",
        inputType: "date",
        extraf: false
    },
    {
        api: "",
        name: "luogo_nascita",
        label: "Luogo di Nascita",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "sesso",
        label: "Sesso",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "indirizzo",
        label: "Indirizzo",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "citta",
        label: "Città",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "provincia",
        label: "Provincia",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "cap",
        label: "Cap",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "regione",
        label: "Regione",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "scadenza_ci",
        label: "Scadenza CI",
        inputType: "date",
        extraf: false
    },
    {
        api: "",
        name: "procura",
        label: "Procura",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "priorita",
        label: "Priorità",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "punto_sos",
        label: "Punto SOS",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "fan",
        label: "Fan",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "telefono",
        label: "Telefono",
        inputType: "number",
        extraf: false
    },
    {
        api: "",
        name: "recapito_alternativo",
        label: "Recapito Alternativo",
        inputType: "number",
        extraf: false
    },
    {
        api: "",
        name: "email",
        label: "Email",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "iban",
        label: "Iban",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "appunti",
        label: "Appunti",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "note",
        label: "Note",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "allegati",
        label: "Allegati",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name: "messaggio",
        label: "Messaggio",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name:  "descrizione_fatti",
        label: "Descrizione Dei Fatti",
        inputType: "text",
        extraf: false
    },
    {
        api: "",
        name:  "file_caricati",
        label: "File Caricati",
        inputType: "text",
        extraf: false
    }
]