import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    token:   string,
    id:      number,
}

interface IField {
    name:           string,
    label:          string,
    type:           string,
    extra_field:    boolean
}

interface IResult {
    id:      string,
    type:    string,
    message: string,
    data:    IField[]
}

async function DeleteFieldsOrderList(props: IProps) {

    let results:IResult = {
        id:      "",
        type:    "",
        message: "",
        data:    []
    };

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "api/delete-fields-order-list",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token:   props.token,
                    id:      props.id
                })
            }).then( (response:any) => {
                if(response.status === 200) {
                    results = response.data;
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default DeleteFieldsOrderList;