import Axios from 'axios';
import {sosglobal} from "../../sosGlobal";

interface IProps {
    token: string
}

interface IUser {
    email:    string
    name:     string,
    surname:  string,
    fullName: string,
}

interface IResult {
    id: string,
    type: string,
    message: string,
    data: IUser[]
}

async function RetrieveUsersList(props:IProps) {

    let results: IResult = {
        id: "",
        type: "",
        message: "",
        data: []
    };

    try {
        await
            Axios.request({
                method: 'POST',
                url: sosglobal.apiUrl + 'api/retrieve_users_list',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data:   JSON.stringify({
                    token:  props.token
                })
            }).then( (response:any) => {
                if(response.status === 200) {
                    results = response.data;
                }
            })
    } catch (err) {
        console.log(err);
    }

    return(results)
}

export default RetrieveUsersList;