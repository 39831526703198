import Axios from 'axios';
import {sosglobal} from "../../sosGlobal";

export interface IProps {
    token:      string,
    user_id:    string,
    error?:     boolean,
    errnum?:    number
}

export interface IUser {
    id: string,
    name: string,
    surname: string,
    email: string
}

async function DeleteUser(props:IProps) {

    try {
        await
            Axios.request({
                method: 'POST',
                url: sosglobal.apiUrl + 'api/user-delete',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token: props.token,
                    user_id: props.user_id
                })
            }).then((response) => {
                if(response.data !== "" && response.data.id === 0) {
                    props.error  = false
                    props.errnum = 0
                    // console.log(response.data)
                } else {
                    props.error  = true
                    props.errnum = response.data.id
                    // console.log(response.data)
                }
            })
    } catch (err) {
        console.log(err);
    }

    return(props)
}

export default DeleteUser;