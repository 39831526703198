import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    token:      string
}

interface IField {
    api:       string,
    name:      string,
    label:     string,
    inputType: string,
    extraf:    boolean
}

interface IResult {
    id:      string,
    type:    string,
    message: string,
    data:    IField[]
}

async function RetrieveExtraFieldsList(props: IProps) {

    let results:IResult = {
        id:      "",
        type:    "",
        message: "",
        data:    []
    };

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "api/retrieve_extra_fields_list",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data:   JSON.stringify({
                    token:  props.token
                })
            }).then( (response:any) => {
                if(response.status === 200) {
                    results = response.data;
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default RetrieveExtraFieldsList;