import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    token:      string,
    user_id:    number
}

async function GetSingleUserInfos(props:IProps) {

    let resp = {
            id:      0,
            type:    "",
            data:    "",
            message: ""
    }

    try {
        await
        Axios.request({
            method: 'POST',
            url: sosglobal.apiUrl + 'api/get_single_user_infos',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=UTF-8",
            },
            params: {
                user_id: props.user_id
            },
            data: JSON.stringify({
                token:    props.token
            })
        }).then((response) => {
            if(response.data !== "") {
                resp = response.data
            }
        })
    }catch(err) {
        console.log(err)
    }

    return resp
}

export default GetSingleUserInfos;