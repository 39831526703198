import React from 'react';
import {RouteComponentProps} from "react-router-dom";
import GetQueries from "./AjaxGetQueries";
import GetMenu from "./AjaxGetMenu";
import PrintMenu from "./PrintMenu";

interface IRouter {
    menu:    string,
    submenu: string,
}

interface IProps {
    token: string,
    route: RouteComponentProps<IRouter> | any
}

interface IMenu {
    type:         string,
    name:         string,
    position:     number,
}

interface IQData {
    id:         number,
    user_id:    number,
    name:       string,
    menu:       string,
    btn_menu:   string,
    submenu:    string,
    position:   number,
    jdata:      string,
}

const MenuDashboard = (props:IProps) => {

    const [menu, setMenu] = React.useState<IMenu[]>([]);
    const [submenu, setSubmenu] = React.useState<IMenu[]>([]);
    const [btns, setBtns] = React.useState<IQData[]>([]);
    const [isMenu, setIsMenu] = React.useState<boolean>(false);
    const [isSubmenu, setIsSubmenu] = React.useState<boolean>(false);
    const CompareMenu = (first:IMenu, next:IMenu) => {
        if(first.position < next.position) {
            return -1;
        }
        else {
            return 1;
        }
    }

    const Init = () => {
        if(props.route.params.menu !== undefined) { setIsMenu(true); setIsSubmenu(false) }
        if(props.route.params.submenu !== undefined) { setIsMenu(false); setIsSubmenu(true) }
        GetMenu({
            token: props.token,
            data: "{}"
        }).then( results => {
            if(results.type === "Success") {
                setMenu([]);
                setSubmenu([]);
                let lmenu:IMenu[] = JSON.parse(results.data);
                lmenu.sort(CompareMenu);
                lmenu.map( (item:IMenu, index:number) => {
                    switch (item.type) {
                        case "MENU":
                            setMenu((prevState:IMenu[]) => [
                                ...prevState,
                                item
                            ]);
                            break;
                        case "SUBMENU":
                            setSubmenu(prevState => [
                                ...prevState,
                                item
                            ])
                            break;
                    }
                });
            }
        });
        GetQueries({
            token:  props.token,
            data:   "{}"
        }).then( result => {
            if(result.type === "Success") {
                let duplicates:[string] = [""], found;
                setBtns([]);
                let qdata:IQData[] = JSON.parse(result.data);
                qdata.sort((a:IQData, b:IQData) => ( a.position - b.position ));
                qdata.map( (query:IQData) => {
                    found = duplicates.find( element => element == query.submenu )
                    if (found == null || found == undefined || found == "") {
                        setBtns((prevState: IQData[]) => [
                            ...prevState,
                            query
                        ])
                        if(props.route.params.menu == undefined) {
                            duplicates.push(query.submenu);
                        }
                    }
                })
            }
        });
    }
    React.useEffect(Init, []);

    return(
        <div>
            { (isMenu === false && isSubmenu === false) ?
                <PrintMenu
                    arrayMenu={menu}
                    queries={btns}
                    type={{menu: true}}
                />
                :
                <div>
                    {(isMenu) ?
                        <PrintMenu
                            arrayMenu={submenu.filter( (fsmenu:IMenu) => fsmenu.name == props.route.params.menu)}
                            queries={btns}
                            type={{submenu: true}}
                        />
                        :
                    (isSubmenu) ?
                        <div>Non Ok</div>
                        : <div>Errore</div>
                    }
                </div>
            }
        </div>
    )
}

export default MenuDashboard