import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    token: string,
    data:  IField
}

interface IField {
    id:             number,
    name:           string,
    type:           string,
    forms_list:     string,
    label:          string,
    pholder:        string,
    extra_options:  string
}

async function updateFieldEdit(props: IProps) {

    let results: {
        id: number,
        type: string,
        message: string,
        data: string
    } = {
        id: 0,
        type: "",
        message: "",
        data: ""
    }

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "api/update_extra_field",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token: props.token,
                    data:  props.data
                })
            }).then((response) => {
                if(response.statusText === 'OK' || response.statusText === '') {
                    results = response.data
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default updateFieldEdit;