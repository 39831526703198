import React from 'react';
import {Control, Controller, useForm} from "react-hook-form";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import fmstyles from "../forms_components/css/forms-manager.module.css";
import GetTeamList from "../user_components/GetTeamList";

interface IProps {
    token: string
    usersSelected: React.Dispatch<React.SetStateAction<ISelected[]>>
    control?: Control<Record<string, any>>,
    usersList?: React.Dispatch<React.SetStateAction<IUser[]>>
}

interface IUser {
    label: string,
    value: any,
    key:   string
}

interface IUserDD {
    id:      string,
    name:    string,
    surname: string,
    email:   string,
}

interface ISelected {
    id:    number,
    name:  string,
    email: string,
}

const UsersListWithCB = (props:IProps) => {

    //Init UseForm
    const {control} = useForm();
    //Initial Array for MultiSelect
    const [selected] = React.useState<ISelected[]>([]);
    //Users List
    const [usersList, setUsersList] = React.useState<IUser[]>([]);

    const UsersSelected = (value:any) => {
        props.usersSelected([]);
        value.map( (user:IUser) => {
            props.usersSelected( (prevState:any) => [
               ...prevState,
               {
                   id:    user.key,
                   name:  user.label,
                   email: user.value,
               }
           ]);
        });
    }

    // Retrieve Users List
    const InitDDUsers = () => {
        GetTeamList({
            token: props.token
        }).then((result) => {
            if (result.error) {
                //Error
            } else {
                //All OK
                if (result.users !== undefined) {
                    result.users.map( (value: IUserDD, index: number) => {
                        setUsersList((prevState: IUser[]) => [
                            ...prevState,
                            {
                                label: value.name + ' ' + value.surname,
                                value: value.email,
                                key:   value.id
                            }
                        ])
                        if(props.usersList !== undefined)
                            props.usersList((prevState: IUser[]) => [
                                ...prevState,
                                {
                                    label: value.name + ' ' + value.surname,
                                    value: value.email,
                                    key:   value.id
                                }
                            ])
                        return index
                    })
                }
            }
        })
    }
    React.useEffect(InitDDUsers, []);

    return(
        <Row>
            <Col sm={12}>
                <Form.Group controlId={"formHideTo"}>
                    <Form.Label>Non Visualizzare Per</Form.Label>
                    <InputGroup>
                        <Controller
                            name={"hideTo"}
                            defaultValue={selected}
                            control={props.control}
                            render={({onChange, value}) => (
                                <MultiSelect
                                    className={fmstyles.multi_select_ulcb}
                                    options={usersList}
                                    value={value}
                                    onChange={(value: []) => {onChange(value); UsersSelected(value)} }
                                    labelledBy={"Select"}
                                    overrideStrings={{
                                        "selectSomeItems": "Seleziona...",
                                        "allItemsAreSelected": "Tutto il Team è stato selezionato.",
                                        "selectAll": "Seleziona Tutto",
                                        "search": "Cerca",
                                        "clearSearch": "Cancella Ricerca"
                                    }}
                                />
                            )}
                        />
                    </InputGroup>
                </Form.Group>
            </Col>
        </Row>
    )
}

export default UsersListWithCB