import React from 'react';
import GetFormsList from "./AjaxFormsList";
import {UserContext} from "../context/UserContext";
import MUIDataTable from "mui-datatables";
import {DeleteForever, Edit, Person, FileCopy} from "@material-ui/icons";
import {Grid, Button, Tooltip, Badge} from "@material-ui/core";
import Modal from "react-bootstrap/cjs/Modal";
import FormEdit from "./FormEdit";
import DeleteMask from "./AjaxDeleteMask";
import UpdateMask from "./AjaxUpdateMask";
import DuplicateForm from "./AjaxDuplicateForm";


interface IForm {
    id: number,
    name: string,
    position: string,
    hide_to: string
}

interface IDialogOpt {
    show: boolean,
    message: string,
    response: string,
    value: any,
    onlyInfo: boolean
}

interface IUser {
    label: string,
    value: any,
    key: string
}

interface IProps {
    user: IUser[]
}

const TableListForms = (props: IProps) => {

    //User Context
    const {infos}: any = React.useContext(UserContext)

    //Masks Array
    const [masks, setMasks] = React.useState<IForm[]>([])

    //Selected Data Row
    const [selectedRow, setSelectedRow] = React.useState({
        id: 0,
        name: "",
        position: "",
        hide_to: []
    })

    //Modal
    const [dialogOpt, setDialogOpt] = React.useState<IDialogOpt>({
        show: false,
        message: "",
        response: "",
        value: "",
        onlyInfo: false
    })

    //Edid Dialog
    const [dialogEdit, setDialogEdit] = React.useState({
        show: false,
        index: 0
    })

    //Data
    const [dataEdit, setDataEdit] = React.useState<IForm>()

    const RetrieveFormsList = () => {
        if (infos.token !== "") {
            GetFormsList({
                token: infos.token
            }).then((results: IForm[]) => {
                setMasks([]);
                results.map(function (mask, index) {
                    setMasks(prev => ([
                        ...prev,
                        mask
                    ]))
                    return index
                })
            })
        }
    }
    React.useEffect(RetrieveFormsList, [])

    const handleClose = () => {
        setDialogOpt(prevState => {
            return {
                show: false,
                message: "",
                response: "",
                value: "",
                onlyInfo: prevState.onlyInfo
            }
        })
        setDialogEdit({
            show: false,
            index: 0
        })
    }

    const handleYes = () => {
        switch (dialogOpt.response) {
            case "deleteRecord":
                handleClose()
                DeleteMask({
                    token: infos.token,
                    data: {
                        id: dialogOpt.value.id,
                        name: dialogOpt.value.name
                    }
                }).then( (response) => {
                    if(response.type === 'Success') {
                        setDialogOpt({
                            show: true,
                            onlyInfo: true,
                            message: response.message,
                            response: "deleteForm",
                            value: ""
                        })
                        setMasks(masks.filter((mask: IForm) => mask.id !== dialogOpt.value.id));
                    } else {
                        setDialogOpt({
                            show: true,
                            onlyInfo: true,
                            message: response.message,
                            response: "deleteForm",
                            value: ""
                        })
                    }
                })
                break;
            default:
                handleClose()
                break;
        }
    }

    const handleList = (value: any) => {
        let message: string = ""
        if (value.length > 0) {
            value.map((value: number, index: number) => {
                props.user.filter(name => parseInt(name.key) === value).map(filteredName => {
                    if (index === 0) {
                        message += filteredName.label
                    } else {
                        message += ", " + filteredName.label
                    }
                    return filteredName;
                })
            })
        } else {
            message = "Nessun collaboratore selezionato"
        }
        openDialog(
            message,
            "listRecord",
            0,
            true
        )
    }

    const openDialog = (message: string, response: string, value: any, onlyInfo: boolean) => {
        setDialogOpt(prevState => {
            return {
                show: true,
                message: message,
                response: response,
                value: value,
                onlyInfo: onlyInfo
            }
        })
    }

    const deleteRecord = (index: number, name: string) => {
        openDialog(
            "Vuoi davvero eliminare la maschera selezionata?",
            "deleteRecord",
            {
                id: index,
                name: name
            },
            false
        )
    }

    const editRecord = (index: number, value: any) => {
        //Recupero dalla riga selezionata gli id
        let hideToId = JSON.parse(value.rowData[3]);
        let Ids: any = [];
        if (hideToId.id.length > 0) {
            hideToId.id.map((value: number, index: number) => {
                props.user.filter(name => parseInt(name.key) === value).map(filteredName => {
                    Ids.push(filteredName);
                    return filteredName;
                })
                return index;
            })
        }
        setSelectedRow({
            id: value.rowData[0],
            name: value.rowData[1],
            position: value.rowData[2],
            hide_to: Ids
        })
        setDialogEdit({
            show: true,
            index: index
        })
    }

    const duplicateMask = (index: number, value: any) => {
        // Continuare qui
        DuplicateForm({
            token: infos.token,
            form: value.rowData
        }).then( response => {
            if(response.type === "Success") {
                RetrieveFormsList();
                setDialogOpt({
                    show: true,
                    onlyInfo: true,
                    message: response.message,
                    response: "duplicateForm",
                    value: ""
                })
            } else {
                setDialogOpt({
                    show: true,
                    onlyInfo: true,
                    message: response.message,
                    response: "duplicateForm",
                    value: ""
                })
            }
        })
    }

    const updateMask = (data:any) => {
        if(data !== undefined) {
            let ids = {id: []} as any;
            masks.map( (mask:IForm, index:number) => {
                if( mask.id == data.id) {
                    mask.name = data.name;
                    mask.position = data.position;
                    data.hide_to.map( (user:IUser, index:number) => {
                        ids.id.push(parseInt(user.key));
                        return index;
                    })
                    mask.hide_to = JSON.stringify(ids);
                }
                return index;
            })
            UpdateMask({
                data: data,
                token: infos.token
            }).then( (response) => {
                if(response.type === 'Success') {
                    setDialogOpt({
                        show: true,
                        onlyInfo: true,
                        message: response.message,
                        response: "updateForm",
                        value: ""
                    })
                } else {
                    setDialogOpt({
                        show: true,
                        onlyInfo: true,
                        message: response.message,
                        response: "updateForm",
                        value: ""
                    })
                }
            })
        }
        setDataEdit(undefined)
    }

    const columns = [
        {name: "id", label: "ID"},
        {name: "name", label: "Nome"},
        {name: "position", label: "Posizione"},
        {
            name: "hide_to", label: "Nascondi per",
            options: {
                sort: false,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <Grid>
                            <Tooltip title={"Lista Utenti"} onClick={() => handleList(JSON.parse(value).id)}>
                                <Badge badgeContent={(JSON.parse(value).id).length} color="error">
                                    <Person/>
                                </Badge>
                            </Tooltip>
                        </Grid>
                    )
                }
            }
        },
        {
            name: "Azioni",
            options: {
                sort: false,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <Grid
                            container
                            item
                            xs={6}
                            alignItems={"center"}
                            justify={"space-between"}
                            direction={"row"}
                        >
                            <Tooltip onClick={() => editRecord(tableMeta.rowData[0], tableMeta)} title={"Modifica"}>
                                <Edit/>
                            </Tooltip>
                            <div onClick={() => deleteRecord(tableMeta.rowData[0], tableMeta.rowData[1])}>
                                <Tooltip title={"Elimina"}>
                                    <DeleteForever color={"error"}/>
                                </Tooltip>
                            </div>
                            <Tooltip onClick={() => duplicateMask(tableMeta.rowData[0], tableMeta)} title={"Duplica"}>
                                <FileCopy/>
                            </Tooltip>
                        </Grid>
                    )
                },
            },
        },
    ]
    return (
        <div>
            <Modal show={dialogOpt.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{dialogOpt.message}</p>
                </Modal.Body>
                <Modal.Footer>
                    {dialogOpt.onlyInfo ?
                        <Button onClick={handleYes}>OK</Button>
                        : (<div>
                            <Button onClick={handleYes}>SI</Button>
                            <Button onClick={handleClose}> NO </Button>
                        </div>)
                    }
                </Modal.Footer>
            </Modal>
            <Modal show={dialogEdit.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modifica Maschera</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormEdit
                        usersList={props.user}
                        rowData={selectedRow}
                        data={setDataEdit}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        updateMask(dataEdit);
                        handleClose();
                    }}>MODIFICA</Button>
                    <Button onClick={ () => {
                        setDataEdit(undefined)
                        handleClose();
                    }}>ANNULLA</Button>
                </Modal.Footer>
            </Modal>
            <MUIDataTable
                title={"Lista Maschere"}
                data={masks}
                columns={columns}
                options={{
                    selectableRows: "none",
                    filter: true,
                    filterType: 'dropdown',
                    responsive: 'vertical',
                    fixedHeader: true,
                    fixedSelectColumn: true,
                    tableBodyHeight: '400px',
                    draggableColumns: {
                        enabled: true
                    },
                    expandableRowsOnClick: true,
                    textLabels: {
                        body: {
                            noMatch: "Mi dispiace, nessuna corrispondenza trovata",
                            toolTip: "Filtra",
                            columnHeaderTooltip: column => `Sort for ${column.label}`
                        },
                        pagination: {
                            next: "Pagina Seguente",
                            previous: "Pagina Precedente",
                            rowsPerPage: "Righe per pagina:",
                            displayRows: "di",
                        },
                        toolbar: {
                            search: "Cerca",
                            downloadCsv: "Download CSV",
                            print: "Stampa",
                            viewColumns: "Schermata Colonne",
                            filterTable: "Filtra Tabella",
                        },
                        filter: {
                            all: "Tutto",
                            title: "FILTRI",
                            reset: "RESET",
                        },
                        viewColumns: {
                            title: "Mostra Colonne",
                            titleAria: "Mostra/Nascondi Colonne Tabella",
                        },
                        selectedRows: {
                            text: "riga(righe) selezionata/e",
                            // delete: "Elimina",
                            deleteAria: "Elimina righe selezionate",
                        },
                    }
                }}
            ></MUIDataTable>
        </div>
    )
}

export default TableListForms