import React from 'react';
import {Controller, useForm} from "react-hook-form";
import {Form, InputGroup} from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import {Grid} from "@material-ui/core";

interface IProps {
    formsList: IForm[],
    rowData: {
        id: number,
        name: string,
        type: string,
        label: string,
        pholder: string,
        extra_options: string,
        forms_list: any[]
    },
    data: any
}

interface IForm {
    label: string,
    value: any,
    key: string
}

const FieldEdit = (props: IProps) => {

    //Init UseForm
    const {control} = useForm()

    const [showOptions, setShowOptions] = React.useState(false)

    const checkSel = (value: any) => {
        if (value === "dropdown") {
            setShowOptions(true)
        } else {
            setShowOptions(false)
        }
    }

    const onChangeDialog = (event: any) => {
        let data = control.getValues();
        data.id = parseInt(props.rowData.id.toString());
        props.data(data);
    }

    const init = () => {
        if(props.rowData.type === 'dropdown') {
            setShowOptions(true);
        }
    }

    React.useEffect(init, []);

    return (
        <Form onChange={onChangeDialog}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={8}>
                    <Form.Group controlId={"formNomeCampo"}>
                        <Form.Label>Nome Campo</Form.Label>
                        <InputGroup>
                            <Controller
                                name={"name"}
                                defaultValue={props.rowData.name}
                                control={control}
                                render={({onChange, value}) => (
                                    <input
                                        name="Nome Campo"
                                        type="text"
                                        className="form-control"
                                        placeholder="Nome Campo"
                                        aria-label="Nome Campo"
                                        aria-describedby="basic-addon1"
                                        onChange={value => onChange(value)}
                                        value={value}
                                        required
                                    />
                                )}
                            />
                        </InputGroup>
                    </Form.Group>
                </Grid>
                <Grid item xs={4}>
                    <Form.Group controlId={"formTipo"}>
                        <Form.Label>Tipo</Form.Label>
                        <InputGroup>
                            <Controller
                                name={"type"}
                                control={control}
                                defaultValue={props.rowData.type}
                                render={({onChange, value}) => (
                                    <select
                                        name={"Tipo"}
                                        className="browser-default custom-select"
                                        defaultValue={props.rowData.type}
                                        onChange={value => {
                                            onChange(value);
                                            checkSel(value.target.value)
                                        }} required>
                                        <option key={"Default"} value={""}></option>
                                        <option key={"Text"} value={"text"}>Testo</option>
                                        <option key={"TextArea"} value={"textarea"}>Area Testo</option>
                                        <option key={"Number"} value={"number"}>Numerico</option>
                                        <option key={"Currency"} value={"currency"}>Numerico €</option>
                                        <option key={"Date"} value={"date"}>Data</option>
                                        <option key={"Dropdown"} value={"dropdown"}>Tendina</option>
                                    </select>
                                )}
                            />
                        </InputGroup>
                    </Form.Group>
                </Grid>
                <Grid item xs={8}>
                    <Form.Group controlId={"formEtichetta"}>
                        <Form.Label>Etichetta</Form.Label>
                        <InputGroup>
                            <Controller
                                name={"label"}
                                defaultValue={props.rowData.label}
                                control={control}
                                render={({onChange, value}) => (
                                    <input
                                        name="Etichetta"
                                        type="text"
                                        className="form-control"
                                        placeholder="Etichetta"
                                        aria-label="Etichetta"
                                        aria-describedby="basic-addon1"
                                        onChange={value => onChange(value)}
                                        value={value}
                                        required
                                    />
                                )}
                            />
                        </InputGroup>
                    </Form.Group>
                </Grid>
                <Grid item xs={4}>
                    <Form.Group controlId={"formSegnaposto"}>
                        <Form.Label>Segnaposto</Form.Label>
                        <InputGroup>
                            <Controller
                                name={"pholder"}
                                defaultValue={props.rowData.pholder}
                                control={control}
                                render={({onChange, value}) => (
                                    <input
                                        name="Segnaposto"
                                        type="text"
                                        className="form-control"
                                        placeholder="Segnaposto"
                                        aria-label="Segnaposto"
                                        aria-describedby="basic-addon1"
                                        onChange={value => onChange(value)}
                                        value={value}
                                        required
                                    />
                                )}
                            />
                        </InputGroup>
                    </Form.Group>
                </Grid>
                {showOptions ? (
                    <Grid item xs={12}>
                        <Form.Group controlId={"formOpzioni"}>
                            <Form.Label>Opzioni</Form.Label>
                            <InputGroup>
                                <Controller
                                    name={"extra_options"}
                                    defaultValue={props.rowData.extra_options}
                                    control={control}
                                    render={({onChange, value}) => (
                                        <input
                                            name="Opzioni"
                                            type="text"
                                            className="form-control"
                                            placeholder="Opzioni separati da ;"
                                            aria-label="Opzioni separati da ;"
                                            aria-describedby="basic-addon1"
                                            onChange={value => onChange(value)}
                                            value={value}
                                            required
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Form.Group controlId={"formTipo"}>
                        <Form.Label>Maschera</Form.Label>
                        <InputGroup>
                            <Controller
                                name={"forms_list"}
                                defaultValue={props.rowData.forms_list}
                                control={control}
                                render={({onChange, value}) => (
                                    <MultiSelect
                                        options={props.formsList}
                                        value={value}
                                        onChange={(value: []) => onChange(value)}
                                        labelledBy={"Select"}
                                        overrideStrings={{
                                            "selectSomeItems": "Seleziona...",
                                            "allItemsAreSelected": "Tutte le maschere selezionate.",
                                            "selectAll": "Seleziona Tutto",
                                            "search": "Cerca",
                                            "clearSearch": "Cancella Ricerca"
                                        }}
                                    />
                                )}
                            />
                        </InputGroup>
                    </Form.Group>
                </Grid>
            </Grid>
        </Form>
    )
}

export default FieldEdit