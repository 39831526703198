import React from "react";
import {useForm, Controller} from "react-hook-form";
import {Container, Row, Col, Card, Form, InputGroup} from "react-bootstrap";
import {Page404Context} from "../context/Page404Context";
import {UserContext} from "../context/UserContext";
import MultiSelect from "react-multi-select-component";
import GetFormsList from "../forms_components/AjaxFormsList";
import SendFieldValues from "./AjaxSendFieldValues";
import TableFields from "./TableFields";
import fdstyles from "./css/fields-manager.module.css";
import Modal from "react-bootstrap/cjs/Modal";
import {Button} from "@material-ui/core";


interface IDialogOpt {
    show:       boolean,
    message:    string,
    response:   string,
    value:      any,
    onlyInfo:   boolean
}

const FieldsView = () => {
    //Page404 Context
    const page404 = React.useContext(Page404Context);
    page404.not_found = false;

    //User Context
    const {infos, setInfos}: any = React.useContext(UserContext);

    //Init UseForm
    const {control, handleSubmit} = useForm();

    const [showOptions, setShowOptions] = React.useState(false);

    type form = {
        label:  string,
        value:  any,
        key:    string
    }

    const [formsList, setFormsList] = React.useState<form[]>([]);

    //Initial Array for MultiSelect
    const [selected] = React.useState([])

    //Modal
    const [dialogOpt, setDialogOpt] = React.useState<IDialogOpt>({
        show: false,
        message: "",
        response: "",
        value: "",
        onlyInfo: false
    })

    const handleClose = () => {
        setDialogOpt(prevState => {
            return {
                show: false,
                message: "",
                response: "",
                value: "",
                onlyInfo: prevState.onlyInfo
            }
        })
    }

    const handleYes = () => {
        switch (dialogOpt.response) {
            default:
                handleClose();
                return 0;
        }
    }

    const Initialization = () => {
        GetFormsList({
            token: infos.token
        }).then((result) => {
            result.map(function (value, index) {
                setFormsList((prevsList) => [
                    ...prevsList,
                    {
                        label:  value.name,
                        value:  value.id,
                        key:    ""
                    }
                ])
                return index
            })
        })
    }

    React.useEffect(Initialization, [])

    const checkSel = (value: any) => {
        if (value === "dropdown") {
            setShowOptions(true)
        } else {
            setShowOptions(false)
        }
    }

    //Send Values
    const onSubmit = (data: any) => {
        // console.log(data);
        SendFieldValues({
            token: infos.token,
            data: data
        }).then((result) => {
            setDialogOpt({
                show: true,
                message: result.message,
                onlyInfo: true,
                value: "",
                response: "addField"
            })
            if(result.type === "Success") {
                document.location.reload();
            }
        })
    }

    return (
        <Container>
            <Modal show={dialogOpt.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{dialogOpt.message}</p>
                </Modal.Body>
                <Modal.Footer>
                    {dialogOpt.onlyInfo ?
                        <Button onClick={handleYes}>OK</Button>
                        : (<div>
                            <Button onClick={handleYes}>SI</Button>
                            <Button onClick={handleClose}> NO </Button>
                        </div>)
                    }
                </Modal.Footer>
            </Modal>
            <Row>
                <Col sm={12} className={"py-5"}>
                    <Card>
                        <Card.Header>
                            <h6 className={"m-0 font-weight-bold text-secondary"}>AGGIUNGI UN NUOVO CAMPO</h6>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col sm={2}>
                                        <Form.Group controlId={"formNomeCampo"}>
                                            <Form.Label>Nome Campo</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"field_name"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="Nome Campo"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Nome Campo"
                                                            aria-label="Nome Campo"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group controlId={"formTipo"}>
                                            <Form.Label>Tipo</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"type"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <select
                                                            name={"Tipo"}
                                                            className="browser-default custom-select"
                                                            onChange={value => {
                                                                onChange(value);
                                                                checkSel(value.target.value)
                                                            }} required>
                                                            <option key={"Default"} value={""}></option>
                                                            <option key={"Text"} value={"text"}>Testo</option>
                                                            <option key={"TextArea"} value={"textarea"}>Area Testo</option>
                                                            <option key={"Number"} value={"number"}>Numerico</option>
                                                            <option key={"Currency"} value={"currency"}>Numerico €</option>
                                                            <option key={"Date"} value={"date"}>Data</option>
                                                            <option key={"Dropdown"} value={"dropdown"}>Tendina</option>
                                                        </select>
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group controlId={"formEtichetta"}>
                                            <Form.Label>Etichetta</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"label"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="Etichetta"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Etichetta"
                                                            aria-label="Etichetta"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group controlId={"formSegnaposto"}>
                                            <Form.Label>Segnaposto</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"placeholder"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="Segnaposto"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Segnaposto"
                                                            aria-label="Segnaposto"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    {showOptions ? (
                                        <Col sm={4}>
                                            <Form.Group controlId={"formOpzioni"}>
                                                <Form.Label>Opzioni</Form.Label>
                                                <InputGroup>
                                                    <Controller
                                                        name={"options"}
                                                        defaultValue={""}
                                                        control={control}
                                                        render={({onChange, value}) => (
                                                            <input
                                                                name="Opzioni"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Opzioni separati da ;"
                                                                aria-label="Opzioni separati da ;"
                                                                aria-describedby="basic-addon1"
                                                                onChange={value => onChange(value)}
                                                                value={value}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    ) : null}
                                    <Col sm={2}>
                                        <Form.Group controlId={"formTipo"}>
                                            <Form.Label>Maschera</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"forms"}
                                                    defaultValue={selected}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <MultiSelect
                                                            className={fdstyles.multi_select}
                                                            options={formsList}
                                                            value={value}
                                                            onChange={(value: []) => onChange(value)}
                                                            labelledBy={"Select"}
                                                            overrideStrings={{
                                                                "selectSomeItems": "Seleziona...",
                                                                "allItemsAreSelected": "Tutte le maschere selezionate.",
                                                                "selectAll": "Seleziona Tutto",
                                                                "search": "Cerca",
                                                                "clearSearch": "Cancella Ricerca"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={2}>
                                        <button
                                            type={"submit"}
                                            className={"btn btn-primary"}
                                        >Aggiungi
                                        </button>
                                    </Col>
                                </Row>
                            </Form>
                            <Row className={"p-3"}>
                                <Col xs={12}>
                                    <TableFields
                                        formsList={formsList}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer></Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default FieldsView;