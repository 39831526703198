import React from 'react';
import {UserContext} from "../context/UserContext";
import {Page404Context} from "../context/Page404Context";
import {Controller, useForm} from "react-hook-form";
import {Button, Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import MUIDataTable, {MUIDataTableColumnDef} from "mui-datatables";

const AgendaMain = () => {
    //User Context
    const { infos, setInfos }:any = React.useContext(UserContext)
    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false
    //Init UseForm
    const {register, control, errors, handleSubmit} = useForm()

    const [rows, SetRows] = React.useState([])
    const columns: MUIDataTableColumnDef[] = [
        {
            name: "id",
            label: "ID",
        },
        {
            name: "otherside",
            label: "Controparte",
        },
        {
            name: "otherside_name",
            label: "Nome Controparte",
        },
        {
            name: "indirizzo",
            label: "Indirizzo",
        },
        {
            name: "pec",
            label: "PEC",
        },
        {
            name: "fax",
            label: "FAX",
        },
        {
            name: "telefono",
            label: "Telefono",
        },
        {
            name: "codice_fiscale",
            label: "Codice Fiscale",
        },
        {
            name: "avvocato",
            label: "Avvocato",
        },
        {
            name: "referente",
            label: "Referente",
        },
        {
            name: "domiciliatario",
            label: "Domiciliatario",
        },
        {
            name: "citta_domiciliatario",
            label: "Città Domiciliatario",
        },
        {
            name: "cellulare",
            label: "Cellulare",
        },
        {
            name: "mail",
            label: "Mail",
        },
        {
            name: "link",
            label: "Link",
        },
    ]

    const onSubmitHandler = () => {
        console.log("Salvo...");
    }

    return(
        <div style={{paddingLeft: "30px", paddingRight: "30px", paddingBottom: "100px"}}>
            <Row>
                <Col sm={12} className={"py-5"}>
                    <Card>
                        <Card.Header>
                            <h6 className={"m-0 font-weight-bold text-secondary"}>AGGIUNGI RECORD IN AGENDA</h6>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onSubmitHandler)}>
                                <Row>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formControparte"}>
                                            <Form.Label>Nome Controparte</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"otherside_name"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="controparte"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Controparte"
                                                            aria-label="Controparte"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formControparteCompleto"}>
                                            <Form.Label>Nome Controparte Completo</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"otherside"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="controparte_completo"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Controparte Completo"
                                                            aria-label="Controparte Completo"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formIndirizzo"}>
                                            <Form.Label>Indirizzo</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"indirizzo"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="indirizzo"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Indirizzo"
                                                            aria-label="Indirizzo"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formPec"}>
                                            <Form.Label>Pec</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"pec"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="pec"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pec"
                                                            aria-label="Pec"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formFax"}>
                                            <Form.Label>Fax</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"fax"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="fax"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Fax"
                                                            aria-label="Fax"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formTelefono"}>
                                            <Form.Label>Telefono</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"telefono"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="telefono"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Telefono"
                                                            aria-label="Telefono"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formCF"}>
                                            <Form.Label>Codice Fiscale</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"codice_fiscale"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="codice_fiscale"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Codice Fiscale"
                                                            aria-label="Codice Fiscale"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formAvvocato"}>
                                            <Form.Label>Avvocato</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"avvocato"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="avvocato"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Avvocato"
                                                            aria-label="Avvocato"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formReferente"}>
                                            <Form.Label>Referente</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"referente"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="referente"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Referente"
                                                            aria-label="Referente"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formDomiciliatario"}>
                                            <Form.Label>Domiciliatario</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"domiciliatario"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="domiciliatario"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Domiciliatario"
                                                            aria-label="Domiciliatario"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formCittaDomiciliatario"}>
                                            <Form.Label>Città Domiciliatario</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"citta_domiciliatario"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="citta_domiciliatario"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Città Domiciliatario"
                                                            aria-label="Città Domiciliatario"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formCellulare"}>
                                            <Form.Label>Cellulare</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"cellulare"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="cellulare"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Cellulare"
                                                            aria-label="Cellulare"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formMail"}>
                                            <Form.Label>Mail</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"mail"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="mail"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Mail"
                                                            aria-label="Mail"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId={"formLink"}>
                                            <Form.Label>Link</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"link"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="link"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Link"
                                                            aria-label="Link"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} >
                                        <div style={{float: "right"}}>
                                            <button className={"btn btn-success"} type={"submit"}>SALVA</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                <MUIDataTable
                    title={"Lista Maschere"}
                    data={rows}
                    columns={columns}
                    options={{
                        selectableRows: "none",
                        filter: true,
                        filterType: 'dropdown',
                        responsive: 'vertical',
                        fixedHeader: true,
                        fixedSelectColumn: true,
                        tableBodyHeight: '400px',
                        draggableColumns: {
                            enabled: true
                        },
                        expandableRowsOnClick: true,
                        textLabels: {
                            body: {
                                noMatch: "Mi dispiace, nessuna corrispondenza trovata",
                                toolTip: "Filtra",
                                columnHeaderTooltip: column => `Sort for ${column.label}`
                            },
                            pagination: {
                                next: "Pagina Seguente",
                                previous: "Pagina Precedente",
                                rowsPerPage: "Righe per pagina:",
                                displayRows: "di",
                            },
                            toolbar: {
                                search: "Cerca",
                                downloadCsv: "Download CSV",
                                print: "Stampa",
                                viewColumns: "Schermata Colonne",
                                filterTable: "Filtra Tabella",
                            },
                            filter: {
                                all: "Tutto",
                                title: "FILTRI",
                                reset: "RESET",
                            },
                            viewColumns: {
                                title: "Mostra Colonne",
                                titleAria: "Mostra/Nascondi Colonne Tabella",
                            },
                            selectedRows: {
                                text: "riga(righe) selezionata/e",
                                // delete: "Elimina",
                                deleteAria: "Elimina righe selezionate",
                            },
                        }
                    }}
                ></MUIDataTable>
                </Col>
            </Row>
        </div>
    )
}

export default AgendaMain