import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    token: string,
}

interface IList {
    id :        number,
    query_id:   number,
    query_name: string,
}

interface IResult {
    id:      string,
    type:    string,
    message: string,
    data:    IList[]
}

async function FieldsOrderList(props: IProps) {

    let results:IResult = {
        id:      "",
        type:    "",
        message: "",
        data:    []
    };

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "api/fields-order-list",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data:   JSON.stringify({
                    token:   props.token,
                })
            }).then( (response:any) => {
                if(response.status === 200) {
                    results = response.data;
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default FieldsOrderList;