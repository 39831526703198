import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    token:  string,
    form: []
}

async function DuplicateForm(props: IProps) {

    let results: {
        id: number,
        type: string,
        message: string,
        data: string
    } = {
        id: 0,
        type: "",
        message: "",
        data: ""
    }

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "api/duplicate_form",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token:  props.token,
                    form: props.form
                })
            }).then((response) => {
                if(response.statusText === 'OK' || response.statusText === '') {
                    results = response.data
                }
            })
    } catch(err) {
        console.log(err)
        if (Axios.isCancel(err)) {
            //cancelled
        } else {
            throw err;
        }
    }

    return (results)
}

export default DuplicateForm;