import React from 'react';
import {Grid, Tooltip} from "@material-ui/core";
import MUIDataTable, {MUIDataTableColumnDef} from "mui-datatables";
import {Info, DeleteForever, Edit} from "@material-ui/icons";
import DelQueryList from "./AjaxDelQueryList";
import {UserContext} from "../context/UserContext";
import {confirmAlert} from "react-confirm-alert";
import MessageInfo from "../modal/MessageInfo";
import ExecuteQueryList from "./AjaxExecuteQuery";

interface IProps {
    queryList: ISQuery[],
    setQueryList: React.Dispatch<React.SetStateAction<ISQuery[]>>
    availableQuery: IAQuery,
    setAvailableQuery: React.Dispatch<React.SetStateAction<IAQuery>>
    isLoading: boolean,
    exportQuery?: React.Dispatch<React.SetStateAction<ISQuery | undefined>>
    showDialog?: React.Dispatch<React.SetStateAction<boolean>>
}

interface IAQuery {
    total:      number,
    used:       number,
    available:  number
}

interface ISQuery {
    id:         number,
    name:       string,
    menu:       string,
    btn_menu:   string,
    submenu:    string,
    user_email: string,
    query:      string,
    extra:      string,
    position:   number,
    jdata:      string,
}

const QueryList = (props:IProps) => {

    //User Context
    const { infos, setInfos }:any = React.useContext(UserContext)
    const [showMessage, setShowMessage] = React.useState<boolean>(false);
    const [messagePopUp, setMessagePopUp] = React.useState<string>("");
    const [titlePopUp, setTitlePopUp] = React.useState<string>("INFO");

    const columns: MUIDataTableColumnDef[] = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false
            }
        },
        {
            name: "name",
            label: "Nome Query",
        },
        {
            name: "menu",
            label: "Menu",
        },
        {
            name: "btn_menu",
            label: "Bottone Menu",
        },
        {
            name: "submenu",
            label: "Sottomenu",
        },
        {
            name: "position",
            label: "Posizione"
        },
        {
            name: "operations",
            label: "Operazioni",
            options: {
                sort: false,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <Grid
                            container
                            item
                            xs={8}
                            alignItems={"center"}
                            justify={"space-between"}
                            direction={"row"}
                        >
                            <Tooltip onClick={() => queryPlay(tableMeta.rowData[0], tableMeta.rowData[1], tableMeta.rowData[2], tableMeta)} title={"Vedi Query"}>
                                <Info />
                            </Tooltip>
                            <Tooltip onClick={ () => exportSelQuery(tableMeta.rowData[0]) } title={"Modifica"}>
                                <Edit />
                            </Tooltip>
                            <div onClick={() => queryDelete(tableMeta.rowData[0], tableMeta.rowData[1])}>
                                <Tooltip title={"Elimina"}>
                                    <DeleteForever color={"error"}/>
                                </Tooltip>
                            </div>
                        </Grid>
                    )
                },
            },
        }
    ]

    const exportSelQuery = ( qid:number ) => {
        if(props.exportQuery !== undefined) {
            props.exportQuery({
                id:         0,
                name:       "",
                menu:       "",
                btn_menu:   "",
                submenu:    "",
                user_email: "",
                query:      "",
                extra:      "",
                position:   0,
                jdata:      "",
            });
            let found = props.queryList.find( ({ id }) => id === qid);
            if(found !== undefined) {
                props.exportQuery(found);
                if(props.showDialog !== undefined) props.showDialog(true);
            }
        }
    }

    const queryPlay = (idq:number, name:string, a:any, b:any) => {
        let found = props.queryList.find( ({ id }) => id === idq);
        if(found !== undefined) {
            let qfound: string = "";
            qfound = found.query;
            setMessagePopUp(found.query);
            setShowMessage(true);
            ExecuteQueryList({
                token: infos.token,
                data:  qfound
            }).then( result => {
                console.log(result);
            });
        }
    }

    const queryDelete = (id:number, name: string) => {
        let message:string = "";
        let dataArray:{id: number} = {
            id: id
        };
        DelQueryList({
            token: infos.token,
            data: JSON.stringify(dataArray)
        }).then( result => {
            props.setAvailableQuery({
                total:      props.availableQuery.total,
                available:  props.availableQuery.available + 1,
                used:       props.availableQuery.used - 1,
            });
            props.setQueryList([]);
            props.queryList.map( (q:ISQuery, index:number) => {
                if(q.id !== id) {
                    props.setQueryList(prevState => [
                        ...prevState,
                        {
                            id:         q.id,
                            query:      q.query,
                            extra:      q.extra,
                            menu:       q.menu,
                            btn_menu:   q.btn_menu,
                            submenu:    q.submenu,
                            name:       q.name,
                            user_email: q.user_email,
                            position:   q.position,
                            jdata:      q.jdata,
                        }
                    ]);
                }
            });
            message = result.message.toString();
            confirmAlert({
                title: 'Info',
                message: message,
                buttons: [
                    {
                        label: 'Chiudi',
                        onClick: () => {}
                    }
                ]
            });
        });
    }

    return(
        <>
            <MessageInfo
                show={showMessage}
                title={titlePopUp}
                message={messagePopUp}
                OkBtn={true}
                TextOkBtn={"CHIUDI"}
                CancelBtn={false}
                TextCancelBtn={""}
                onHide={ () => {} }
                onClick={ () => {setShowMessage(false)} }
            />
            <MUIDataTable
                title={"LISTA QUERY"}
                data={props.queryList}
                columns={columns}
                options={{
                    selectableRows: "none",
                    filter: true,
                    filterType: 'dropdown',
                    responsive: 'vertical',
                    fixedHeader: true,
                    fixedSelectColumn: true,
                    tableBodyHeight: '100%',
                    draggableColumns: {
                        enabled: true
                    },
                    expandableRowsOnClick: true,
                    textLabels: {
                        body: {
                            noMatch: (props.isLoading ? "Caricamento in corso...": "Mi dispiace, nessuna corrispondenza trovata"),
                            toolTip: "Filtra",
                            //columnHeaderTooltip: column => `Sort for ${column.label}`
                        },
                        pagination: {
                            next: "Pagina Seguente",
                            previous: "Pagina Precedente",
                            rowsPerPage: "Righe per pagina:",
                            displayRows: "di",
                        },
                        toolbar: {
                            search: "Cerca",
                            downloadCsv: "Download CSV",
                            print: "Stampa",
                            viewColumns: "Schermata Colonne",
                            filterTable: "Filtra Tabella",
                        },
                        filter: {
                            all: "Tutto",
                            title: "FILTRI",
                            reset: "RESET",
                        },
                        viewColumns: {
                            title: "Mostra Colonne",
                            titleAria: "Mostra/Nascondi Colonne Tabella",
                        },
                        selectedRows: {
                            text: "riga(righe) selezionata/e",
                            // delete: "Elimina",
                            deleteAria: "Elimina righe selezionate",
                        },
                    }
                }}
            ></MUIDataTable>
        </>
    )
}

export default QueryList