import React from 'react';
import LoginForm from "./components/LoginForm";
import {UserContext} from "./components/context/UserContext";
import {Page404Context} from "./components/context/Page404Context";
import RegistrationForm from "./components/RegistrationForm";
import NavBar from "./components/NavBar";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Profilo from "./components/Profilo";
import Team from "./components/Team";
import Options from "./components/Options";
import Page404 from "./components/Page404";
import AddTeamUser from "./components/team_components/AddTeamUser";
import EditTeamUser from "./components/team_components/EditTeamUser";
import FieldsView from './components/fields_components/FieldsView';
import FormsView from './components/forms_components/FormsView';
import FormsManager from "./components/forms_components/FormsManager";
import CheckToken from "./components/CheckToken";
import {TokenContext} from "./components/context/TokenContext";
import RecordsList from "./components/record_list_components/RecordsList";
import EsaManager from "./components/esa_components/EsaManager";
import QueryB from "./components/querybuilder_components/QueryB";
import PipeDrive from "./components/pipedrive_components/PipeDrive";
import RecordsListByQuery from "./components/record_list_components/RecordsListByQuery";
import AgendaMain from "./components/agenda_components/AgendaMain";
import Search from "./components/search_components/Search";
import OrderFieldsPage from "./components/orderfields_components/OrderFieldsPage";

export interface IInfos {
    isLogged: boolean,
    username: string,
    email: string,
    token: string,
    name: string,
    surname: string,
    registration?: boolean,
    loggin?: boolean
}

const Home = () => {

    let infoStorage = JSON.parse((JSON.parse(JSON.stringify(localStorage.getItem("credential")))));
    if (infoStorage === null) {
        infoStorage = ({
            isLogged: false,
            username: "",
            email: "",
            token: "",
            name: "",
            surname: "",
        })
    }

    const [infos, setInfos] = React.useState<IInfos>({
        isLogged: infoStorage.isLogged,
        username: infoStorage.username,
        email: infoStorage.email,
        token: infoStorage.token,
        name: (infoStorage.name) ? infoStorage.name : "",
        surname: (infoStorage.surname) ? infoStorage.surname : ""
    })

    const [isValidToken, setIsValidToken] = React.useState({
        isValidToken: false
    })

    return (
        <div>
            <Router>
                <Switch>
                    <UserContext.Provider value={{infos, setInfos}}>
                        <Page404Context.Provider value={{not_found: true, forbidden: false}}>
                            <TokenContext.Provider value={{isValidToken, setIsValidToken}}>
                                <NavBar/>
                                <CheckToken/>
                                <Route exact path={"/"} component={Dashboard}></Route>
                                <Route exact path={"/dashboard/:menu"} component={Dashboard}></Route>
                                <Route exact path={"/dashboard/:menu/:submenu"} component={Dashboard}></Route>
                                <Route exact path={"/login"}>
                                    <LoginForm/>
                                </Route>
                                <Route exact path={"/registration"}>
                                    <RegistrationForm/>
                                </Route>
                                <Route exact path={"/profile"}>
                                    <Profilo/>
                                </Route>
                                <Route exact path={"/team"}>
                                    <Team/>
                                </Route>
                                <Route exact path={"/add-team-user"}>
                                    <AddTeamUser/>
                                </Route>
                                <Route path={"/edit-team-user/:id"}>
                                    <EditTeamUser/>
                                </Route>
                                <Route exact path={"/options"}>
                                    <Options/>
                                </Route>
                                <Route exact path={"/forms"}>
                                    <FormsManager/>
                                </Route>
                                <Route exact path={"/fields"}>
                                    <FieldsView/>
                                </Route>
                                <Route exact path={"/forms-view/:form_id/:record_id"} component={FormsView}></Route>
                                <Route exact path={"/forms-view/:form_id"} component={FormsView}></Route>
                                <Route exact path={"/records-list"} component={RecordsList}></Route>
                                <Route exact path={"/esapi/:esapi_id"} component={EsaManager}></Route>
                                <Route exact path={"/esapi"} component={EsaManager}></Route>
                                <Route exact path={"/qbuilder"} component={QueryB}></Route>
                                <Route exact path={"/qbuilder:qid"} component={QueryB}></Route>
                                <Route exact path={"/pipedrive"} component={PipeDrive}></Route>
                                <Route exact path={"/query-records/:qid"} component={RecordsListByQuery}></Route>
                                <Route exact path={"/agende"} component={AgendaMain}></Route>
                                <Route exact path={"/search"} component={Search}></Route>
                                <Route exact path={"/order-fields"} component={OrderFieldsPage}></Route>
                                <Route path={"*"}>
                                    <Page404/>
                                </Route>
                            </TokenContext.Provider>
                        </Page404Context.Provider>
                    </UserContext.Provider>
                </Switch>
            </Router>
        </div>
    )
}

export default Home;
