import React from 'react';
import {Container, Col, Row} from "react-bootstrap";
import GetExtraInfos from "../user_components/GetExtraInfos";

interface IProps {
    token:            string,
    extraInfoName:    string,
    textToShow:       ITextToShow,
    extraInfo:        IExtra,
    extraInfoValues:  React.Dispatch<React.SetStateAction<IExtra>>
}

interface ITextToShow {
    usedText:  string,
    availText: string,
    totalText: string
}

interface IExtra {
    total:      number
    used:       number,
    available:  number,
}

const GetExtraUserInfo = (props:IProps) => {

    const retrieveExtraUserInfo = () => {
        GetExtraInfos({
            token: props.token,
            var_name: props.extraInfoName
        }).then( result => {
            if(result.data !== undefined) {
                const extraInfo:IExtra = JSON.parse(result.data);
                props.extraInfoValues(extraInfo);
            }
        })
    }
    React.useEffect(retrieveExtraUserInfo, [])

    return(
        <Container>
            <Row>
                <Col xs={2}>
                    <span>{props.textToShow.usedText}: {props.extraInfo.used}</span>
                </Col>
                <Col xs={2}>
                    <span>{props.textToShow.availText}: {props.extraInfo.available}</span>
                </Col>
                <Col xs={2}>
                    <span>{props.textToShow.totalText}: {props.extraInfo.total}</span>
                </Col>
            </Row>
        </Container>
    )
}

export default GetExtraUserInfo