import React from 'react';
import {Controller, useForm} from "react-hook-form";
import {Form, InputGroup} from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import {Grid} from "@material-ui/core";

interface IProps {
    usersList: IUser[],
    rowData: {
        id: number,
        name: string,
        position: string,
        hide_to: any[]
    },
    data: any
}

interface IUser {
    label: string,
    value: any,
    key: string
}

//Component for Form edit
const FormEdit = (props: IProps) => {

    //Init UseForm
    const {register, control, errors} = useForm()

    const onChangeDialog = (event: any) => {
        let data = control.getValues()
        data.id = parseInt(props.rowData.id.toString())
        props.data(data)
    }

    return (
        <Form onChange={onChangeDialog}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={8}>
                    <Form.Group controlId={"formNomeMaschera"}>
                        <Form.Label>Nome Maschera</Form.Label>
                        <InputGroup>
                            <Controller
                                name={"name"}
                                defaultValue={props.rowData.name}
                                control={control}
                                render={({onChange, value}) => (
                                    <input
                                        name="Nome Maschera"
                                        type="text"
                                        className="form-control"
                                        placeholder="Nome Maschera"
                                        aria-label="Nome Maschera"
                                        aria-describedby="basic-addon1"
                                        onChange={value => onChange(value)}
                                        value={value}
                                        required
                                    />
                                )}
                            />
                        </InputGroup>
                    </Form.Group>
                </Grid>
                <Grid item xs={4}>
                    <Form.Group controlId={"formPosition"}>
                        <Form.Label>Posizione</Form.Label>
                        <InputGroup>
                            <input
                                name="position"
                                type="number"
                                className="form-control"
                                placeholder="Posizione"
                                aria-label="Posizione"
                                aria-describedby="basic-addon1"
                                defaultValue={parseInt(props.rowData.position)}
                                ref={
                                    register({
                                        min: 1,
                                        valueAsNumber: true,
                                        validate: {
                                            positive: value => parseInt(value) > 0
                                        }
                                    })
                                }
                                required
                            />
                            {errors.position?.type === "min" && (
                                <p style={{color: "red"}}>Inserire un numero maggiore di 0</p>)}
                            {errors.position?.type === "positive" && (
                                <p style={{color: "red"}}>Inserire un valore positivo</p>)}
                        </InputGroup>
                    </Form.Group>
                </Grid></Grid>
            <Grid
                container
                justify="center"
                alignItems="center"
            >
                <Form.Group controlId={"formHideToE"}>
                    <Form.Label>Non Visualizzare Per</Form.Label>
                    <InputGroup>
                        <Controller
                            name={"hide_to"}
                            defaultValue={props.rowData.hide_to}
                            control={control}
                            render={({onChange, value}) => (
                                <MultiSelect
                                    options={props.usersList}
                                    value={value}
                                    onChange={(value: []) => {onChange(value)}}
                                    labelledBy={"Select"}
                                    overrideStrings={{
                                        "selectSomeItems": "Seleziona...",
                                        "allItemsAreSelected": "Tutto il Team è stato selezionato.",
                                        "selectAll": "Seleziona Tutto",
                                        "search": "Cerca",
                                        "clearSearch": "Cancella Ricerca"
                                    }}
                                />
                            )}
                        />
                    </InputGroup>
                </Form.Group>
            </Grid>
        </Form>
    )

}

export default FormEdit