import React from "react";
import {Control, Controller} from "react-hook-form";
import {Form, InputGroup, Button, Col, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
import 'moment/locale/it'
import AjaxCodiceFiscale from "./AjaxCodiceFiscale";
import CurrencyInput from "react-currency-input-field";
import {Padding} from "@material-ui/core/TableCell/TableCell";

interface IRouter {
    form_id: string,
    record_id: string
}

interface IField {
    id: number,
    id_api: string,
    name: string,
    type: string,
    label: string,
    pholder: string,
    required: boolean,
    extra_options: string,
    form_name: string,
    ext_field: boolean
}

interface IUser {
    email:  string,
    name:   string
}

interface IPracties {
    id:       string,
    protocol: string
}

interface IForm {
    id:        number,
    name:      string,
    position:  string,
    hide_to:   string
}

interface IProps extends React.HTMLAttributes<HTMLFormElement> {
    obj_control:    Control<Record<string, any>>,
    arr_fieldsList: IField[],
    usersList:      IUser[],
    formsList:      IForm[],
    other_practies: IPracties[],
    token:          string,
    protocol:       string,
    routerprop:     IRouter
}

const PrintFieldsList = ({className, obj_control, arr_fieldsList, token, usersList, formsList, routerprop, protocol, other_practies}: IProps) => {

    return (
        <div className={className}>
            {
                routerprop.record_id !== undefined ? (
                    <div>
                        <Row>
                            <Col sm={12}>
                                <span style={{paddingLeft: "5px"}}><b>Numero Pratica:</b> {protocol}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <img
                                    src={"https://chart.googleapis.com/chart?cht=qr&chs=150x150&chl=" + protocol + "&choe=UTF-8"}
                                ></img>
                            </Col>
                        </Row>
                    </div>
                ): null
            }
            {
                arr_fieldsList.map(function (field, key) {
                    switch (field.type) {
                        case "text": {
                            return (
                                <Form.Group controlId={field.name} key={key}>
                                    <Form.Label>{field.label}</Form.Label>
                                    <InputGroup>
                                        <Controller
                                            name={field.name}
                                            defaultValue={""}
                                            control={obj_control}
                                            render={({onChange, value}) => (
                                                <input
                                                    name={field.name}
                                                    type={field.type}
                                                    className="form-control"
                                                    placeholder={field.pholder}
                                                    aria-label={field.label}
                                                    aria-describedby="basic-addon1"
                                                    onChange={value => onChange(value)}
                                                    value={value}
                                                    required={Boolean(field.required)}
                                                />
                                            )}
                                        />
                                        {(field.name === "allegati") ?
                                            <Button
                                                variant={"info"}
                                                onClick={() => {
                                                    let val: string = obj_control.getValues().allegati;
                                                    if (val !== "") {
                                                        window.open(val, '_blank');
                                                    }
                                                }}
                                            >APRI LINK</Button>
                                            :
                                            null
                                        }
                                        {(field.name === "codice_fiscale") ?
                                            <Button
                                                variant={"info"}
                                                onClick={() => {
                                                    let val: string = obj_control.getValues().codice_fiscale;
                                                    if (val === "") {
                                                        if (obj_control.getValues().nome !== undefined &&
                                                            obj_control.getValues().cognome !== undefined &&
                                                            obj_control.getValues().data_nascita !== undefined &&
                                                            obj_control.getValues().luogo_nascita !== undefined &&
                                                            obj_control.getValues().sesso !== undefined
                                                        ) {
                                                            let data_nascita = obj_control.getValues().data_nascita.toDateString();
                                                            AjaxCodiceFiscale({
                                                                token: token,
                                                                data: {
                                                                    nome: obj_control.getValues().nome,
                                                                    cognome: obj_control.getValues().cognome,
                                                                    data_nascita: data_nascita,
                                                                    comune: obj_control.getValues().luogo_nascita,
                                                                    sesso: obj_control.getValues().sesso
                                                                }
                                                            }).then(response => {
                                                                if (response.type === "Success") {
                                                                    obj_control.setValue("codice_fiscale", response.data);
                                                                }
                                                            })
                                                        }
                                                    }
                                                }}
                                            >CALCOLA</Button>
                                            :
                                            null
                                        }
                                    </InputGroup>
                                </Form.Group>)
                        }
                        case "textarea": {
                            return (
                                <Form.Group controlId={field.name} key={key}>
                                    <Form.Label>{field.label}</Form.Label>
                                    <InputGroup>
                                        <Controller
                                            name={field.name}
                                            defaultValue={""}
                                            control={obj_control}
                                            render={({onChange, value}) => (
                                                <textarea
                                                    name={field.name}
                                                    cols={15}
                                                    rows={5}
                                                    className="form-control"
                                                    placeholder={field.pholder}
                                                    aria-label={field.label}
                                                    aria-describedby="basic-addon1"
                                                    onChange={value => onChange(value)}
                                                    value={value}
                                                    required={field.required}
                                                />
                                            )}
                                        />
                                    </InputGroup>
                                </Form.Group>)
                        }
                        case "number": {
                            return (
                                <Form.Group controlId={field.name} key={key}>
                                    <Form.Label>{field.label}</Form.Label>
                                    <InputGroup>
                                        <Controller
                                            name={field.name}
                                            defaultValue={""}
                                            control={obj_control}
                                            render={({onChange, value}) => (
                                                <input
                                                    name={field.name}
                                                    type={field.type}
                                                    className="form-control"
                                                    style={{maxWidth: '50%'}}
                                                    placeholder={field.pholder}
                                                    aria-label={field.label}
                                                    aria-describedby="basic-addon1"
                                                    onChange={value => onChange(value)}
                                                    value={value}
                                                    required={field.required}
                                                />
                                            )}
                                        />
                                    </InputGroup>
                                </Form.Group>)
                        }
                        case "dropdown": {
                            return (
                                <Form.Group controlId={field.name} key={key}>
                                    <Form.Label>{field.label}</Form.Label>
                                    <InputGroup>
                                        <Controller
                                            name={field.name}
                                            control={obj_control}
                                            defaulValue={""}
                                            render={({onChange, value}) => (
                                                <select
                                                    name={field.name}
                                                    className="browser-default custom-select"
                                                    onChange={value => {
                                                        onChange(value)
                                                    }} required={field.required}
                                                    value={value}>
                                                    <option key={"Default"} value={""}></option>
                                                    {field.extra_options.split(";").map(function (option, index) {
                                                        return (
                                                            <option key={index} value={option}>{option}</option>
                                                        )
                                                    })}
                                                </select>
                                            )}
                                        />
                                    </InputGroup>
                                </Form.Group>)
                        }
                        case "date": {
                            return (
                                <Form.Group controlId={field.name} key={key}>
                                    <Form.Label>{field.label}</Form.Label>
                                    <InputGroup>
                                        <Controller
                                            name={field.name}
                                            control={obj_control}
                                            render={({onChange, value}) => (
                                                <DatePicker
                                                    className={"form-control"}
                                                    placeholderText={"00/00/0000"}
                                                    name={field.name}
                                                    selected={value}
                                                    onChange={(date) => {
                                                        onChange(date)
                                                    }}
                                                    required={field.required}
                                                    dateFormat={"dd/MM/yyyy"}
                                                    locale={it}
                                                    isClearable
                                                    autoComplete={"off"}
                                                />
                                            )}
                                        />
                                    </InputGroup>
                                </Form.Group>)
                        }
                        case "currency": {
                            return (
                                <Form.Group controlId={field.name} key={key}>
                                    <Form.Label>{field.label}</Form.Label>
                                    <InputGroup>
                                        <Controller
                                            name={field.name}
                                            control={obj_control}
                                            defaultValue={""}
                                            render={({onChange, value}) => (
                                                <CurrencyInput
                                                    id="validationCustom01"
                                                    name="input-1"
                                                    className={"form-control"}
                                                    value={value}
                                                    onValueChange={value => {
                                                        onChange(value)
                                                    }}
                                                    placeholder="Inserisci un valore"
                                                    prefix={"€ "}
                                                    step={1}
                                                    decimalScale={2}
                                                    style={{maxWidth: '50%'}}
                                                />
                                            )}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            )
                        }
                        case 'div': {
                            return (
                                <Form.Group controlId={field.name} key={key}>
                                    <Form.Label>{field.label}</Form.Label>
                                    <InputGroup>
                                        <Controller
                                            name={field.name}
                                            control={obj_control}
                                            render={({onChange, value}) => (
                                                <div className={"input-group"} style={{position: "absolute", borderRadius: "0.25rem", resize: "vertical", border: "1px solid #ccc", cursor: "text", height: "135px",  overflow: "auto", width: "100%"}}>
                                                    <div className={"form-group"} dangerouslySetInnerHTML={{ __html: value }}></div>
                                                </div>
                                            )}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            )
                        }
                        case 'affide_to': {
                            return (
                                <Form.Group controlId={field.name} key={key}>
                                    <Form.Label>{field.label}</Form.Label>
                                    <InputGroup>
                                        <Controller
                                            name={field.name}
                                            control={obj_control}
                                            render={({onChange, value}) => (
                                                <select
                                                    name={field.name}
                                                    className="browser-default custom-select"
                                                    onChange={value => {
                                                        onChange(value)
                                                    }} required={field.required}
                                                    value={value}>
                                                    <option key={"Default"} value={""}></option>
                                                    {usersList.map( (user:IUser, index:number)  => {
                                                        return (
                                                            <option key={index} value={user.email}>{user.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            )}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            )
                        }
                        case "others_practice": {
                            return (
                                <Form.Group controlId={field.name} key={key}>
                                    <Form.Label>{field.label}</Form.Label>
                                    <InputGroup>
                                        <Controller
                                            name={field.name}
                                            control={obj_control}
                                            defaulValue={""}
                                            render={({onChange, value}) => (
                                                <select
                                                    name={field.name}
                                                    className="browser-default custom-select"
                                                    onChange={value => {
                                                        onChange(value)
                                                    }}
                                                    value={value}>
                                                    <option key={"Default"} value={""}></option>
                                                    {
                                                        other_practies instanceof Array ? (
                                                                other_practies.map( (records:any, index:number) =>
                                                            {
                                                                return (
                                                                    <option key={index} value={records.record_id}>{records.protocol}</option>
                                                                )
                                                            }
                                                          )
                                                        )
                                                        : null
                                                    }
                                                </select>
                                            )}
                                        />
                                        <Button
                                            variant={"info"}
                                            onClick={() => {
                                                let formView: string = routerprop.form_id;
                                                let practieId = obj_control.fieldsRef.current.altre_pratiche?.ref.value;
                                                let val = "/forms-view/" + formView + "/" + practieId;
                                                if (val !== "" && formView !== undefined && practieId !== undefined) {
                                                    window.open(val, '_blank');
                                                }
                                            }}
                                        >APRI PRATICA</Button>
                                    </InputGroup>
                                </Form.Group>)
                        }
                        case "formsList": {
                            return (
                                <Form.Group controlId={field.name} key={key}>
                                    <Form.Label>{field.label}</Form.Label>
                                    <InputGroup>
                                        <Controller
                                            name={field.name}
                                            control={obj_control}
                                            defaulValue={""}
                                            render={({onChange, value}) => (
                                                <select
                                                    name={field.name}
                                                    className="browser-default custom-select"
                                                    onChange={value => {
                                                        onChange(value)
                                                    }} required={field.required}
                                                    value={value}>
                                                    <option key={"Default"} value={""}></option>
                                                    {formsList.map(function (option:IForm, index:number) {
                                                        return (
                                                            <option key={index} value={option.id}>{option.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            )}
                                        />
                                    </InputGroup>
                                </Form.Group>)
                        }
                    }
                    return key
                })
            }
        </div>
    )

}

export default PrintFieldsList;