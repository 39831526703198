import React from 'react';
import {Page404Context} from "../context/Page404Context";
import {UserContext} from "../context/UserContext";
import MUIDataTable, {MUIDataTableColumnDef} from "mui-datatables";
import GetFieldsRecordsList from "./GetFieldsRecordsList";
import "./css/recordslist.css";
import LoadingPage from "../loadingpage_components/LoadingPage";

interface IColumn {
    field_name: string,
    label: string
}

const RecordsList = () => {

    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false

    //User Context
    const {infos, setInfos}: any = React.useContext(UserContext)

    const [loading, setLoading] = React.useState(true);
    const [records, setRecords] = React.useState([]);

    const columns: MUIDataTableColumnDef[] = [
        {
            name: "id",
            label: "ID",
        },
        {
            name: "id_form",
            label: "ID Maschera",
        },
        {
            name: "data_affidamento",
            label: "Data Affidamento",
        },
        {
            name: "avvocato",
            label: "Avvocato",
        },
        {
            name: "domiciliatario",
            label: "Domiciliatario",
        },
        {
            name: "referente",
            label: "Referente",
        },
        {
            name: "data_acquisizione",
            label: "Data Acquisizione",
        },
        {
            name: "tematica",
            label: "Tematica",
        },
        {
            name: "problematica",
            label: "Problematica",
        },
        {
            name: "provenienza",
            label: "Provenienza",
        },
        {
            name: "stato_pratica",
            label: "Stato Pratica",
        },
        {
            name: "nome",
            label: "Nome",
        },
        {
            name: "cognome",
            label: "Cognome",
        },
        {
            name: "controparte",
            label: "Controparte",
        },
        {
            name: "codice_fiscale",
            label: "Codice Fiscale",
        },
        {
            name: "data_nascita",
            label: "Data di Nascita",
        },
        {
            name: "luogo_nascita",
            label: "Luogo di Nascita",
        },
        {
            name: "sesso",
            label: "Sesso",
        },
        {
            name: "indirizzo",
            label: "Indirizzo",
        },
        {
            name: "citta",
            label: "Città",
        },
        {
            name: "provincia",
            label: "Provincia",
        },
        {
            name: "cap",
            label: "Cap",
        },
        {
            name: "regione",
            label: "Regione",
        },
        {
            name: "scadenza_ci",
            label: "Scadenza CI",
        },
        {
            name: "procura",
            label: "Procura",
        },
        {
            name: "priorita",
            label: "Priorità",
        },
        {
            name: "punto_sos",
            label: "Punto SOS",
        },
        {
            name: "fan",
            label: "Fan",
        },
        {
            name: "telefono",
            label: "Telefono",
        },
        {
            name: "recapito_alternativo",
            label: "Recapito Alternativo",
        },
        {
            name: "email",
            label: "Email",
        },
        {
            name: "iban",
            label: "Iban",
        },
        {
            name: "appunti",
            label: "Appunti",
        },
        {
            name: "note",
            label: "Note",
        },
        {
            name: "allegati",
            label: "Allegati",
        },
        {
            name: "messaggio",
            label: "Messaggio",
        },
        {
            name: "descrizione_fatti",
            label: "Descrizione Dei Fatti",
        }
    ];

    const Init = () => {
        GetFieldsRecordsList({
            token: infos.token
        }).then(result => {
            if (result != undefined) {
                if (result.type === 'Success') {
                    let records = JSON.parse(result.data.toString()).records;
                    setRecords(records);
                    setLoading(false);
                } else if(result.type === 'Error') {
                    setLoading(false);
                }
            }
        }).finally( () => {
            setLoading(false);
        })
    }
    React.useEffect(Init, []);

    return (
        <div>
            <LoadingPage isLoading={loading}></LoadingPage>
            <MUIDataTable
                title={"Lista Pratiche"}
                data={records}
                columns={columns}
                options={{
                    setRowProps: (row, dataIndex) => {
                        return ({
                            onDoubleClick: (event: any) => {
                                window.open(
                                    '/forms-view/' + row[1] + '/' + row[0],
                                    "_blank"
                                );
                            },
                            style: { whiteSpace: 'nowrap'}
                        });
                    },
                    rowsPerPage: 10,
                    selectableRows: "none",
                    filter: true,
                    filterType: 'dropdown',
                    responsive: 'vertical',
                    fixedHeader: true,
                    fixedSelectColumn: true,
                    tableBodyHeight: '100%',
                    draggableColumns: {
                        enabled: true
                    },
                    expandableRowsOnClick: true,
                    textLabels: {
                        body: {
                            noMatch: "Mi dispiace, nessuna corrispondenza trovata",
                            toolTip: "Filtra",
                            //columnHeaderTooltip: column => `Sort for ${column.label}`
                        },
                        pagination: {
                            next: "Pagina Seguente",
                            previous: "Pagina Precedente",
                            rowsPerPage: "Righe per pagina:",
                            displayRows: "di",
                        },
                        toolbar: {
                            search: "Cerca",
                            downloadCsv: "Download CSV",
                            print: "Stampa",
                            viewColumns: "Schermata Colonne",
                            filterTable: "Filtra Tabella",
                        },
                        filter: {
                            all: "Tutto",
                            title: "FILTRI",
                            reset: "RESET",
                        },
                        viewColumns: {
                            title: "Mostra Colonne",
                            titleAria: "Mostra/Nascondi Colonne Tabella",
                        },
                        selectedRows: {
                            text: "riga(righe) selezionata/e",
                            // delete: "Elimina",
                            deleteAria: "Elimina righe selezionate",
                        },
                    }
                }}
            ></MUIDataTable>
        </div>
    )
}

export default RecordsList