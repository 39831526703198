import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    token:      string,
    query:      string,
    sUserName:  string,
    qName:      string,
    menu:       string,
    btn_menu:   string,
    submenu:    string,
    extra:      string,
    position:   number,
    jdata:      string,
}

interface IMessage {
    id:         number,
    type:       string,
    message:    string,
    data:       string
}

async function SaveQueryBuilder(props: IProps) {

    let results:IMessage = {
        id:         0,
        type:       "",
        message:    "",
        data:       ""
    }

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "qbuilder/save",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token:      props.token,
                    query:      props.query,
                    sUserName:  props.sUserName,
                    qName:      props.qName,
                    menu:       props.menu,
                    btn_menu:   props.btn_menu,
                    submenu:    props.submenu,
                    extra:      props.extra,
                    position:   props.position,
                    jdata:      props.jdata,
                })
            }).then((response) => {
                if(response.statusText === 'OK' || response.status == 200) {
                    results = response.data
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default SaveQueryBuilder;