import React from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {CardContent, CardHeader, Grid} from "@material-ui/core";

interface IProps {
    arrayMenu: IMenu[],
    queries: IQData[],
    type: {
        menu?: boolean,
        submenu?: boolean
    }
}

interface IMenu {
    type:         string,
    name:         string,
    position:     number,
}

interface IQData {
    id:         number,
    user_id:    number,
    name:       string,
    menu:       string,
    btn_menu:   string,
    submenu:    string,
    jdata:      string,
}

const PrintMenu = (props:IProps) => {

    const OnClickHandler = (id:number) => {
        window.open(
            '/query-records/' + id,
            "_blank"
        );
    }

    const OnBtnMenuHandler = (id:string) => {
        window.open(
            '/dashboard/' + id,
            "_blank"
        );
    }

    const OnBtnFormHandler = (jdata:string) => {
        let data = JSON.parse(jdata);
        if(data !== null) {
            window.open(
                '/forms-view/' + data.form_id,
                "_blank"
            );
        }
    }

    return(
        <Container fluid={"sm"}>
            {
                props.arrayMenu.map( (item: IMenu, index: number) => {
                    return(
                        <Row className={"mt-3"} key={index}>
                            <Col sm={12}>
                                <Card>
                                    <CardHeader
                                        title={item.name}
                                    />
                                    <CardContent>
                                        <Grid container spacing={2} justifyContent={"center"} alignItems="center">
                                            {
                                                props.type.menu ?
                                                    props.queries.filter( (query:IQData) => query.menu == item.name ).map( (filteredQuery:IQData, index:number) => {
                                                        return(
                                                            <Grid item key={index}>
                                                                {
                                                                    (filteredQuery.submenu == null || filteredQuery.submenu == undefined || filteredQuery.submenu == "") ?
                                                                        (filteredQuery.jdata === null || filteredQuery.jdata === "" || filteredQuery.jdata === "{}") ?
                                                                            <Button onClick={ () => OnClickHandler(filteredQuery.id) }>{filteredQuery.name}</Button>
                                                                            :
                                                                            <Button onClick={ () => OnBtnFormHandler(filteredQuery.jdata) }>{filteredQuery.name}</Button>
                                                                    : <Button onClick={() => OnBtnMenuHandler(filteredQuery.submenu)}>{filteredQuery.submenu}</Button>
                                                                }
                                                            </Grid>
                                                        )
                                                    })
                                                    : props.type.submenu ?
                                                        props.queries.filter( (query:IQData) => query.submenu == item.name ).map( (filteredQuery:IQData, index:number) => {
                                                            return(
                                                                <Grid item key={index}>
                                                                    {
                                                                        (filteredQuery.jdata === null || filteredQuery.jdata === "" || filteredQuery.jdata === "{}") ?
                                                                            <Button onClick={ () => OnClickHandler(filteredQuery.id) }>{filteredQuery.name}</Button>
                                                                            :
                                                                            <Button onClick={ () => OnBtnFormHandler(filteredQuery.jdata) }>{filteredQuery.name}</Button>
                                                                    }
                                                                </Grid>
                                                            )
                                                        })
                                                    : null
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Col>
                        </Row>
                    )
                })
            }
        </Container>
    )
}

export default PrintMenu