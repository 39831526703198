import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    record_id: number,
    token: string,
}

async function RetrieveRecord(props: IProps) {

    let results:string = ""

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "api/record",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    record_id: props.record_id,
                    token: props.token
                })
            }).then((response) => {
                if(response.statusText === 'OK' || response.statusText === '') {
                    results = response.data
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default RetrieveRecord;