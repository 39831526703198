import React from 'react';
import {UserContext} from "../context/UserContext";
import {Page404Context} from "../context/Page404Context";
import {Container, Button, Col, Form, InputGroup, Row, Table} from "react-bootstrap";
import {useForm} from "react-hook-form";
import RetrieveColumnsList from "./AjaxRetrieveColumnsList";
import ReactDragListView from "react-drag-listview";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faMinus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import FieldsOrderList from "./AjaxFieldsOrderList";
import GetFieldsOrderListByID from "./AjaxFieldsOrderListByID";
import SaveFieldsOrderList from "./AjaxSaveFieldsOrderList";
import DeleteFieldsOrderList from "./AjaxDeleteFieldsOrderList";
import RetrieveQueryListDropDown from "../reusable_components/QueryListDropDown";


interface IQuery {
    id:   number,
    name: string,
}
interface IField {
    name:           string,
    label:          string,
    type:           string,
    extra_field:    boolean
}
interface IFol {
    id:         number,
    query_id:   number,
    query_name: string,
}

const OrderFieldsPage = () => {

    //User Context
    const { infos, setInfos }:any = React.useContext(UserContext)
    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false;

    //Init UseForm
    const {control, handleSubmit} = useForm();
    const [isExtr, setIsExtr] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [selectedQuery, setSelectedQuery] = React.useState<IQuery>();
    const [fieldsList, setFieldsList] = React.useState<IField[]>([]);
    const [foList, setFoList] = React.useState<IFol[]>([]);

    const onSubmit = (data:any) => {
        setIsExtr(true);
        RetrieveColumnsList({
            token: infos.token,
        }).then( result => {
            if(result.type === 'Success') {
                let flist = result.data;
                setFieldsList([]);
                flist.map( (field:IField, id:number) => {
                    if(field.name !== "user_id" && field.name !== "id" && field.name !== "manager_id"
                      && field.name !== "created" && field.name !== "last_update"
                      && field.name !== "extra_data" && field.name !== "id_form") {
                        setFieldsList(prevState => [
                            ...prevState,
                            field
                        ])
                    }
                })
            }
        }).catch( (e) => console.error(e.toString())
        ).finally( () => setIsExtr(false) )
        //console.log(selectedForm);

    }

    const drag = (fromIndex:number, toIndex:number) => {
        const columnsCopy = fieldsList.slice();
        const item = columnsCopy.splice(fromIndex, 1)[0];
        columnsCopy.splice(toIndex, 0, item);
        setFieldsList(columnsCopy);
    }

    const fieldsOrderList = () => {
        setFoList([])
        FieldsOrderList({
            token: infos.token
        }).then( result => {
            if(result.type === 'Success') {
                setFoList(result.data);
            }
        }).catch( error => {
            console.error(error)
        })
    }

    const fieldsOrderListById = (id:number) => {
        GetFieldsOrderListByID({
            token: infos.token,
            query_id: id,
        }).then( result => {
            if(result.type === 'Success') {
                setFieldsList(result.data);
                setSelectedQuery({
                    id: id,
                    name: "",
                })
            }
        }).catch( error => {console.error(error)})
    }

    const saveData = () => {
        if(fieldsList.length > 0 && selectedQuery !== undefined ) {
            SaveFieldsOrderList({
                token: infos.token,
                query_id: selectedQuery.id,
                JSONfields: JSON.stringify(fieldsList)
            }).then( result => {
                fieldsOrderList()
                alert(result.message);
                console.log(result);
            }).catch( error => ( console.error(error) ))
        }
    }

    const deleteOrderListById = (id:number) => {
        DeleteFieldsOrderList({
            token: infos.token,
            id: id,
        }).then( result => {
            fieldsOrderList()
            alert(result.message);
            console.log(result)
        }).catch( error => {
            console.error(error)
        })
    }

    React.useEffect(fieldsOrderList, []);

    return (
        <Container>
            <Row style={{marginTop: "20px", marginBottom: "20px"}}>
                <Col sm={12}>
                    <h2>GESTIONE ORDINE CAMPI DELLE MASCHERE</h2>
                </Col>
            </Row>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col sm={4}>
                        <RetrieveQueryListDropDown
                            token={infos.token}
                            label={"Lista Query"}
                            querySelected={setSelectedQuery}
                        />
                    </Col>
                    <Col sm={2} className={"py-2"}>
                        <Form.Group controlId={"formSave"}>
                            <Form.Label></Form.Label>
                            <InputGroup>
                                {
                                    isExtr ? (
                                        <Button type={"button"} disabled={true} className={"btn btn-primary"}>
                                            <span className="spinner-border"
                                                  style={{
                                                      width: "1.5rem",
                                                      height: "1.5rem",
                                                      marginRight: "5px"
                                                  }}
                                                  role="status"
                                                  aria-hidden="true">
                                            </span>
                                            ESTRAZIONE...
                                        </Button>
                                    ) : (
                                        <Button name={"save"} type={"submit"} className={"btn btn-info"} >
                                            ESTRAI CAMPI
                                        </Button>
                                    )
                                }
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Table hover bordered responsive>
                    <thead className={"thead-dark"}>
                    <tr>
                        <th style={{textAlign: "center"}}>ID</th>
                        <th style={{textAlign: "center"}}>QUERY ID</th>
                        <th style={{textAlign: "center"}}>QUERY NAME</th>
                        <th style={{textAlign: "center"}}>ACTIONS</th>
                    </tr>
                    </thead>
                    <tbody>
                    {foList.length > 0 ?
                        foList.map( (field:IFol, index:number) => {
                        return (
                            <tr key={index}>
                                <td style={{textAlign: "center"}}>{field.id}</td>
                                <td style={{textAlign: "center"}}>{field.query_id}</td>
                                <td style={{textAlign: "center"}}>{field.query_name}</td>
                                <td style={{textAlign: "center"}}>
                                    <span style={{padding: "0px 20px 0px 0px"}}><i><FontAwesomeIcon icon={faEdit} style={{cursor: "pointer"}} onClick={ () => {fieldsOrderListById(field.query_id)} }/></i></span>
                                    <span style={{padding: "0px 20px 0px 0px"}}><i><FontAwesomeIcon icon={faTrash}  style={{cursor: "pointer"}} onClick={ () => { deleteOrderListById(field.id) } }/></i></span>
                                </td>
                            </tr>
                        )
                    })
                        : null
                    }
                    </tbody>
                </Table>
            </Row>
            <Row>
                <ReactDragListView.DragColumn onDragEnd={drag} nodeSelector="tr">
                    <Table hover bordered responsive>
                        <thead className={"thead-dark"}>
                            <tr>
                                <th style={{textAlign: "center"}}>NAME</th>
                                <th style={{textAlign: "center"}}>LABEL</th>
                                <th style={{textAlign: "center"}}>INPUT TYPE</th>
                                <th style={{textAlign: "center"}}>EXTRA FIELD</th>
                            </tr>
                        </thead>
                        <tbody>
                        {fieldsList.map( (field:IField, index:number) => {
                            return (
                                <tr key={index}>
                                    <td>{field.name}</td>
                                    <td>{field.label}</td>
                                    <td>{field.type}</td>
                                    <td style={{textAlign: "center"}}>{field.extra_field ? <FontAwesomeIcon icon={faCheckSquare} /> :
                                        <FontAwesomeIcon icon={faMinus} /> }</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </ReactDragListView.DragColumn>
            </Row>
            <Row>
                {fieldsList.length > 0 && (selectedQuery !== undefined && selectedQuery?.id > 0) ?
                    <Button onClick={ saveData }>SALVA</Button>
                    : null
                }
            </Row>
        </Container>
    )
}

export default OrderFieldsPage