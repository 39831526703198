import React from 'react';
import {Page404Context} from "../context/Page404Context";
import {UserContext} from "../context/UserContext";
import {Container} from "@material-ui/core";
import AddApiComponent from "./AddApiComponent";
import ManageTOC from "./ManageTOC";
import {RouteComponentProps} from "react-router-dom";

interface IAddedApi {
    name: string,
    api: string
}

interface IRoute {
    esapi_id: string
}

const EsaManager = ( {match}:RouteComponentProps<IRoute> ) => {

    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false;
    //User Context
    const {infos, setInfos}: any = React.useContext(UserContext)
    const [apisList, setApisList] = React.useState<IAddedApi[]>([]);

    return (
        <Container>
            <AddApiComponent
                token={infos.token}
                setApisList={setApisList}
            />
            <ManageTOC
                token={infos.token}
                apisList={apisList}
                esapi_id={match.params.esapi_id}
            />
        </Container>
    )
}

export default EsaManager