import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    form_id:    number,
    record_id:  number,
    token:      string,
    values:     any
}

async function AjaxUpdateFormValues(props: IProps) {

    type jsonret = {
        Result: string,
        RecordId: string,
        ProtocolId: string
    }

    let results:jsonret = {
        Result: "",
        RecordId: "",
        ProtocolId: ""
    }

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "api/update_form_values",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    form_id:    props.form_id,
                    record_id:  props.record_id,
                    token:      props.token,
                    values:     props.values
                })
            }).then((response) => {
                if(response.statusText === 'OK' || response.statusText === '') {
                    results = response.data
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default AjaxUpdateFormValues;