import React from 'react';
import {UserContext} from "../context/UserContext";
import {Page404Context} from "../context/Page404Context";
import {Card, Col, Container, Form, FormGroup, FormControl, Row, Button} from 'react-bootstrap';
import {CardContent, CardHeader} from "@material-ui/core";
import GetExtraUserInfo from "../reusable_components/GetExtraUserInfo";
import {Controller, useForm} from "react-hook-form";
import UsersListDropDown from "../reusable_components/UsersListDropDown";
import FormsListDropDown from "../reusable_components/FormsListDropDown";
import TableDataPipeDrive from "./TableDataPipeDrive";
import AjaxSavePipeData from "./AjaxSavePipeData2DB";
import {confirmAlert} from "react-confirm-alert";

interface IAPipeData {
    total: number,
    used: number,
    available: number
}

interface IUser {
    name: string,
    email: string,
}

const PipeDrive = () => {

    //User Context
    const { infos, setInfos }:any = React.useContext(UserContext)
    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false

    const { control, register, handleSubmit, watch, formState: { errors } } = useForm();
    const [selectedUser, setSelectedUser] = React.useState<IUser>();
    const [availablePipeData, setAvailablePipeData] = React.useState<IAPipeData>({
        total: 0,
        available: 0,
        used: 0
    });
    const [records, setRecords] = React.useState<any[]>([]);

    const onSubmit = (data:any) => {
        let message: string = "";
        AjaxSavePipeData({
            token: infos.token,
            data: data,
        }).then( result => {
           if(result != undefined && result.type == "Success") {
               setAvailablePipeData({
                   used: availablePipeData.used + 1,
                   available: availablePipeData.available - 1,
                   total: availablePipeData.total
               });
               message = result.message;
               confirmAlert({
                   title: 'Info',
                   message: message,
                   buttons: [
                       {
                           label: 'Chiudi',
                           onClick: () => {
                               document.location.reload();
                           }
                       }
                   ]
               })
           } else {
               confirmAlert({
                   title: 'Errore',
                   message: "Si sono riscontrati problemi.",
                   buttons: [
                       {
                           label: 'Chiudi',
                           onClick: () => null
                       }
                   ]
               })
           }
        });
    }

    return(
        <Container fluid={"sm"} style={{marginTop: "3%"}}>
            <Card>
                <CardHeader
                    title={"GESTIONE PIPEDRIVE"}
                    subheader={"sezione per la gestione delle estrazioni da PipeDrive"}
                />
                <CardContent>
                    <Row>
                        <Col sm={12}>
                            <GetExtraUserInfo
                                token={infos.token}
                                extraInfoName={"pipedata_available_users"}
                                textToShow={{
                                    usedText: "PipeData in uso",
                                    availText: "Disponibili",
                                    totalText: "In totale"
                                }}
                                extraInfo={availablePipeData}
                                extraInfoValues={setAvailablePipeData}
                            />
                        </Col>
                    </Row>
                    <Row className={"mt-5"}>
                        <Col sm={12}>
                            <TableDataPipeDrive
                                token={infos.token}
                                records={setRecords}
                                extraInfo={availablePipeData}
                                extraInfoValues={setAvailablePipeData}
                            />
                        </Col>
                    </Row>
                </CardContent>
            </Card>
            <div className={"my-4"}>
                <Card>
                    <CardHeader
                        title={"CONFIGURA PIPEDATA"}
                        subheader={"compila i campi per configurare l'estrazione da PipeDrive"}
                    />
                    <CardContent>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Form.Label>Inserisci il Nome della Configurazione</Form.Label>
                                        <Controller
                                            name={"pipeDataName"}
                                            control={control}
                                            defaultValue={""}
                                            render={ ({onChange, value}) =>
                                                <FormControl
                                                    placeholder={"Inserisci il Nome"}
                                                    type={"text"}
                                                    value={value}
                                                    onChange={ value => onChange(value)}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Controller
                                            name={"user"}
                                            control={control}
                                            defaultValue={""}
                                            render={ () =>
                                                <UsersListDropDown
                                                    token={infos.token}
                                                    label={"Seleziona il Collaboratore"}
                                                    userSelected={setSelectedUser}
                                                    control={control}
                                                />
                                            }
                                            />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Controller
                                            name={"form"}
                                            control={control}
                                            defaultValue={""}
                                            render={ () =>
                                                <FormsListDropDown
                                                    token={infos.token}
                                                    label={"Seleziona la Maschera"}
                                                    control={control}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Form.Label>Inserisci il Numero del Filtro</Form.Label>
                                        <Controller
                                            name={"filtro"}
                                            control={control}
                                            defaultValue={""}
                                            render={ ({onChange, value}) =>
                                                <FormControl
                                                    placeholder={"Inserisci il Filtro"}
                                                    type={"number"}
                                                    value={value}
                                                    onChange={ value => onChange(value)}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={8}>
                                    <FormGroup>
                                        <Form.Label>Inserisci EndPoint</Form.Label>
                                        <Controller
                                            name={"endpoint"}
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: true }}
                                            render={ ({onChange, value}) =>
                                                <FormControl
                                                    placeholder={"Inserisci EndPoint"}
                                                    type={"text"}
                                                    value={value}
                                                    required
                                                    onChange={ value => onChange(value)}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Button
                                        type={"submit"}
                                    >SALVA</Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardContent>
                </Card>
            </div>
        </Container>
    )
}

export default PipeDrive