import React, {ChangeEvent} from 'react';
import {Row, Col, Form, InputGroup, Button} from 'react-bootstrap';
import {Card, CardContent, CardHeader, Grid, Tooltip} from "@material-ui/core";
import MUIDataTable, {MUIDataTableColumnDef} from "mui-datatables";
import styled from "styled-components";
import axios from "axios";
import RetrieveExtraFieldsList from "../fields_components/AjaxGetExtraFieldsListManagerID";
import {staticFieldsArray} from "../data_components/fields_array";
import RetrieveUsersList from "../user_components/AjaxRetriveUsersList";
import EsaFields from "./EsaFields";
import GetExtraInfos from "../user_components/GetExtraInfos";
import RetrieveEsapi from "./AjaxRetrieveEsapi";
import AddRecordEsapi from "./AjaxAddRecordEsapi";
import RetrieveTacList from "./AjaxRetrieveTacList";
import {ArrowRight, DeleteForever} from "@material-ui/icons";
import TacPlay from "./AjaxTacPlay";
import TacDel from "./AjaxTacDel";
import AjaxFormsList from "../forms_components/AjaxFormsList";

interface IProps {
    token: string,
    api: IApiSelected | undefined,
    formsList: IJF[],
    esapi_id: string
}

interface IApiSelected {
    name: string,
    api: string
}

interface IJF {
    name: string,
    id: number
}

interface IJFields {
    name: string,
    text: string
}

interface IUser {
    name: string,
    email: string
}

interface ITac {
    fieldName: string,
    fieldValue: string | null
}

interface IProcessList {
    api:IApiSelected | undefined,
    jotForm:IJF | undefined,
    tacName: string | undefined,
    user:IUser | undefined,
    fields:ITac[],
    destForm: IJF | undefined,
}

interface IEsapiTac {
    total: number,
    used: number,
    available: number
}

interface IForm {
    id:        number,
    name:      string,
    position:  string,
    hide_to:   string
}

const JFManager = (props: IProps) => {

    const [selectedForm, setSelectedForm]   = React.useState<IJF>();
    const [selectedDForm, setSelectedDForm] = React.useState<IJF>();
    const [selectedName, setSelectedName]   = React.useState("");
    const [selectedUser, setSelectedUser]   = React.useState<IUser>();
    const [isRet, setIsRet]                 = React.useState(false);
    const [isSave, setIsSave]               = React.useState(false);
    const [jotFields, setJotFields]         = React.useState<IJFields[]>([]);
    const [formsList, setFormsList]         = React.useState<IForm[]>([]);

    const selectedHandle = (name: string, id: string, type: string) => {
        switch (type) {
            case "FORM":
                setSelectedForm({
                    name: name,
                    id: parseInt(id)
                })
                break;
            case "USER":
                setSelectedUser({
                    name: name,
                    email: id
                })
                break;
            case "DFORM":
                setSelectedDForm({
                    name: name,
                    id: parseInt(id)
                })
                break;
        }
    }

    const retrieveFieldsHandle = () => {
        if (selectedForm !== undefined) {
            setJotFields([]);
            setIsRet(true);
            axios.request({
                method: 'GET',
                url: 'https://eu-api.jotform.com/form/' + selectedForm.id + '/questions?apiKey=' + props.api?.api
            }).then((result: any) => {
                // console.log(result);
                if (result.status == 200) {
                    const content = result.data.content;
                    Object.values(content).map((field: any, index: number) => {
                        console.log(field);
                        setJotFields(prevState => [
                            ...prevState,
                            {
                                name: field.name,
                                text: field.text
                            }
                        ])
                        return index;
                    })
                    setIsRet(false);
                }
            })
        }
    }

    //CSS
    const BtnCSS = styled.div`
        margin-top: 2rem;
    `;

    type fieldStruct = {
        api: string,
        name: string,
        label: string,
        extraf: boolean
    };
    const [fieldsListSos, setFieldsListSos] = React.useState<fieldStruct[]>([]);
    const extraFieldsList = () => {
        RetrieveExtraFieldsList({
            token: props.token
        }).then(result => {
            if (result !== undefined) {
                if (result.type === "Success") {
                    const data = result.data;
                    setFieldsListSos(staticFieldsArray)
                    data.map((field: fieldStruct, index: number) => {
                        setFieldsListSos(prevState => [
                            ...prevState,
                            field
                        ])
                    })
                }
            }
            // console.log(fieldsListSos);
        })
    }
    React.useEffect(extraFieldsList, []);

    type userStruct = {
        email: string,
        name: string,
        surname: string,
        fullName: string
    }
    const [users, setUsers] = React.useState<userStruct[]>([]);
    const retrieveUsersList = () => {
        RetrieveUsersList({
            token: props.token,
        }).then(result => {
            if (result !== undefined) {
                if (result.type === "Success") {
                    const userData: userStruct[] = result.data;
                    userData.map((user: userStruct, index: number) => {
                        setUsers(prevState => [
                            ...prevState,
                            user
                        ]);
                    })
                }
            }
            // console.log(result.data);
        })
    }
    React.useEffect(retrieveUsersList, [])

    const retrieveFormsList = () => {
        AjaxFormsList({
            token: props.token
        }).then( results => {
            if( results != undefined ) {
                setFormsList( results );
            }
        })
    }
    React.useEffect(retrieveFormsList, []);

    const [availableTac, setAvailableTac] = React.useState<IEsapiTac>({
        total: 0,
        available: 0,
        used: 0
    });
    const retrieveExtraUserInfo = () => {
        GetExtraInfos({
            token: props.token,
            var_name: "esapi_available_tac"
        }).then( result => {
            if(result.data !== undefined) {
                const esapiInfo:IEsapiTac = JSON.parse(result.data);
                setAvailableTac(esapiInfo);
            }
        })
        if(props.esapi_id !== undefined) {
            RetrieveEsapi({
                token: props.token,
                esapi_id: props.esapi_id,
            }).then( result => {
                console.log(result);
            })
        }
    }
    React.useEffect(retrieveExtraUserInfo, [])

    const HandleClick = () => {
        setIsSave(true);
        const fieldsList: ITac[] = [];
        const list = document.querySelectorAll('div[contenteditable="true"]');
        const tacName:any = document.querySelector('input[id="tacName"]');
        list.forEach( (el) => {
            if(el.textContent !== "") {
                fieldsList.push({
                    fieldName: el.id,
                    fieldValue: el.textContent
                });
            }
        })
        setSelectedName(tacName?.value != undefined ? tacName?.value : "");
        const processList:IProcessList = {
            api: props.api,
            jotForm: selectedForm,
            tacName: selectedName,
            user: selectedUser,
            destForm: selectedDForm,
            fields: fieldsList,
        };
        // console.log(JSON.stringify(processList));
        AddRecordEsapi({
            token: props.token,
            esapi: JSON.stringify(processList)
        }).then( result => {
            // console.log(result.message);
            retrieveTacList();
            retrieveExtraUserInfo();
            alert(result.message);
            setIsSave(false);
        })
    }

    const initEsapiId = () => {
        if(props.esapi_id !== undefined) {
            //Recupero le informazioni sull'Api esterna
            console.log(props.esapi_id);
        } else {
            console.log("sbagliato");
        }
    }
    React.useEffect(initEsapiId, []);

    const retrieveTacList = () => {
        setLoading(true);
        RetrieveTacList({
            token: props.token
        }).then( (result) => {
            if(result.data != undefined && result.data != "{}") {
                let data:string[] = Array.from(result.data);
                setRecords([]);
                data.map( (val:any) => {
                    setRecords( (prevState:any) => [
                        ...prevState,
                        val
                    ])
                    setLoading(false);
                })
            }
        }).finally( () => {
            setLoading(false);
        })
    }

    React.useEffect(retrieveTacList, []);

    const [loading, setLoading] = React.useState<boolean>(true);

    const [records, setRecords] = React.useState<any>([]);

    const columns: MUIDataTableColumnDef[] = [
        {
            name: "id",
            label: "ID",
        },
        {
            name: "name",
            label: "Nome TAC",
        },
        {
            name: "execution",
            label: "Ultima Esecuzione",
        },
        {
            name: "operations",
            label: "Operazioni",
            options: {
                sort: false,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <Grid
                            container
                            item
                            xs={5}
                            alignItems={"center"}
                            justify={"space-between"}
                            direction={"row"}
                        >
                            <Tooltip onClick={() => tacPlay(tableMeta.rowData[0], tableMeta.rowData[1], tableMeta)} title={"Avvia"}>
                                <ArrowRight />
                            </Tooltip>
                            <div onClick={() => tacDel(tableMeta.rowData[0], tableMeta.rowData[1])}>
                                <Tooltip title={"Elimina"}>
                                    <DeleteForever color={"error"}/>
                                </Tooltip>
                            </div>
                        </Grid>
                    )
                },
            },
        }
    ]

    const tacPlay = (index: number, tac_name: string, name: string) => {
        if(index != undefined) {
            TacPlay({
                esapi_id: index.toString(),
                tac_name: tac_name,
                token: props.token
            }).then( result => {
                if(result != undefined) {
                    setRecords([]);
                    retrieveTacList();
                    alert("Tac eseguito con successo");
                } else {
                    alert("Si stanno riscontrando problemi");
                }
            })
        }
    }

    const tacDel = (index: number, name: string) => {
        TacDel({
            token: props.token,
            esapi_id: index.toString(),
            tac_name: name,
        }).then( result => {
            if(result != undefined) {
                retrieveTacList();
                retrieveExtraUserInfo();
                alert(result.message);
            }
        })
    }

    return (
        <div className={"my-4"}>
            <Card>
                <CardHeader
                    title={"Interazioni disponibili"}
                    subheader={"informazioni sulle interazioni con le Api esterne rimanenti"}
                />
                <CardContent>
                    <Row>
                        <Col xs={2}>
                            <span>Interazioni in uso: {availableTac.used}</span>
                        </Col>
                        <Col xs={2}>
                            <span>Disponibili: {availableTac.available}</span>
                        </Col>
                        <Col xs={2}>
                            <span>In totale: {availableTac.total}</span>
                        </Col>
                    </Row>
                </CardContent>
            </Card>
            <div className={"my-4"} />
            <Card>
                <CardHeader
                    title={"Interazioni create"}
                    subheader={"lista interazioni create"}
                />
                <CardContent>
                    <Row>
                        <Col xs={12}>
                            {loading ? <span>AGGIORNAMENTO DATI IN CORSO...</span>:
                                <MUIDataTable
                                    title={"Lista TAC"}
                                    data={records}
                                    columns={columns}
                                    options={{
                                        selectableRows: "none",
                                        filter: true,
                                        filterType: 'dropdown',
                                        responsive: 'vertical',
                                        fixedHeader: true,
                                        fixedSelectColumn: true,
                                        tableBodyHeight: '100%',
                                        draggableColumns: {
                                            enabled: true
                                        },
                                        expandableRowsOnClick: true,
                                        textLabels: {
                                            body: {
                                                noMatch: "Mi dispiace, nessuna corrispondenza trovata",
                                                toolTip: "Filtra",
                                                //columnHeaderTooltip: column => `Sort for ${column.label}`
                                            },
                                            pagination: {
                                                next: "Pagina Seguente",
                                                previous: "Pagina Precedente",
                                                rowsPerPage: "Righe per pagina:",
                                                displayRows: "di",
                                            },
                                            toolbar: {
                                                search: "Cerca",
                                                downloadCsv: "Download CSV",
                                                print: "Stampa",
                                                viewColumns: "Schermata Colonne",
                                                filterTable: "Filtra Tabella",
                                            },
                                            filter: {
                                                all: "Tutto",
                                                title: "FILTRI",
                                                reset: "RESET",
                                            },
                                            viewColumns: {
                                                title: "Mostra Colonne",
                                                titleAria: "Mostra/Nascondi Colonne Tabella",
                                            },
                                            selectedRows: {
                                                text: "riga(righe) selezionata/e",
                                                // delete: "Elimina",
                                                deleteAria: "Elimina righe selezionate",
                                            },
                                        }
                                    }}
                                ></MUIDataTable>
                            }
                        </Col>
                    </Row>
                </CardContent>
            </Card>
            <div className={"my-4"} />
            <Card>
                <CardHeader
                    title={"Seleziona un JotForm"}
                    subheader={"seleziona una maschera di JotForm per recuperare i campi"}
                />
                <CardContent>
                    <Row>
                        <Col sm={5}>
                            <Form.Group controlId={"name"}>
                                <Form.Label>Lista JotForms</Form.Label>
                                <InputGroup>
                                    <select
                                        className={"browser-default custom-select"}
                                        onChange={(data: ChangeEvent<HTMLSelectElement>) => selectedHandle(data.target.selectedOptions[0].innerText, data.target.value, "FORM")}
                                    >
                                        <option value=""></option>
                                        {
                                            props.formsList.map((form: IJF, index: number) => {
                                                return <option value={form.id} key={index}>{form.name}</option>
                                            })
                                        }
                                    </select>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <BtnCSS>
                                {
                                    isRet ? (
                                        <Button type={"button"} disabled={true} className={"btn btn-default"}>
                                                        <span className="spinner-border"
                                                              style={{
                                                                  width: "1.5rem",
                                                                  height: "1.5rem",
                                                                  marginRight: "5px"
                                                              }}
                                                              role="status"
                                                              aria-hidden="true">
                                                        </span>
                                            Recupero...
                                        </Button>
                                    ) : (
                                        <Button type={"button"} className={"btn btn-default"}
                                                onClick={retrieveFieldsHandle}>
                                            Recupera Campi
                                        </Button>
                                    )
                                }
                            </BtnCSS>
                        </Col>
                    </Row>
                </CardContent>
            </Card>
            <div className={"my-4"}></div>
            <Card>
                <CardHeader
                    title={"Seleziona Utente"}
                    subheader={"Seleziona a chi vuoi che vengano assegnate le pratiche"}
                />
                <CardContent>
                    <Row>
                        <Col sm={5}>
                            <Form.Group controlId={"userList"}>
                                <Form.Label>Lista Utenti</Form.Label>
                                <InputGroup>
                                    <select
                                        className={"browser-default custom-select"}
                                        onChange={(data: ChangeEvent<HTMLSelectElement>) => selectedHandle(data.target.selectedOptions[0].innerText, data.target.value, "USER")}
                                    >
                                        <option></option>
                                        {
                                            users.map((user: userStruct, index: number) => {
                                                return <option value={user.email}
                                                               key={index}>{user.fullName + " - " + user.email}</option>
                                            })
                                        }
                                    </select>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </CardContent>
            </Card>
            <div className={"my-4"}></div>
            <Card>
                <CardHeader
                    title={"Seleziona Maschera"}
                    subheader={"Seleziona Maschera di Destinazione"}
                />
                <CardContent>
                    <Row>
                        <Col sm={5}>
                            <Form.Group controlId={"formsList"}>
                                <Form.Label>Lista Maschere</Form.Label>
                                <InputGroup>
                                    <select
                                        className={"browser-default custom-select"}
                                        onChange={(data: ChangeEvent<HTMLSelectElement>) => selectedHandle(data.target.selectedOptions[0].innerText, data.target.value, "DFORM")}
                                    >
                                        <option></option>
                                        {
                                            formsList.map( (form:IForm, index:number) => {
                                                return <option value={form.id}
                                                               key={index}>{form.name}</option>
                                            })
                                        }
                                    </select>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </CardContent>
            </Card>
            {jotFields.length > 0 ?
                <div>
                    <div className={"my-4"}></div>
                    <Card>
                        <CardHeader
                            title={"Collega i Campi"}
                            subheader={"Collega i campi di JotForm con i campi di SosAvvocato"}
                        />
                        <CardContent>
                            <div>
                                <Row>
                                    <Col sm={5}>
                                        <Form.Group controlId={"tacName"}>
                                            <Form.Label>Nome Collegamento</Form.Label>
                                            <InputGroup>
                                                <input id={"tacName"} className={"form-control"} placeholder={"Nome del collegamento"} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {fieldsListSos.map((fieldSos: fieldStruct, index: number) => {
                                    return (
                                        <Row>
                                            <Col sm={12}>
                                                <EsaFields
                                                    id={index}
                                                    jfields={jotFields}
                                                    currentField={fieldsListSos[index]}
                                                />
                                            </Col>
                                        </Row>
                                    )
                                })}
                                {
                                    isSave ? (
                                        <Button type={"button"} disabled={true} className={"btn btn-primary"}>
                                                        <span className="spinner-border"
                                                              style={{
                                                                  width: "1.5rem",
                                                                  height: "1.5rem",
                                                                  marginRight: "5px"
                                                              }}
                                                              role="status"
                                                              aria-hidden="true">
                                                        </span>
                                            Salvataggio...
                                        </Button>
                                    ) : (
                                        <Button type={"button"} className={"btn btn-primary"}
                                                onClick={HandleClick}>
                                            Salva
                                        </Button>
                                    )
                                }
                            </div>
                        </CardContent>
                    </Card>
                </div>
                : (null) }
        </div>
    )
}

export default JFManager