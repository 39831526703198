import React from "react";
import {Page404Context} from "../context/Page404Context";
import {UserContext} from "../context/UserContext";
import {Controller, useForm} from "react-hook-form";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import GetTeamList, {IUser} from "../user_components/GetTeamList";
import AddForm from "./AjaxAddForm";
import 'react-confirm-alert/src/react-confirm-alert.css'
import TableListForms from "./TableListforms"; // Import css
import fmstyles from "./css/forms-manager.module.css";
import MessageInfo from "../modal/MessageInfo";
import FormDropDownManager from "./FormDropDownManager";

const FormsManager = () => {
    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false

    //User Context
    const {infos, setInfos}: any = React.useContext(UserContext)

    //Init UseForm
    const {register, control, errors, handleSubmit} = useForm()

    //Initial Array for MultiSelect
    const [selected] = React.useState([])

    type user = {
        label: string,
        value: any,
        key: string
    }

    const [usersList, setUsersList] = React.useState<user[]>([])

    const [messageInfo, setMessageInfo] = React.useState({
        show: false,
        title: "",
        message: "",
        okBtn: false,
        textOkBtn: "",
        cancelBtn: false,
        textCancelBtn: "",
        onClick: () => {
        },
        onHide: () => {
        }
    })

    //Send Values
    const onSubmit = (data: any) => {
        AddForm({
            token: infos.token,
            data: {
                name: data.form_name,
                position: data.position,
                hide_to: data.hideTo
            }
        }).then((results) => {
            setMessageInfo(prevState => {
                return {
                    show: true,
                    title: "Info",
                    message: results.message,
                    okBtn: true,
                    textOkBtn: "Chiudi",
                    cancelBtn: false,
                    textCancelBtn: "Chiudi",
                    onClick: () => {
                        setMessageInfo({
                            show: false,
                            title: "",
                            message: "",
                            okBtn: true,
                            textOkBtn: "Chiudi",
                            cancelBtn: false,
                            textCancelBtn: "Chiudi",
                            onClick: () => {
                            },
                            onHide: () => {
                            }
                        })
                        window.location.reload()
                    },
                    onHide: prevState.onHide
                }
            })
        })
    }

    // Retrieve Users List
    const InitDropDownUser = () => {
        GetTeamList({
            token: infos.token
        }).then((result) => {
            if (result.error) {
                //Error
            } else {
                //All OK
                if (result.users !== undefined) {
                    result.users.map(function (value: IUser, index: number) {
                        setUsersList((prevState: user[]) => [
                            ...prevState,
                            {
                                label: value.name + ' ' + value.surname,
                                value: value.email,
                                key: value.id
                            }
                        ])
                        return index
                    })
                }
            }
        })
    }

    React.useEffect(InitDropDownUser, [])

    return (
        <Container>
            <MessageInfo
                show={messageInfo.show}
                title={messageInfo.title}
                message={messageInfo.message}
                OkBtn={messageInfo.okBtn}
                TextOkBtn={messageInfo.textOkBtn}
                CancelBtn={messageInfo.cancelBtn}
                TextCancelBtn={messageInfo.textCancelBtn}
                onHide={messageInfo.onHide}
                onClick={messageInfo.onClick}
            />
            <Row>
                <Col sm={12} className={"py-5"}>
                    <Card>
                        <Card.Header>
                            <h6 className={"m-0 font-weight-bold text-secondary"}>AGGIUNGI UNA NUOVA MASCHERA</h6>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col sm={2}>
                                        <Form.Group controlId={"formNomeMaschera"}>
                                            <Form.Label>Nome Maschera</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"form_name"}
                                                    defaultValue={""}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <input
                                                            name="Nome Maschera"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Nome Maschera"
                                                            aria-label="Nome Maschera"
                                                            aria-describedby="basic-addon1"
                                                            onChange={value => onChange(value)}
                                                            value={value}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group controlId={"formPosition"}>
                                            <Form.Label>Posizione</Form.Label>
                                            <InputGroup>
                                                <input
                                                    name="position"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Posizione"
                                                    aria-label="Posizione"
                                                    aria-describedby="basic-addon1"
                                                    ref={
                                                        register({
                                                            min: 1,
                                                            valueAsNumber: true,
                                                            validate: {
                                                                positive: value => parseInt(value) > 0
                                                            }
                                                        })
                                                    }
                                                    required
                                                />
                                                {errors.position?.type === "min" && (
                                                    <p style={{color: "red"}}>Inserire un numero maggiore di 0</p>)}
                                                {errors.position?.type === "positive" && (
                                                    <p style={{color: "red"}}>Inserire un valore positivo</p>)}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group controlId={"formHideTo"}>
                                            <Form.Label>Non Visualizzare Per</Form.Label>
                                            <InputGroup>
                                                <Controller
                                                    name={"hideTo"}
                                                    defaultValue={selected}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <MultiSelect
                                                            className={fmstyles.multi_select}
                                                            options={usersList}
                                                            value={value}
                                                            onChange={(value: []) => onChange(value)}
                                                            labelledBy={"Select"}
                                                            overrideStrings={{
                                                                "selectSomeItems": "Seleziona...",
                                                                "allItemsAreSelected": "Tutto il Team è stato selezionato.",
                                                                "selectAll": "Seleziona Tutto",
                                                                "search": "Cerca",
                                                                "clearSearch": "Cancella Ricerca"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={2}>
                                        <button
                                            type={"submit"}
                                            className={"btn btn-primary"}
                                        >Aggiungi
                                        </button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className={"mb-5"}>
                <Col sm={12}>
                    <Card>
                        <Card.Header>
                            <h6 className={"m-0 font-weight-bold text-secondary"}>MODIFICA CAMPI LISTA</h6>
                        </Card.Header>
                        <Card.Body>
                            <FormDropDownManager/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Card>
                        <Card.Header>
                            <h6 className={"m-0 font-weight-bold text-secondary"}>LISTA MASCHERE</h6>
                        </Card.Header>
                        <Card.Body>
                            <TableListForms
                                user={usersList}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default FormsManager