import React from 'react';
import 'react-querybuilder/dist/query-builder.css';
import QueryBuilder, {Field, formatQuery, RuleGroupType} from 'react-querybuilder';
import {CardContent, CardHeader} from "@material-ui/core";
import {Card, Container, Button, Col, Form, InputGroup, Row, FormControl} from "react-bootstrap";
import {UserContext} from "../context/UserContext";
import {Page404Context} from "../context/Page404Context";
import RetrieveExtraFieldsList from "../fields_components/AjaxGetExtraFieldsListManagerID";
import {staticFieldsArray} from "../data_components/fields_array";
import SaveQueryBuilder from "./AjaxSaveQueryBuilder";
import GetExtraUserInfo from "../reusable_components/GetExtraUserInfo";
import {confirmAlert} from "react-confirm-alert";
import QueryList from "./QueryList";
import GetQueryList from "./AjaxGetQueryList";
import GetMenu from "../dashboard_components/AjaxGetMenu";
import {Controller, useForm} from "react-hook-form";
import ManageQueryMenu from "./ManageQueryMenu";
import UsersListWithCB from "../reusable_components/UsersListWithCB";
import FormsListDropDown from "../reusable_components/FormsListDropDown";
import Modal from "react-bootstrap/cjs/Modal";
import ModifyQueryBuilder from "./AjaxModifyQuery";

interface IfieldStruct {
    api:       string,
    name:      string,
    label:     string,
    inputType: string,
    extraf:    boolean
}

interface IAQuery {
    total: number,
    used: number,
    available: number
}

interface IQeryName {
    name: string,
    fieldName: string
}

interface ISQuery {
    id:         number,
    name:       string,
    menu:       string,
    btn_menu:   string,
    submenu:    string,
    user_email: string,
    query:      string,
    extra:      string,
    position:   number,
    jdata:      string,
}

interface IMenu {
    type:         string,
    name:         string,
    position:     number,
}

interface IDDownMenu {
    name: string,
}

interface ISelected {
    id:    number,
    name:  string,
    email: string,
}

interface IForm {
    id:         number,
    name:       string,
    position?:  string,
    hide_to?:   string
}

interface IUser {
    label: string,
    value: any,
    key:   string
}

const QueryB = () => {

    //User Context
    const { infos, setInfos }:any = React.useContext(UserContext)
    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false
    //UseForm
    const {control, handleSubmit} = useForm();

    const [isSave, setIsSave] = React.useState<boolean>(false);
    const [queryName, setQueryName] = React.useState<IQeryName>();
    const [menuName, setMenuName] = React.useState("");
    const [btnMenu, setBtnMenu] = React.useState("");
    const [position, setPosition] = React.useState(0);
    const [submenuName, setSubmenuName] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [dDownMenu, setDDownMenu] = React.useState<IDDownMenu[]>([]);
    const [dDownSubMenu, setDDownSubMenu] = React.useState<IDDownMenu[]>([]);
    const [usersSelected, setUsersSelected] = React.useState<ISelected[]>([]);
    const [selectedForm, setSelectedForm] = React.useState<IForm>();
    const [modifyMode, setModifyMode] = React.useState<boolean>(false);
    const [modifySave, setModifySave] = React.useState<boolean>(false);
    const [selectedQuery, setSelectedQuery] = React.useState<ISQuery | undefined>();
    const [dialogShow, setDialogShow] = React.useState<boolean>(false);
    const [usersList, setUsersList] = React.useState<IUser[]>([]);

    const [queryFields, setQueryFields] = React.useState<Field[]>([]);
    const [query, setQuery] = React.useState<RuleGroupType>({
        id: 'root',
        combinator: 'and',
        rules: []
    })

    const [availableQuery, setAvailableQuery] = React.useState<IAQuery>({
        total: 0,
        available: 0,
        used: 0
    });

    const [queryList, setQueryList] = React.useState<ISQuery[]>([])

    const onQChange = (query:RuleGroupType) => {
        setQuery(query);
    }

    const HandleClick = (button:any) => {
        setIsSave(true);
        let message: string = "";
        let formatted_query = formatQuery(query, "json").toString();
        let selform:any = Object();
        if(selectedForm !== undefined) {
            if(!isNaN(selectedForm.id)) {
                selform.form_id = selectedForm.id;
            }
        }
        // console.log(formatted_query);
        SaveQueryBuilder({
            token: infos.token,
            query: formatted_query,
            qName: queryName != undefined ? queryName.name : "",
            sUserName: infos.email,
            menu: menuName,
            btn_menu: btnMenu,
            submenu: submenuName,
            extra: JSON.stringify(usersSelected),
            position: position,
            jdata: JSON.stringify(selform),
        }).then( (result) => {
            availableQuery.available--;
            availableQuery.used++;
            setAvailableQuery(availableQuery);
            message = result.message;
            setIsSave(false);
            extractQueryList();
            confirmAlert({
                title: 'Info',
                message: message,
                buttons: [
                    {
                        label: 'Chiudi',
                        onClick: () => {}
                    }
                ]
            });
        }).catch(reason => {
            message = reason.message;
            setIsSave(false);
            confirmAlert({
                title: 'Info',
                message: message.toString(),
                buttons: [
                    {
                        label: 'Chiudi',
                        onClick: () => {}
                    }
                ]
            });
        });
    }

    const extraFieldsList = () => {
        RetrieveExtraFieldsList({
            token: infos.token
        }).then(result => {
            if (result !== undefined) {
                if (result.type === "Success") {
                    const data = result.data;
                    setQueryFields(staticFieldsArray)
                    data.map((field: IfieldStruct, index: number) => {
                        setQueryFields( prevState => [
                            ...prevState,
                            field
                        ])
                    })
                }
            }
        })
    }
    React.useEffect(extraFieldsList, []);

    const extractQueryList = () => {
        setIsLoading(true);
        GetQueryList({
            token: infos.token,
            data: "{}"
        }).then( result => {
            if(result.type == 'Success') {
                setQueryList([]);
                let queryArray:[] = JSON.parse(result.data);
                queryArray.map( (q:ISQuery, index: number) => {
                    setQueryList(prevState => [
                        ...prevState,
                        {
                            id: q.id,
                            menu: q.menu,
                            btn_menu: q.btn_menu,
                            submenu: q.submenu,
                            extra: q.extra,
                            name: q.name,
                            query: q.query,
                            user_email: q.user_email,
                            position: q.position,
                            jdata: q.jdata,
                        }
                    ])
                })
            }
            setIsLoading(false);
        }).finally( () => {setIsLoading(false);})
    }
    React.useEffect(extractQueryList, []);

    const RetrieveMenu = () => {
        GetMenu({
            token: infos.token,
            data: "{}"
        }).then( results => {
            if (results.type === "Success") {
                setDDownMenu([]);
                setDDownSubMenu([]);
                let lmenu: IMenu[] = JSON.parse(results.data);
                lmenu.map( (item:IMenu) => {
                    switch (item.type) {
                        case "MENU":
                            setDDownMenu(prevState => [
                                ...prevState,
                                {
                                    name: item.name
                                }
                            ]);
                            break;
                        case "SUBMENU":
                            setDDownSubMenu(prevState => [
                                ...prevState,
                                {
                                    name: item.name
                                }
                            ]);
                            break;
                    }
                });
            }
        }).finally();
    }
    React.useEffect(RetrieveMenu, []);

    const HandleModify = () => {
        // Salvo le modifiche
        setModifySave(true);
        let message: string = "";
        let formatted_query = formatQuery(query, "json").toString();
        let selform:any = Object();
        if(selectedForm !== undefined) {
            if(!isNaN(selectedForm.id)) {
                selform.form_id = selectedForm.id;
            }
        }

        ModifyQueryBuilder({
            token: infos.token,
            queryID: selectedQuery?.id !== undefined ? selectedQuery.id : 0,
            query: formatted_query,
            qName: queryName != undefined ? queryName.name : "",
            sUserName: infos.email,
            menu: menuName,
            btn_menu: btnMenu,
            submenu: submenuName,
            extra: JSON.stringify(usersSelected),
            position: position,
            jdata: JSON.stringify(selform),
        }).then( (result) => {
            message = result.message;
            setModifySave(false);
            extractQueryList();
            confirmAlert({
                title: 'Info',
                message: message,
                buttons: [
                    {
                        label: 'Chiudi',
                        onClick: () => {}
                    }
                ]
            });
        }).catch(reason => {
            message = reason.message;
            setModifySave(false);
            confirmAlert({
                title: 'Info',
                message: message.toString(),
                buttons: [
                    {
                        label: 'Chiudi',
                        onClick: () => {}
                    }
                ]
            });
        });

        // Sbianco i valori
        let queryB:RuleGroupType = JSON.parse( "{}" );
        setQuery(queryB);

        let formsList:any = document.querySelector("select[name=formsList]");
        formsList.options[0].selected = true;
        setSelectedForm({
            id: formsList.options[0].value,
            name: formsList.options[0].text
        })

        let nomeMenu:any = document.querySelector("select[name=nomeMenu]");
        nomeMenu.options[0].selected = true;
        setMenuName(nomeMenu.options[0].text);

        let nomeSottoMenu:any = document.querySelector("select[name=nomeSottoMenu]");
        nomeSottoMenu.options[0].selected = true;
        setSubmenuName(nomeSottoMenu.options[0].text);

        let ibtnmenu:any = document.querySelector("input[name=button_menu]");
        if(ibtnmenu !== null) {
            ibtnmenu.value = "";
        }
        setBtnMenu((selectedQuery?.btn_menu !== undefined ? selectedQuery.btn_menu : ""));

        let iposition:any = document.querySelector("input[name=position]");
        if(iposition !== null) {
            iposition.value = 0;
        }
        setPosition(0);

        let iqname:any = document.querySelector("input[name=query_name]");
        if(iqname !== null) {
            iqname.value = "";
        }
        setQueryName({
            name: "",
            fieldName: "",
        });

        while(control.getValues().hideTo.length > 0) {
            control.getValues().hideTo.pop();
        }

        setModifyMode(false);
    }

    const handleClose = () => {
        setDialogShow(false);
    }

    const handleYes = () => {
        let query:RuleGroupType = JSON.parse( (selectedQuery?.query !== undefined ? selectedQuery.query : "") );
        setQuery(query);

        let jdataObj:any;
        let formsList:any = document.querySelector("select[name=formsList]");
        let optsCollection:HTMLOptionsCollection = formsList.options;
        for(let i:number=0;i<optsCollection.length;i++) {
            if((selectedQuery !== undefined) && (selectedQuery?.jdata !== undefined && selectedQuery?.jdata !== null)) {
                jdataObj = JSON.parse(selectedQuery.jdata.toString());
                if(jdataObj.form_id !== undefined) {
                    if (optsCollection.item(i)?.value === jdataObj.form_id.toString()) {
                        formsList.options[i].selected = true;
                        setSelectedForm({
                            id: formsList.options[i].value,
                            name: formsList.options[i].text
                        })
                    }
                }
            }
        }

        let nomeMenu:any = document.querySelector("select[name=nomeMenu]");
        optsCollection = nomeMenu.options;
        for(let i:number=0;i<optsCollection.length;i++) {
            if(optsCollection.item(i)?.text === selectedQuery?.menu) {
                nomeMenu.options[i].selected = true;
                setMenuName(nomeMenu.options[i].text);
            }
        }

        let nomeSottoMenu:any = document.querySelector("select[name=nomeSottoMenu]");
        optsCollection = nomeSottoMenu.options;
        for(let i:number=0;i<optsCollection.length;i++) {
            if(optsCollection.item(i)?.text === selectedQuery?.submenu) {
                nomeSottoMenu.options[i].selected = true;
                setSubmenuName(nomeSottoMenu.options[i].text);
            }
        }

        let ibtnmenu:any = document.querySelector("input[name=button_menu]");
        if(ibtnmenu !== null) {
            ibtnmenu.value = (selectedQuery?.btn_menu !== undefined ? selectedQuery.btn_menu : "");
        }
        setBtnMenu((selectedQuery?.btn_menu !== undefined ? selectedQuery.btn_menu : ""));

        let iposition:any = document.querySelector("input[name=position]");
        if(iposition !== null) {
            iposition.value = (selectedQuery?.position !== undefined ? selectedQuery.position : 0).toString();
        }
        setPosition((selectedQuery?.position !== undefined ? selectedQuery.position : 0));

        let iqname:any = document.querySelector("input[name=query_name]");
        if(iqname !== null) {
            iqname.value = (selectedQuery?.name !== undefined ? selectedQuery.name : 0);
        }
        setQueryName({
            name: selectedQuery?.name !== undefined ? selectedQuery.name : "",
            fieldName: "query_name",
        });

        // Bisogna valorizzare il dropdown degli utenti a cui nascondere la query
        let uid = JSON.parse((selectedQuery?.extra !== undefined ? selectedQuery?.extra : "{}")).id;
        while(control.getValues().hideTo.length > 0) {
            control.getValues().hideTo.pop();
        }
        if(uid.length > 0) {
            usersList.map((user: IUser) => {
                if(uid.includes(parseInt(user.key))) {
                    control.getValues().hideTo.push({
                        key: user.key,
                        label: user.label,
                        value: user.value
                    });
                }
            })
        }
        setDialogShow(false);
        setModifyMode(true);
    }

    return(
        <Container fluid={"sm"} style={{marginTop: "3%"}}>
            <Modal show={dialogShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Sei sicuro di voler modificare?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleYes}>SI</Button>
                    <Button onClick={handleClose}> NO </Button>
                </Modal.Footer>
            </Modal>
            <div className={"my-4"}>
                <Card>
                    <CardHeader
                        title={"Gestione Menù"}
                        subheader={"gestisci il menù da utilizzare per le estrazioni"}
                    />
                    <CardContent>
                        <Row>
                            <Col>
                                <ManageQueryMenu
                                    token={infos.token}
                                />
                            </Col>
                        </Row>
                    </CardContent>
                </Card>
            </div>
            <div className={"my-4"}>
                <Card>
                    <CardHeader
                        title={"Lista Estrazioni"}
                        subheader={"elenco delle estrazioni create"}
                    />
                    <CardContent>
                        <Row>
                            <Col sm={12}>
                                <GetExtraUserInfo
                                    token={infos.token}
                                    extraInfoName={"query_available_users"}
                                    textToShow={{
                                        usedText: "Estrazioni in uso",
                                        availText: "Disponibili",
                                        totalText: "In totale"
                                    }}
                                    extraInfo={availableQuery}
                                    extraInfoValues={setAvailableQuery}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col sm={12}>
                                <QueryList
                                    availableQuery={availableQuery}
                                    setAvailableQuery={setAvailableQuery}
                                    queryList={queryList}
                                    setQueryList={setQueryList}
                                    isLoading={isLoading}
                                    exportQuery={setSelectedQuery}
                                    showDialog={setDialogShow}
                                />
                            </Col>
                        </Row>
                    </CardContent>
                </Card>
            </div>
            <div className={"my-4"}>
                <Card>
                    <CardHeader
                        title={"Nome Query"}
                        subheader={"Inserisci il nome della query"}
                    />
                    <CardContent>
                        <Row>
                            <Col sm={4}>
                                <Container>
                                    <Form.Group controlId={"queryName"}>
                                        <Form.Label>Nome Query</Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                placeholder={"Nome Query"}
                                                aria-label={"Queryname"}
                                                aria-describedby={"queryname1"}
                                                name={"query_name"}
                                                onChange={ (q) => setQueryName({name: q.target.value, fieldName: q.target.name}) }
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Container>
                            </Col>
                        </Row>
                    </CardContent>
                </Card>
            </div>
            <div className={"my-4"}>
                <Card>
                    <CardHeader
                        title={"Sezione Menù"}
                        subheader={"Scegli in quale menu inserire la query"}
                    />
                    <CardContent>
                        <Row>
                            <Col sm={3}>
                                <Container>
                                    <Form.Group controlId={"categoryNAME"}>
                                        <Form.Label>Inserisci Nome Menu</Form.Label>
                                        <InputGroup>
                                            <Controller
                                                name={"type"}
                                                defaultValue={""}
                                                control={control}
                                                render={({onChange, value}) => (
                                                    <select
                                                        name={"nomeMenu"}
                                                        className="browser-default custom-select"
                                                        onChange={value => {
                                                            onChange(value);
                                                            setMenuName(value.currentTarget.value);
                                                        }}
                                                        placeholder={"Scegli..."}>
                                                        <option key={0} value={""}></option>
                                                        {dDownMenu.map((menu: IDDownMenu, index:number) => {
                                                            return(
                                                                <option key={index} value={menu.name}>{menu.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                )}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Container>
                            </Col>
                            <Col sm={4}>
                                <Container>
                                    <Form.Group controlId={"pulsanteMenu"}>
                                        <Form.Label>Pulsante Menu</Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                placeholder={"Pulsante Menu"}
                                                aria-label={"ButtonMenu"}
                                                aria-describedby={"ButtonMenu"}
                                                name={"button_menu"}
                                                onChange={ (q) => setBtnMenu(q.target.value) }
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Container>
                            </Col>
                            <Col sm={3}>
                                <Container>
                                    <Form.Group controlId={"submenuName"}>
                                        <Form.Label>Inserisci Nome SottoMenu</Form.Label>
                                        <InputGroup>
                                            <Controller
                                                name={"type"}
                                                defaultValue={""}
                                                control={control}
                                                render={({onChange, value}) => (
                                                    <select
                                                        name={"nomeSottoMenu"}
                                                        className="browser-default custom-select"
                                                        onChange={value => {
                                                            onChange(value);
                                                            setSubmenuName(value.currentTarget.value);
                                                        }}
                                                        placeholder={"Scegli..."}>
                                                        <option key={0} value={""}></option>
                                                        {dDownSubMenu.map((menu: IDDownMenu, index:number) => {
                                                            return(
                                                                <option key={index} value={menu.name}>{menu.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                )}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Container>
                            </Col>
                            <Col sm={2}>
                                <Container>
                                    <Form.Group controlId={"posizione"}>
                                        <Form.Label>Posizione</Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                placeholder={"Posizione"}
                                                aria-label={"position"}
                                                aria-describedby={"position"}
                                                name={"position"}
                                                onChange={ (q) => setPosition(parseInt(q.target.value)) }
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <FormsListDropDown
                                    token={infos.token}
                                    label={"Lista Maschere"}
                                    formSelected={setSelectedForm}
                                />
                            </Col>
                            <Col sm={8}>
                                <Form.Group controlId={"info"}>
                                    <Form.Label>Info</Form.Label>
                                    <InputGroup>
                                        <span>Se si vuole inserire un bottone per aprire la maschera invece della query basta selezionarla dal menù.</span>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                    </CardContent>
                </Card>
            </div>
            <div className={"my-4"}>
                <Card>
                    <CardHeader
                        title={"Gestione delle esclusioni"}
                        subheader={"Seleziona l'utente o gli utenti che vuoi escludere dalla visualizzazione della query."}
                    />
                    <CardContent>
                        <Row>
                            <Col sm={5}>
                                <UsersListWithCB
                                    token={infos.token}
                                    usersSelected={setUsersSelected}
                                    control={control}
                                    usersList={setUsersList}
                                />
                            </Col>
                        </Row>
                    </CardContent>
                </Card>
            </div>
            <div className={"my-4"}>
                <Card>
                    <CardHeader
                        title={"Crea Estrazione"}
                        subheader={"crea la tua estrazione personalizzata per l'estrazione dei dati"}
                    />
                    <CardContent>
                        <Row>
                            <Col xs={12}>
                                <Container>
                                    <QueryBuilder
                                        fields={queryFields}
                                        onQueryChange={ q => onQChange(q) }
                                        query={query}
                                    />
                                </Container>
                            </Col>
                        </Row>
                    </CardContent>
                </Card>
            </div>
            <div className={"my-4"}>
                <Row>
                    <Col sm={4}>
                        {
                            modifyMode == false ?
                                isSave ? (
                                <Button type={"button"} disabled={true} className={"btn btn-primary"}>
                                    <span className="spinner-border"
                                          style={{
                                                   width: "1.5rem",
                                                   height: "1.5rem",
                                                   marginRight: "5px"
                                                 }}
                                          role="status"
                                          aria-hidden="true">
                                    </span>
                                    SALVATAGGIO...
                                </Button>
                                ) : (
                                <Button type={"button"} className={"btn btn-primary"}
                                        onClick={ b => HandleClick(b) }>
                                    SALVA
                                </Button>
                                )
                            : null
                        }
                    </Col>
                    <Col sm={4}>
                        {
                            modifyMode == true ?
                                modifySave ?
                                    <Button type={"button"} disabled={true} className={"btn btn-warning"}>
                                        <span className="spinner-border"
                                              style={{
                                                  width: "1.5rem",
                                                  height: "1.5rem",
                                                  marginRight: "5px"
                                              }}
                                              role="status"
                                              aria-hidden="true">
                                        </span>
                                        MODIFICA IN CORSO...
                                    </Button>
                                    :
                                    <Button type={"button"} className={"btn btn-warning"}
                                            onClick={ () => HandleModify()}>
                                        MODIFICA
                                    </Button>
                                : null
                        }
                    </Col>
                </Row>
            </div>
        </Container>
    )

}

export default QueryB