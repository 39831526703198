import React, {ChangeEvent} from 'react';
import {Container, Form, InputGroup} from "react-bootstrap";
import {Control} from "react-hook-form";
import GetFormsList from "../forms_components/AjaxFormsList";

interface IProps {
    token: string,
    label: string,
    formSelected?: React.Dispatch<React.SetStateAction<IForm | undefined>>
    control?: Control<Record<string, any>>,
}

interface IForm {
    id:         number,
    name:       string,
    position?:  string,
    hide_to?:   string
}

const FormsListDropDown = (props:IProps) => {

    const [selectedForm, setSelectedForm] = React.useState<IForm>();
    const [forms, setForms] = React.useState<IForm[]>([]);

    const selectedHandle = (name: string, id: string) => {
        setSelectedForm({
            id: parseInt(id),
            name: name
        })
        if(props.formSelected != undefined) {
            props.formSelected({
                id: parseInt(id),
                name: name
            })
        };
        props.control?.setValue(
            "form",
            id
        );
    }

    const retrieveFormsList = () => {
        GetFormsList({
            token: props.token,
        }).then( results => {
            setForms([]);
            if (results !== undefined) {
                const formData: IForm[] = results;
                formData.map((form: IForm, index: number) => {
                    setForms(prevState => [
                        ...prevState,
                        form
                    ]);
                })
            }
        })
    }
    React.useEffect(retrieveFormsList, []);

    return(
        <Container>
            <Form.Group controlId={"formsList"}>
                <Form.Label>{props.label}</Form.Label>
                <InputGroup>
                    <select
                        name={"formsList"}
                        className={"browser-default custom-select"}
                        onChange={(data: ChangeEvent<HTMLSelectElement>) => selectedHandle(data.target.selectedOptions[0].innerText, data.target.value)}
                    >
                        <option></option>
                        {
                            forms.map((form: IForm, index: number) => {
                                return <option value={form.id}
                                               key={index}>{form.name}</option>
                            })
                        }
                    </select>
                </InputGroup>
            </Form.Group>
        </Container>
    )

}

export default FormsListDropDown