import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    token: string
}

interface IField {
    id:             number,
    id_api:         string,
    name:           string,
    type:           string,
    forms_list:     string,
    label:          string,
    pholder:        string,
    extra_options:  string
}

async function GetFieldsList(props: IProps) {

    let results:IField[] = [{
        id:             0,
        id_api:         "",
        name:           "",
        type:           "",
        label:          "",
        pholder:        "",
        forms_list:     "",
        extra_options:  ""
    }]

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "api/extra_fields_list",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token: props.token
                })
            }).then((response) => {
                if(response.statusText === 'OK' || response.statusText === '') {
                    results = response.data
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default GetFieldsList;