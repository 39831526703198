import React from "react";
import {UserContext} from "../context/UserContext";
import {Button, Card, Col, Container, Form, InputGroup, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faKey, faUser} from "@fortawesome/free-solid-svg-icons";
import RegistrationTeamUser from "../user_components/RegistrationTeamUser";
import "../css/team.css";
import {Page404Context} from "../context/Page404Context";

export interface IParams {
    email:      string,
    username:   string,
    password:   string,
    name:       string,
    surname:    string,
    token:      string
}

export interface IDialog {
    show: boolean,
    message: string
}

const AddTeamUser = () => {

    //Variabile di check registrazione
    const [regUser, setRegUser] = React.useState(false)

    //User Context
    const { infos, setInfos }:any = React.useContext(UserContext)
    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false

    //Gestione Errori
    const [dialog, setDialog] = React.useState<IDialog>({
        show: false,
        message: ""
    })

    //Input
    const [parameters, setParameters] = React.useState<IParams>({
        email:      "",
        username:   "",
        password:   "",
        name:       "",
        surname:    "",
        token:      infos.token
    })

    const hideDialogHandler =  () => {
        setDialog({
            show: false,
            message: dialog.message
        })
    }

    const submitHanlder = (event: React.FormEvent) => {
        event.preventDefault()
    }

    //Fase di Registrazione utente
    const registrationHandler = () => {
        if(parameters.email !== "" && parameters.username !== "" && parameters.password !== "") {
            RegistrationTeamUser(parameters).then( (response) => {
                let resp = JSON.parse(JSON.stringify(response))
                if(resp.id === 0) setRegUser(true); else setRegUser(false)
                setDialog({
                    show: true,
                    message: resp.message
                })
            })
        } else {
            setDialog({
                show: true,
                message: "Inserire i dati mancanti"
            })
        }
    }

    return(
        <Container>
            {(dialog.show) ? (
                <Modal show={dialog.show} onHide={ () => hideDialogHandler() } centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Attenzione!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {dialog.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={ () => hideDialogHandler() }>
                            CHIUDI
                        </Button>
                    </Modal.Footer>
                </Modal>
            ): null}
            {(infos.isLogged) ? (
                <Row>
                    <Col sm={2}></Col>
                    <Col sm={8} className={"py-5"}>
                        <Card className={"shadow"}>
                            <Card.Header className={"py-3"}>
                                <h6 className={"m-0 font-weight-bold text-secondary"}>
                                    AGGIUNGI COLLABORATORE
                                </h6>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={submitHanlder}>
                                    <Form.Group controlId={"email"}>
                                        <Form.Label>
                                            Email*
                                        </Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faEnvelope}/>
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type={"text"}
                                                placeholder={"Inserisci la mail"}
                                                onChange={(event) => {
                                                    setParameters({
                                                        email:      event.target.value,
                                                        username:   parameters.username,
                                                        password:   parameters.password,
                                                        name:       parameters.name,
                                                        surname:    parameters.surname,
                                                        token:      parameters.token
                                                    })
                                                }}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group controlId={"username"}>
                                        <Form.Label>
                                            Username*
                                        </Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faUser}/>
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type={"text"}
                                                placeholder={"Inserisci il nome utente"}
                                                onChange={(event) => {
                                                    setParameters({
                                                        email:      parameters.email,
                                                        username:   event.target.value,
                                                        password:   parameters.password,
                                                        name:       parameters.name,
                                                        surname:    parameters.surname,
                                                        token:      parameters.token
                                                    })
                                                }}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group controlId={"password"}>
                                        <Form.Label>
                                            Password*
                                        </Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faKey}/>
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type={"password"}
                                                placeholder={"Inserisci password"}
                                                onChange={(event) => {
                                                    setParameters({
                                                        email:      parameters.email,
                                                        username:   parameters.username,
                                                        password:   event.target.value,
                                                        name:       parameters.name,
                                                        surname:    parameters.surname,
                                                        token:      parameters.token
                                                    })
                                                }}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group controlId={"nome"}>
                                                <Form.Label>
                                                    Nome
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faUser}/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"text"}
                                                        placeholder={"Inserisci nome"}
                                                        onChange={(event) => {
                                                            setParameters({
                                                                email:      parameters.email,
                                                                username:   parameters.username,
                                                                password:   parameters.password,
                                                                name:       event.target.value,
                                                                surname:    parameters.surname,
                                                                token:      parameters.token

                                                            })
                                                        }}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group controlId={"cognome"}>
                                                <Form.Label>
                                                    Cognome
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faUser}/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"text"}
                                                        placeholder={"Inserisci cognome"}
                                                        onChange={(event) => {
                                                            setParameters({
                                                                email:      parameters.email,
                                                                username:   parameters.username,
                                                                password:   parameters.password,
                                                                name:       parameters.name,
                                                                surname:    event.target.value,
                                                                token:      parameters.token
                                                            })
                                                        }}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                            <Card.Footer className={"footer-card"}>
                                <Button
                                    variant={"lg btn-secondary"}
                                    onClick={ () => { window.location.href = "/team" }  }
                                >CHIUDI</Button>
                                <Button
                                    variant="lg btn-primary"
                                    onClick={(registrationEvent) => registrationHandler()}
                                >AGGIUNGI COLLABORATORE</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
            ) : page404.forbidden = true }
        </Container>
    )
}

export default AddTeamUser;