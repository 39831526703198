import React from 'react';
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import styled from "styled-components";
import AddApi from "./AjaxAddApi";

interface IProps {
    token: string,
    setApisList: React.Dispatch<React.SetStateAction<IAddedApi[]>>
}

interface IAddedApi {
    name: string,
    api: string
}

const AddApiComponent = (props: IProps) => {

    //CSS
    const BtnCSS = styled.div`
        margin-top: 2rem;
    `;

    const [isLoading, setIsLoading] = React.useState(false);
    const {control, handleSubmit} = useForm();

    const onSubmit = (data: any) => {
        setIsLoading(true);
        AddApi({
            token: props.token,
            data: data
        }).then(result => {
            setIsLoading(false);
            if (result != undefined) {
                if (result.type == "Success") {
                    props.setApisList(prevState => [
                        ...prevState,
                        {
                            name: data.api_name,
                            api: data.api
                        }
                    ])
                    console.log(result);
                }
            }
        })
    }

    return (
        <Card className={"my-5"}>
            <CardHeader
                title={"Aggiungi API"}
            />
            <CardContent>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col sm={4}>
                            <Form.Group controlId={"name"}>
                                <Form.Label>Nome Api</Form.Label>
                                <InputGroup>
                                    <Controller
                                        name="api_name"
                                        control={control}
                                        defaultValue=""
                                        as={
                                            <input
                                                name={"api_name"}
                                                className="form-control"
                                                placeholder={"Insrisci il nome dell'api"}
                                            ></input>
                                        }
                                    ></Controller>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group controlId={"api"}>
                                <Form.Label>Api</Form.Label>
                                <InputGroup>
                                    <Controller
                                        name="api"
                                        control={control}
                                        defaultValue=""
                                        as={
                                            <input
                                                name={"api"}
                                                className="form-control"
                                                placeholder={"Insrisci api"}
                                            ></input>
                                        }
                                    ></Controller>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group>
                                <InputGroup>
                                    <BtnCSS>
                                        {
                                            isLoading ? (
                                                <Button type={"submit"} disabled={true}>
                                                        <span className="spinner-border"
                                                              style={{
                                                                  width: "1.5rem",
                                                                  height: "1.5rem",
                                                                  marginRight: "5px"
                                                              }}
                                                              role="status"
                                                              aria-hidden="true">
                                                        </span>
                                                    Salvataggio...
                                                </Button>
                                            ) : (
                                                <Button type={"submit"}>
                                                    Salva
                                                </Button>
                                            )
                                        }
                                    </BtnCSS>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </CardContent>
        </Card>
    )
}

export default AddApiComponent