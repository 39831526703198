import React from 'react';
import {Container} from "@material-ui/core";
import DropdownApiList from "./DropdownApiList";
import JFManager from "./JFManager";

interface IProps {
    token: string,
    apisList: IApiSelected[],
    esapi_id: string
}

interface IApiSelected {
    name: string,
    api: string
}

interface IJF {
    name: string,
    id: number
}

const ManageTOC = (props:IProps) => {

    const [apiSelected, setApiSelected] = React.useState<IApiSelected>();
    const [jfForms, setJfForms] = React.useState<IJF[]>([]);

    return(
        <Container>
            <DropdownApiList
                token={props.token}
                setApi={setApiSelected}
                setFormsList={setJfForms}
                apisList={props.apisList}
            />
            <JFManager
                token={props.token}
                api={apiSelected}
                formsList={jfForms}
                esapi_id={props.esapi_id}
            />
        </Container>
    )
}

export default ManageTOC