import React from 'react';
import MUIDataTable, {MUIDataTableColumnDef} from "mui-datatables";
import {Grid, Tooltip} from "@material-ui/core";
import {DeleteForever, Edit} from "@material-ui/icons";
import DeleteMenuListById from "./AjaxDeleteMenuListById";

interface IProps {
    token: string,
    menuList: IMenuList[],
    setMenuList: React.Dispatch<React.SetStateAction<IMenuList[]>>
    isLoading: boolean
}

interface IMenuList {
    id:       number,
    type:     string,
    name:     string,
    position: number
}

const ListQueryMenu = (props:IProps) => {

    const DeleteMenu = (id:number) => {
        let data:IMenuList = {
           id:       id,
           type:     "",
           name:     "",
           position: 0
        };
        DeleteMenuListById({
            token: props.token,
            data:  JSON.stringify(data)
        }).then( result => {
           if(result.type === "Success") {
               let menu_filtered:IMenuList[] = props.menuList.filter( element => element.id !== id)
               props.setMenuList(menu_filtered);
           }
        });
    }

    const ModifyMenu = (id:number) => {
        alert("Presto disponibile");
    }

    const columns:MUIDataTableColumnDef[] = [
        {
            name: "id",
            label: "ID",
        },
        {
            name: "type",
            label: "Tipo Menu",
        },
        {
            name: "name",
            label: "Nome Menu",
        },
        {
            name: "position",
            label: "Posizione"
        },
        {
            name: "operations",
            label: "Operazioni",
            options: {
                sort: false,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <Grid
                            container
                            item
                            xs={5}
                            alignItems={"center"}
                            justify={"space-between"}
                            direction={"row"}
                        >
                            <Tooltip onClick={() => ModifyMenu(tableMeta.rowData[0])} title={"Modifica"}>
                                <Edit />
                            </Tooltip>
                            <div onClick={() => DeleteMenu(tableMeta.rowData[0])}>
                                <Tooltip title={"Elimina"}>
                                    <DeleteForever color={"error"}/>
                                </Tooltip>
                            </div>
                        </Grid>
                    )
                },
            },
        }
    ]

    return(
        <MUIDataTable
            title={"LISTA MENU"}
            data={props.menuList}
            columns={columns}
            options={{
                selectableRows: "none",
                filter: true,
                filterType: 'dropdown',
                responsive: 'vertical',
                fixedHeader: true,
                fixedSelectColumn: true,
                tableBodyHeight: '100%',
                draggableColumns: {
                    enabled: true
                },
                expandableRowsOnClick: true,
                textLabels: {
                    body: {
                        noMatch: (props.isLoading ? "Caricamento in corso...": "Mi dispiace, nessuna corrispondenza trovata"),
                        toolTip: "Filtra",
                        //columnHeaderTooltip: column => `Sort for ${column.label}`
                    },
                    pagination: {
                        next: "Pagina Seguente",
                        previous: "Pagina Precedente",
                        rowsPerPage: "Righe per pagina:",
                        displayRows: "di",
                    },
                    toolbar: {
                        search: "Cerca",
                        downloadCsv: "Download CSV",
                        print: "Stampa",
                        viewColumns: "Schermata Colonne",
                        filterTable: "Filtra Tabella",
                    },
                    filter: {
                        all: "Tutto",
                        title: "FILTRI",
                        reset: "RESET",
                    },
                    viewColumns: {
                        title: "Mostra Colonne",
                        titleAria: "Mostra/Nascondi Colonne Tabella",
                    },
                    selectedRows: {
                        text: "riga(righe) selezionata/e",
                        // delete: "Elimina",
                        deleteAria: "Elimina righe selezionate",
                    },
                }
            }}
        ></MUIDataTable>
    )
}

export default ListQueryMenu