import React, {ChangeEvent} from 'react';
import {Row, Col, Form, InputGroup} from 'react-bootstrap';
import {Chip, Card, CardContent, CardHeader} from '@material-ui/core';
import Classes from "./css/esa.module.css";

interface IProps {
    id: number,
    currentField: IFieldStruct,
    jfields: IJFields[],
}

interface IChip {
    id: number,
    label: string
}

interface IJFields {
    name: string,
    text: string
}

interface IFieldStruct {
    api: string,
    name: string,
    label: string,
    extraf: boolean
}

const EsaFields = (props: IProps) => {

    const HandleDelete = (element: HTMLElement) => {
        setChipList(chipList.filter((chip: IChip) => chip.id.toString() !== element.parentElement?.id));
    }

    const HandleSelected = (name: string, id: string) => {
        if (name !== "" && id !== "") {
            setChipList(prevState => [
                ...prevState,
                {
                    id: (prevState.length !== 0 ? prevState[prevState.length - 1].id + 1 : 1),
                    label: '<!' + id + '!>'
                }
            ])
        }
    }

    const [chipList, setChipList] = React.useState<IChip[]>([]);

    return (
        <div className={"my-4"}>
            <Card>
                <CardHeader
                    title={"Assegna il valore al campo " + props.currentField?.label}
                />
                <CardContent>
                    <Row>
                        <Col sm={8}>
                            <div contentEditable={"true"} className={Classes.editText}
                                 id={ props.currentField.extraf ? props.currentField.api : props.currentField.name }>
                                {chipList.map((chip: IChip, index: number) => {
                                    return (
                                        <Chip label={chip.label} key={index} id={chip.id.toString()}
                                              contentEditable={false}
                                              onDelete={(target) => HandleDelete(target.currentTarget)}></Chip>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={5}>
                            <Form.Group controlId={"lcmjf"}>
                                <Form.Label>Lista Campi Maschera JotForm</Form.Label>
                                <InputGroup>
                                    <select
                                        className={"browser-default custom-select"}
                                        onChange={(data: ChangeEvent<HTMLSelectElement>) => HandleSelected(data.target.selectedOptions[0].innerText, data.target.value)}
                                    >
                                        <option value=""></option>
                                        {
                                            props.jfields.map((field: IJFields, index: number) => {
                                                return <option value={field.name}
                                                               key={index}>{field.text} - {field.name}</option>
                                            })
                                        }
                                    </select>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </CardContent>
            </Card>
        </div>
    )
}

export default EsaFields