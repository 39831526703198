import React from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import {IInfos} from "../Home";
import {Page404Context} from "./context/Page404Context";
import {UserContext} from "./context/UserContext";

const Options = () => {

    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false

    //User Context
    const { infos, setInfos }:any = React.useContext(UserContext)

    return(
        <div>
            { infos.isLogged ? (
                <Container>
                    <h1>Opzioni</h1>
                    <Row>
                        Pagina in costruzione
                    </Row>
                </Container>
            ): page404.forbidden = true }
        </div>
    )
}

export default Options