import Axios from 'axios';
import {sosglobal} from "../../sosGlobal";

export interface IProps {
    token: string,
    var_name: string
}

export interface IRes {
    id:         number,
    type:       string,
    data:       string,
    message:    string

}

async function GetExtraInfos(props:IProps) {

    let resp:IRes = {
        id:      0,
        type:    "",
        data:    "",
        message: ""
    };

    try {
        await
        Axios.request({
            method: 'POST',
            url: sosglobal.apiUrl + 'api/get_user_extras',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=UTF-8",
            },
            data: JSON.stringify({
                token:    props.token,
                var_name: props.var_name
            })
        }).then((response) => {
            if(response.data !== "") {
                resp = response.data
            }
        })
    } catch (err) {
        console.log(err);
    }
    return resp
}

export default GetExtraInfos;