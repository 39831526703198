import React, {ChangeEvent, useEffect} from 'react';
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import RetrieveApisList from "./AjaxRetrieveApisList";
import DeleteApi from "./AjaxDeleteApi";
import axios from "axios";
import styled from "styled-components";

interface IProps {
    token: string,
    setApi: React.Dispatch<React.SetStateAction<IApiSelected | undefined>>,
    setFormsList: React.Dispatch<React.SetStateAction<IJF[] | []>>,
    apisList: IApiSelected[]
}

interface IApiSelected {
    name: string,
    api: string
}

interface IJF {
    name: string,
    id: number
}


const DropdownApiList = (props: IProps) => {

    const [apisListI, setApisListI] = React.useState<IApiSelected[]>([]);
    const [apiSelected, setApiSelected] = React.useState<IApiSelected>();
    const [isDel, setIsDel] = React.useState(false);
    const [isRet, setIsRet] = React.useState(false);

    const selectedHandle = (name: string, api: string) => {
        props.setApi({
            name: name,
            api: api
        });
        setApiSelected({
            name: name,
            api: api
        });
    }

    const apiSelectedHandle = () => {
        setIsRet(true);
        axios.request({
            method: 'GET',
            url: 'https://eu-api.jotform.com/user/forms?limit=1000&apiKey=' + apiSelected?.api
        }).then((result: any) => {
            // console.log(result);
            if (result.status == 200) {
                props.setFormsList([]);
                result.data.content?.map((forms: any, index: number) => {
                    props.setFormsList(prevState => [
                        ...prevState,
                        {
                            name: forms.title,
                            id: forms.id
                        }
                    ])
                });
                setIsRet(false);
            }
        })

    }

    const apiDeleteHandle = () => {
        if (apiSelected != undefined) {
            if (apiSelected.name != '' && apiSelected.api != '') {
                setIsDel(true);
                DeleteApi({
                    token: props.token,
                    data: {
                        name: apiSelected.name,
                        api: apiSelected.api
                    }
                }).then(result => {
                    if (result != undefined) {
                        if (result.type === "Success") {
                            setApisListI(apisListI.filter(apil => String(apil.api) !== String(apiSelected.api)));
                        }
                        setIsDel(false);
                        console.log(result);
                    }
                })
            }
        }
    }

    const retrieveApisList = () => {
        RetrieveApisList({
            token: props.token
        }).then(result => {
            if (result != undefined) {
                if (result.type == 'Success') {
                    let list: IApiSelected[] = JSON.parse(JSON.stringify(result.data));
                    setApisListI(list);
                }
            }
        })
    }

    useEffect(retrieveApisList, []);

    //CSS
    const BtnCSS = styled.div`
        margin-top: 2rem;
    `;

    return (
        <Row>
            <Col sm={4}>
                <Form.Group controlId={"name"}>
                    <Form.Label>Lista Api</Form.Label>
                    <InputGroup>
                        <select
                            className={"browser-default custom-select"}
                            onChange={(data: ChangeEvent<HTMLSelectElement>) => selectedHandle(data.target.selectedOptions[0].innerText, data.target.value)}
                        >
                            <option value=""></option>
                            {
                                apisListI.length > 0 && apisListI != undefined ? (
                                    apisListI.map((api: IApiSelected, index: number) => {
                                        return (
                                            <option value={api.api} key={index}>{api.name}</option>
                                        )
                                    })
                                ) : null
                            }
                            {
                                props.apisList.length > 0 ? (
                                    props.apisList.map((api: IApiSelected, index: number) => {
                                        return (
                                            <option value={api.api} key={index}>{api.name}</option>
                                        )
                                    })
                                ) : null
                            }
                        </select>
                    </InputGroup>
                </Form.Group>
            </Col>
            <Col sm={2}>
                <BtnCSS>
                    {
                        isRet ? (
                            <Button type={"button"} disabled={true} className={"btn btn-default"}>
                                                        <span className="spinner-border"
                                                              style={{
                                                                  width: "1.5rem",
                                                                  height: "1.5rem",
                                                                  marginRight: "5px"
                                                              }}
                                                              role="status"
                                                              aria-hidden="true">
                                                        </span>
                                Recupero...
                            </Button>
                        ) : (
                            <Button type={"button"} className={"btn btn-default"} onClick={apiSelectedHandle}>
                                Recupera Lista
                            </Button>
                        )

                    }
                </BtnCSS>
            </Col>
            <Col sm={2}>
                <BtnCSS>
                    {
                        isDel ? (
                            <Button type={"button"} disabled={true} className={"btn btn-danger"}>
                                                        <span className="spinner-border"
                                                              style={{
                                                                  width: "1.5rem",
                                                                  height: "1.5rem",
                                                                  marginRight: "5px"
                                                              }}
                                                              role="status"
                                                              aria-hidden="true">
                                                        </span>
                                Eliminazione...
                            </Button>
                        ) : (
                            <Button type={"button"} className={"btn btn-danger"} onClick={apiDeleteHandle}>
                                Elimina
                            </Button>
                        )
                    }
                </BtnCSS>
            </Col>
        </Row>
    )
}

export default DropdownApiList