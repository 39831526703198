import React from "react";
import {RouteComponentProps} from "react-router-dom";
import PrintFieldsList from "./PrintFieldsList";
import {Controller, useForm} from "react-hook-form";
import {Form, Button} from "react-bootstrap";
import moment from "moment";
import "./css/FormsViews.css";
import GetExtraFieldsList from "../fields_components/AjaxExtraFieldsList";
import AjaxSendFormsValues from "./AjaxSendFormsValues";
import RetrieveRecord from "./AjaxRetrieveRecord";
import {Page404Context} from "../context/Page404Context";
import {UserContext} from "../context/UserContext";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useHistory} from "react-router";
import AjaxUpdateFormValues from "./AjaxUpdateFormValues";
import GetDropDownBaseForm from "./AjaxGetDropDownBaseForm";
import DeleteRecordById from "./AjaxDeleteRecord";
import CheckAuthForm from "./AjaxCheckAuthForm";
import GetTeamsList from "./AjaxGetTeamsList";
import LoadingPage from "../loadingpage_components/LoadingPage";
import GetFormsList from "./AjaxFormsList";

interface IRouter {
    form_id: string,
    record_id: string
}

interface IField {
    id: number,
    id_api: string,
    name: string,
    type: string,
    label: string,
    pholder: string,
    required: boolean,
    extra_options: string,
    form_name: string,
    ext_field: boolean
}

interface IUser {
    email:  string,
    name:   string
}

interface IPracties {
    id:       string,
    protocol: string
}

interface IForm {
    id:        number,
    name:      string,
    position:  string,
    hide_to:   string
}

const FormsView = ({match}: RouteComponentProps<IRouter>) => {

    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false

    //User Context
    const {infos, setInfos}: any = React.useContext(UserContext)

    const listaRegioni =
        "VALLE D'AOSTA;PIEMONTE;LIGURIA;LOMBARDIA;TRENTINO ALTO ADIGE;VENETO;FRIULI VENEZIA GIULIA;EMILIA ROMAGNA;TOSCANA;UMBRIA;MARCHE;LAZIO;ABRUZZO;MOLISE;CAMPANIA;PUGLIA;BASILICATA;CALABRIA;SICILIA;SARDEGNA"

    const arrFields = [
        {
            id: 1,
            id_api: "",
            name: "affida_pratica",
            type: "affide_to",
            label: "Affida Pratica",
            pholder: "Nome Collaboratore",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 2,
            id_api: "",
            name: "data_affidamento",
            type: "date",
            label: "Data Affidamento",
            pholder: "Data Affidamento",
            required: true,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 3,
            id_api: "",
            name: "avvocato",
            type: "dropdown",
            label: "Avvocato",
            pholder: "Avvocato",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 4,
            id_api: "",
            name: "domiciliatario",
            type: "dropdown",
            label: "Domiciliatario",
            pholder: "Domiciliatario",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 5,
            id_api: "",
            name: "referente",
            type: "text",
            label: "Referente",
            pholder: "Referente",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 6,
            id_api: "",
            name: "data_acquisizione",
            type: "date",
            label: "Data Acquisizione",
            pholder: "Data Acquisizione",
            required: true,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 7,
            id_api: "",
            name: "altre_pratiche",
            type: "others_practice",
            label: "Altre Pratiche",
            pholder: "Altre Pratiche",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 8,
            id_api: "",
            name: "id_form",
            type: "formsList",
            label: "Maschera",
            pholder: "Maschera",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 9,
            id_api: "",
            name: "tematica",
            type: "dropdown",
            label: "Tematica",
            pholder: "Tematica",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 10,
            id_api: "",
            name: "problematica",
            type: "dropdown",
            label: "Problematica",
            pholder: "Problematica",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 11,
            id_api: "",
            name: "provenienza",
            type: "dropdown",
            label: "Provenienza",
            pholder: "Provenienza",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 12,
            id_api: "",
            name: "stato_pratica",
            type: "dropdown",
            label: "Stato Pratica",
            pholder: "Stato Pratica",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 13,
            id_api: "",
            name: "nome",
            type: "text",
            label: "Nome",
            pholder: "Nome",
            required: true,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 14,
            id_api: "",
            name: "cognome",
            type: "text",
            label: "Cognome",
            pholder: "Cognome",
            required: true,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 15,
            id_api: "",
            name: "controparte",
            type: "text",
            label: "Controparte",
            pholder: "Controparte",
            required: true,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 16,
            id_api: "",
            name: "codice_fiscale",
            type: "text",
            label: "Codice Fiscale",
            pholder: "Codice Fiscale",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 17,
            id_api: "",
            name: "data_nascita",
            type: "date",
            label: "Data di Nascita",
            pholder: "Data di Nascita",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 18,
            id_api: "",
            name: "luogo_nascita",
            type: "text",
            label: "Luogo di Nascita",
            pholder: "Luogo di Nascita",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 19,
            id_api: "",
            name: "sesso",
            type: "dropdown",
            label: "Sesso",
            pholder: "Sesso",
            required: false,
            extra_options: "M;F",
            form_name: "",
            ext_field: false
        },
        {
            id: 20,
            id_api: "",
            name: "indirizzo",
            type: "text",
            label: "Indirizzo",
            pholder: "Indirizzo",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 21,
            id_api: "",
            name: "citta",
            type: "text",
            label: "Città",
            pholder: "Città",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 22,
            id_api: "",
            name: "provincia",
            type: "text",
            label: "Provincia",
            pholder: "Provincia",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 23,
            id_api: "",
            name: "cap",
            type: "text",
            label: "Cap",
            pholder: "Cap",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 24,
            id_api: "",
            name: "regione",
            type: "dropdown",
            label: "Regione",
            pholder: "Regione",
            required: false,
            extra_options: listaRegioni,
            form_name: "",
            ext_field: false
        },
        {
            id: 25,
            id_api: "",
            name: "scadenza_ci",
            type: "date",
            label: "Scadenza CI",
            pholder: "Scadenza CI",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 26,
            id_api: "",
            name: "procura",
            type: "dropdown",
            label: "Procura",
            pholder: "Procura",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 27,
            id_api: "",
            name: "priorita",
            type: "dropdown",
            label: "Priorità",
            pholder: "Priorità",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 28,
            id_api: "",
            name: "punto_sos",
            type: "text",
            label: "Punto SOS",
            pholder: "Punto SOS",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 29,
            id_api: "",
            name: "fan",
            type: "dropdown",
            label: "Fan",
            pholder: "Fan",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 30,
            id_api: "",
            name: "telefono",
            type: "text",
            label: "Telefono",
            pholder: "Telefono",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 31,
            id_api: "",
            name: "recapito_alternativo",
            type: "text",
            label: "Recapito Alternativo",
            pholder: "Recapito Alternativo",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 32,
            id_api: "",
            name: "email",
            type: "text",
            label: "Email",
            pholder: "Email",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 33,
            id_api: "",
            name: "iban",
            type: "text",
            label: "Iban",
            pholder: "Iban",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 34,
            id_api: "",
            name: "appunti",
            type: "textarea",
            label: "Appunti",
            pholder: "Appunti",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 35,
            id_api: "",
            name: "note",
            type: "textarea",
            label: "Note",
            pholder: "Note",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 36,
            id_api: "",
            name: "allegati",
            type: "text",
            label: "Allegati",
            pholder: "Allegati",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 37,
            id_api: "",
            name: "file_caricati",
            type: "div",
            label: "File Caricati",
            pholder: "File Caricati",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 38,
            id_api: "",
            name: "messaggio",
            type: "textarea",
            label: "Messaggio",
            pholder: "Messaggio",
            required: false,
            extra_options: "",
            form_name: "",
            ext_field: false
        },
        {
            id: 39,
            id_api: "",
            name: "descrizione_fatti",
            type: "textarea",
            label: "Descrizione Dei Fatti",
            required: false,
            pholder: "Descrizione Dei Fatti",
            extra_options: "",
            form_name: "",
            ext_field: false
        }
    ]

    const [field, setField] = React.useState<IField>({
        id: 0,
        id_api: "",
        name: "",
        type: "",
        label: "",
        pholder: "",
        required: false,
        extra_options: "",
        form_name: "",
        ext_field: false
    })

    const [fieldsList, setFieldsList] = React.useState([field])

    const {control, handleSubmit} = useForm()

    const [recordExist, setRecordExist] = React.useState(false)

    const [authorized, setAuthorized] = React.useState(true)

    const [usersList, setUsersList] = React.useState<IUser[]>([])

    const [protocol, setProtocol] = React.useState<string>("")

    const [loading, setLoading] = React.useState(false);

    const [practies, setPracties] = React.useState<IPracties[]>([]);

    const [formsList, setFormsList] = React.useState<IForm[]>([]);

    const Initialize = () => {
        GetTeamsList({
            token: infos.token
        }).then( result => {
            if(result !== undefined) {
                if(result.id === 0) {
                    let usersArray = JSON.parse(result.data);
                    usersArray.map((user: IUser, index: number) => {
                        setUsersList(prevUsersList => [
                            ...prevUsersList,
                            {
                                email: user.email,
                                name: user.name
                            }
                        ]);
                        return user;
                    });
                }
            }
        })
        CheckAuthForm({
            token: infos.token,
            form_id: parseInt(match.params.form_id)
        }).then( result => {
            if(result.id !== 0) {
                confirmAlert({
                    title: 'Info',
                    message: result.message,
                    buttons: [
                        {
                            label: 'Chiudi',
                            onClick: () => gotoLink("/")
                        }
                    ]
                })
                setAuthorized(false);
            }
        })
        GetFormsList({
            token:infos.token
        }).then( result => {
            setFormsList(result);
        });
        setFieldsList(arrFields);
        GetDropDownBaseForm({
            token: infos.token
        }).then( result => {
            if(result.type === "Success") {
                let data = result.data;
                data.map((fielddata:any) => {
                    arrFields.map( (field:IField) => {
                        if(field.name === fielddata.field_name) {
                            field.extra_options = fielddata.value;
                            setFieldsList( lastFields => ([
                                ...lastFields
                            ]))
                        }
                        return arrFields;
                    })
                    return fielddata;
                })
            }
        })
        GetExtraFieldsList({
            form_id: parseInt(match.params.form_id),
            token: infos.token,
            record_id: (parseInt(match.params.record_id)>0 ? parseInt(match.params.record_id) : 0)
        }).then((results) => {
            if(results !== undefined) {
                results.map((fields, index) => {
                    fields.name = fields.id_api
                    setFieldsList(lastFieldsList => ([
                        ...lastFieldsList,
                        fields
                    ]))
                    return index
                })
            }
        })
        if (parseInt(match.params.record_id) > 0) {
            setLoading(true);
            RetrieveRecord({
                record_id: parseInt(match.params.record_id),
                token: infos.token
            }).then((results) => {
                if (results !== "") {
                    setRecordExist(true);
                    let fieldskey = Object.keys(results)
                    let fieldsval = Object.values(results)
                    fieldskey.map((key, index) => {
                        control.setValue(key, fillData(fieldsval[index]));
                        return index;
                    })
                    if(Object(results).protocollo !== undefined) {
                        setProtocol(Object(results).protocollo);
                    }
                    if(Object(results).altre_pratiche instanceof Array) {
                        setPracties(Object(results).altre_pratiche);
                    }
                    setLoading(false);
                } else {
                    setRecordExist(false);
                    setLoading(false);
                    confirmAlert({
                        title: 'Info',
                        message: "Errore record, potrebbe essere stato cancellato.",
                        buttons: [
                            {
                                label: 'Chiudi',
                                onClick: () => null
                            }
                        ]
                    })
                }
            }).finally( () => {
                setLoading(false);
            })
        }
    }

    const fillData = (data: string): any => {
        let result: any = data
        if (isADate(result)) {
            let isoDate = moment(result, "YYYY-MM-DD")
            let dataConverted = new Date(isoDate.year(), isoDate.month(), isoDate.date())
            result = dataConverted
        }
        if (result === null) result = ""
        return result
    }

    const isADate = (value: string) => {
        let parseDate = moment(value, "YYYY-MM-DD", true).isValid()
        return parseDate
    }

    React.useEffect(Initialize, [])

    const onSubmit = (data: any) => {
        if (data !== undefined) {
            switch (data.button) {
                case 'btnSave':
                    arrFields.map(function (item: IField, index: number) {
                        if (item.type === "date") {
                            if (data[item.name] !== undefined && data[item.name] !== null && data[item.name] !== "") {
                                data[item.name] = moment(new Date(data[item.name].toString()).toISOString()).format("YYYY-MM-DD")
                            }
                        }
                        if (data[item.name] === undefined) {
                            data[item.name] = ""
                        }
                        return index
                    })
                    AjaxSendFormsValues({
                        form_id: parseInt(match.params.form_id),
                        token: infos.token,
                        values: data
                    }).then(results => {
                        confirmAlert({
                            title: 'Info',
                            message: results.Result,
                            buttons: [
                                {
                                    label: 'Chiudi',
                                    onClick: () => null
                                }
                            ]
                        })
                    })
                    break;
                case 'btnModify':
                    arrFields.map(function (item: IField, index: number) {
                        if (item.type === "date") {
                            if (data[item.name] !== undefined && data[item.name] !== null && data[item.name] !== "") {
                                data[item.name] = moment(new Date(data[item.name].toString()).toISOString()).format("YYYY-MM-DD");
                            }
                            if (data[item.name] == null) {
                                data[item.name] = "";
                            }
                        }
                        if (data[item.name] === undefined) {
                            data[item.name] = "";
                        }
                        return index
                    })
                    AjaxUpdateFormValues({
                        token: infos.token,
                        record_id: parseInt(match.params.record_id),
                        form_id: parseInt(match.params.form_id),
                        values: data

                    }).then(response => {
                        confirmAlert({
                            title: 'Info',
                            message: response.Result,
                            buttons: [
                                {
                                    label: 'Chiudi',
                                    onClick: () => null
                                }
                            ]
                        })
                    })
                    break;
                default:
                    console.log("Niente da fare...");
                    break;
            }
        }
        return;
    }

    const duplicateHandler = () => {
        let data = control.getValues();
        if (data !== undefined) {
            arrFields.map(function (item: IField, index: number) {
                if (item.type === "date") {
                    if (data[item.name] !== undefined && data[item.name] !== null && data[item.name] !== "") {
                        data[item.name] = moment(new Date(data[item.name].toString()).toISOString()).format("YYYY-MM-DD")
                    }
                }
                if (data[item.name] === undefined) {
                    data[item.name] = ""
                }
                return index
            })
            AjaxSendFormsValues({
                form_id: parseInt(match.params.form_id),
                token: infos.token,
                values: data
            }).then(results => {
                confirmAlert({
                    title: 'Info',
                    message: results.Result,
                    buttons: [
                        {
                            label: 'Chiudi',
                            onClick: () => null
                        },
                        {
                            label: 'Vai al Record',
                            onClick: () => gotoRecord(parseInt(results.RecordId), parseInt(match.params.form_id))
                        }
                    ]
                })
            })
        }
    }

    const deleteHandler = () => {
        if (match.params.record_id !== undefined) {
            DeleteRecordById({
                token: infos.token,
                record_id: parseInt(match.params.record_id)
            }).then( result => {
                confirmAlert({
                    title: 'Info',
                    message: result.message,
                    buttons: [
                        {
                            label: 'Chiudi',
                            onClick: () => gotoNewRecord(parseInt(match.params.form_id))
                        }
                    ]
                })
            })
        }
    }

    let history = useHistory();
    const gotoRecord = (record_id: number, form_id: number) => {
        history.push('/forms-view/' + form_id + '/' + record_id);
    }
    const gotoNewRecord = (form_id: number) => {
        history.push('/forms-view/' + form_id);
    }
    const gotoLink = (link:string) => {
        history.push(link);
    }

    return (
        authorized ? (
                <Form onSubmit={handleSubmit(onSubmit)} className={"forms-view"}>
                    <LoadingPage isLoading={loading}></LoadingPage>
                    <PrintFieldsList
                        className={"fieldsContainer"}
                        obj_control={control}
                        arr_fieldsList={fieldsList}
                        usersList={usersList}
                        formsList={formsList}
                        other_practies={practies}
                        token={infos.token}
                        routerprop={match.params}
                        protocol={protocol}
                    />
                    <div className={"buttonContainer"}>
                        {(match.params.record_id === undefined) ?
                            <Controller
                                name="button"
                                control={control}
                                defaultValue="btnSave"
                                as={
                                    <Button
                                        type={"submit"}
                                        className={"SaveButton"}
                                        name={"salva"}
                                    >Salva</Button>
                                }
                            ></Controller>
                            :
                            <div style={{
                                display: "grid",
                                alignItems: "center",
                                gridAutoFlow: "column",
                                gridColumnGap: "10px"
                            }}>
                                {(recordExist === true) ?
                                    <div style={{
                                        display: "grid",
                                        alignItems: "center",
                                        gridAutoFlow: "column",
                                        gridColumnGap: "10px"
                                    }}>
                                        <Controller
                                            name="button"
                                            control={control}
                                            defaultValue="btnModify"
                                            as={
                                                <Button
                                                    type={"submit"}
                                                    className={"ModifyButton"}
                                                    name={"modifica"}
                                                    variant={"success"}
                                                >Modifica</Button>
                                            }
                                        ></Controller>
                                        <Controller
                                            name="btnDuplicate"
                                            control={control}
                                            defaultValue="btnSave"
                                            as={
                                                <Button
                                                    type={"button"}
                                                    className={"DuplicateButton"}
                                                    name={"delete"}
                                                    variant={"warning"}
                                                    onClick={duplicateHandler}
                                                >Duplica Record</Button>
                                            }
                                        ></Controller>
                                        <Controller
                                            name="btnDuplicate"
                                            control={control}
                                            defaultValue="btnSave"
                                            as={
                                                <Button
                                                    type={"button"}
                                                    className={"DuplicateButton"}
                                                    name={"duplicate"}
                                                    variant={"danger"}
                                                    onClick={deleteHandler}
                                                >Elimina Record</Button>
                                            }
                                        ></Controller>
                                    </div>
                                    :
                                    <Controller
                                        name="button"
                                        control={control}
                                        defaultValue="btnSave"
                                        as={
                                            <Button
                                                type={"submit"}
                                                className={"SaveButton"}
                                                name={"salva"}
                                            >Salva</Button>
                                        }
                                    ></Controller>
                                }
                            </div>
                        }
                    </div>
                    <div
                        style={{marginBottom: "8rem"}}
                    ></div>
                </Form>)
            : null
    )
}

export default FormsView;