import React from 'react';
import {UserContext} from './context/UserContext';
import {Redirect} from "react-router";
import {Page404Context} from "./context/Page404Context";
import DMain from "./dashboard_components/DMain";
import {RouteComponentProps} from "react-router-dom";
import MenuDashboard from "./dashboard_components/MenuDashboard";

interface IRouter {
    menu:    string,
    submenu: string,
}

const Dashboard = ({match}: RouteComponentProps<IRouter>) => {

    //User Context
    const { infos, setInfos }:any = React.useContext(UserContext)
    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false

    return(
        <div>
            {(infos.isLogged) ? (
                    <div>
                        {/*<DMain*/}
                        {/*    token={infos.token}*/}
                        {/*    route={match}*/}
                        {/*/>*/}
                        <MenuDashboard
                            token={infos.token}
                            route={match}
                        />
                    </div>
                )
                : (
                    <Redirect
                        push to={"/login"}
                    />
                )
            }
        </div>
    )
}

export default Dashboard