import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    token: string,
    data: string
}

interface IResult {
    id: string,
    type: string,
    message: string,
    data: string,
}

async function AjaxSavePipeData(props:IProps) {

    let results:IResult = {
        id: "",
        type: "",
        message: "",
        data: ""
    };

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "pipedata/save",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token: props.token,
                    data: props.data
                })
            }).then((response) => {
                if(response.status == 200) {
                    results = response.data;
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default AjaxSavePipeData;