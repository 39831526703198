import Axios from 'axios';
import {sosglobal} from "../../sosGlobal";

export interface IProps {
    username:   string,
    email:      string,
    password:   string,
    name:       string,
    surname:    string,
    token:      string
}

async function RegistrationTeamUser(props:IProps) {

    let resp:string = "";

    try {
        await
            Axios.request({
                method: 'POST',
                url: sosglobal.apiUrl + 'api/add-team-user',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    username: props.username,
                    email:    props.email,
                    password: props.password,
                    name:     props.name,
                    surname:  props.surname,
                    token:    props.token
                })
            }).then((response) => {
                if(response.data !== "") {
                    resp = response.data
                } else {
                    resp = "Errore"
                }
                console.log(response.data);
            })
    } catch (err) {
        console.log(err);
    }

    return(resp)
}

export default RegistrationTeamUser;