import React from 'react';
import {Controller, useForm} from "react-hook-form";
import {Form, InputGroup, Row, Col, Button} from "react-bootstrap";
import ListQueryMenu from "./ListQueryMenu";
import GetMenu from "../dashboard_components/AjaxGetMenu";
import SaveMenu from "./AjaxSaveMenu";

interface IProps {
    token: string
}

interface IMenuList {
    id:       number,
    type:     string,
    name:     string,
    position: number
}

const ManageQueryMenu = (props:IProps) => {

    //Init UseForm
    const {control, handleSubmit} = useForm();
    const [isSave, setIsSave] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [menuList, setMenuList] = React.useState<IMenuList[]>([]);

    const onSubmit = (data:any) => {
        setIsSave(true);
        SaveMenu({
            token: props.token,
            data:  JSON.stringify(data)
        }).finally( () => {
            Init();
        })
        setIsSave(false);
    }

    const Init = () => {
        setIsLoading(true);
        setMenuList([]);
        GetMenu({
            token: props.token,
            data: "{}"
        }).then( results => {
            if (results.type === "Success") {
                let menuA: IMenuList[] = JSON.parse(results.data);
                menuA.map( (single_menu:IMenuList) => {
                    setMenuList( prevState => [
                        ...prevState,
                        single_menu
                    ]);
                });
            }
        }).finally( () => {
            setIsLoading(false);
        })
    }

    React.useEffect(Init, []);

    return(
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col sm={4}>
                        <Form.Group controlId={"formTipo"}>
                            <Form.Label>Tipo</Form.Label>
                            <InputGroup>
                                <Controller
                                    name={"type"}
                                    defaultValue={""}
                                    control={control}
                                    render={({onChange, value}) => (
                                        <select
                                            name={"Tipo"}
                                            className="browser-default custom-select"
                                            onChange={value => {
                                                onChange(value);
                                            }} required>
                                            <option key={"Default"} value={""}></option>
                                            <option key={"Menu"} value={"MENU"}>Menù</option>
                                            <option key={"Submenu"} value={"SUBMENU"}>Sotto Menù</option>
                                        </select>
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                        <Form.Group controlId={"formNomeMenu"}>
                            <Form.Label>Nome Menù</Form.Label>
                            <InputGroup>
                                <Controller
                                    name={"name"}
                                    defaultValue={""}
                                    control={control}
                                    render={({onChange, value}) => (
                                        <input
                                            name="Nome Menù"
                                            type="text"
                                            className="form-control"
                                            placeholder="Nome Menù"
                                            aria-label="Nome Menù"
                                            aria-describedby="basic-addon1"
                                            onChange={value => onChange(value)}
                                            value={value}
                                            required
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col sm={2}>
                        <Form.Group controlId={"formPosizione"}>
                            <Form.Label>Posizione</Form.Label>
                            <InputGroup>
                                <Controller
                                    name={"position"}
                                    defaultValue={""}
                                    control={control}
                                    render={({onChange, value}) => (
                                        <input
                                            name="Posizione"
                                            type="text"
                                            className="form-control"
                                            placeholder="Posizione"
                                            aria-label="Posizione"
                                            aria-describedby="basic-addon1"
                                            onChange={value => onChange(value)}
                                            value={value}
                                            required
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col sm={2} className={"py-2"}>
                        <Form.Group controlId={"formSave"}>
                            <Form.Label></Form.Label>
                            <InputGroup>
                                {
                                    isSave ? (
                                        <Button type={"button"} disabled={true} className={"btn btn-primary"}>
                                            <span className="spinner-border"
                                                  style={{
                                                      width: "1.5rem",
                                                      height: "1.5rem",
                                                      marginRight: "5px"
                                                  }}
                                                  role="status"
                                                  aria-hidden="true">
                                            </span>
                                            SALVATAGGIO...
                                        </Button>
                                    ) : (
                                        <Button name={"save"} type={"submit"} className={"btn btn-primary"} >
                                            AGGIUNGI
                                        </Button>
                                    )
                                }
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col sm={12}>
                    <ListQueryMenu
                        token={props.token}
                        menuList={menuList}
                        setMenuList={setMenuList}
                        isLoading={isLoading}
                    />
                </Col>
            </Row>
        </>
    )
}

export default ManageQueryMenu