import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    token: string,
    data: any
}

interface IField {
    id:             number,
    field_name:     string,
    type:           string,
    label:          string,
    placeholder:    string,
    options:        string,
    forms:          [],
}

async function SendFieldValues(props: IProps) {

    let results: {
        id: number,
        type: string,
        message: string,
        data: IField
    } = {
        id: 0,
        type: "",
        message: "",
        data: {
            id:             0,
            field_name:     "",
            type:           "",
            label:          "",
            placeholder:    "",
            options:        "",
            forms:          [],
        }
    }

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "api/add_extra_field",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token: props.token,
                    data: props.data
                })
            }).then((response) => {
                if(response.statusText === 'OK' || response.statusText === '') {
                    results = response.data
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default SendFieldValues;