import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    token:      string,
    record_id:  number
}

interface IMessage {
    id: number,
    type: string,
    message: string,
    data: []
}

async function DeleteRecordById(props: IProps) {

    let results:IMessage = {
        id: 0,
        type: "",
        message: "",
        data: []
    }

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "api/delete_record_by_id",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token:   props.token,
                    record_id: props.record_id
                })
            }).then((response) => {
                if(response.statusText === 'OK' || response.statusText === '') {
                    results = response.data
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default DeleteRecordById;