import React from 'react';
import {UserContext} from "../context/UserContext";
import {Page404Context} from "../context/Page404Context";
import GetFieldsList from "../fields_components/AjaxFieldsList";
import {IStaticFieldsArray, staticFieldsArray} from "../data_components/fields_array";
import {CardContent, CardHeader, Chip, Grid} from "@material-ui/core";
import {Button, Card, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import SearchStart from "./AjaxSearch";
import RecordsTable from "../reusable_components/RecordsTable";

interface IField {
    id:             number,
    id_api:         string,
    name:           string,
    type:           string,
    forms_list:     string,
    label:          string,
    pholder:        string,
    extra_options:  string
}

interface IFilter {
    id:     string,
    id_api: string,
    name:   string,
    type:   string,
    label:  string,
    value:  string,
}

const Search = () => {
    //User Context
    const { infos, setInfos }:any = React.useContext(UserContext);
    //Page404 Context
    const page404 = React.useContext(Page404Context);
    page404.not_found = false;

    const [extraFields, setExtraFields] = React.useState<IField[]>([]);
    const [fieldValue, setFieldValue] = React.useState<string>('{"value": ""}');
    const [dropDownValue, setDropDownValue] = React.useState<string>('{"label":"","name":"","type":"","extra":""}');
    const [dropDownOperation, setDropDownOperation] = React.useState<string>('{"operation":"equal"}');
    const [collectFilters, setCollectFilters] = React.useState<IFilter[]>([]);
    const [records, setRecords] = React.useState<[]>([]);

    React.useEffect( () => {
        GetFieldsList({
            token: infos.token,
        }).then( (extraFieldsList:IField[]) => {
            setExtraFields(extraFieldsList);
        })
    } , []);

    const onChangeDropDownFields = (value:string) => {
        setDropDownValue(value);
    }

    const onChangeDropDownOperations = (value:string) => {
        setDropDownOperation(value);
    }

    const onChangeFieldValue = ( value:string ) => {
        setFieldValue(JSON.stringify({"value": value}));
    }

    const clickHandler = () => {
        if( dropDownValue !== "" ) {
            if( fieldValue == "" ) setFieldValue(JSON.stringify({"value": ""}));
            let dpvArr = JSON.parse(dropDownValue);
            let dpoArr = JSON.parse(dropDownOperation);
            let concArr = {...dpvArr, ...(JSON.parse(fieldValue)), ...dpoArr};
            setCollectFilters(prevState => [
              ...prevState,
                concArr
            ]);
        }
    }

    const onDeleteFilterHandler = (index: number) => {
        if(index > -1) {
            collectFilters.splice(index, 1);
            setCollectFilters([]);
            collectFilters.map( (filter:IFilter, index:number) => {
                setCollectFilters( prevState => [
                    ...prevState,
                    filter
                ])
            });
        }
    }

    const searchHandler = () => {
        SearchStart({
            token: infos.token,
            data: JSON.stringify(collectFilters)
        }).then( result => {
            if(result.type === "Success" && result.data != undefined) {
                let records = JSON.parse(result.data.toString()).records;
                setRecords([]);
                setRecords(records);
            }
            // console.log(result);
        });
        // console.log(collectFilters);
    }

    return(
        <Container fluid={true} className={"mt-3"}>
            <Card>
                <CardHeader
                    title={"Ricerca"}
                    subheader={"Imposta i filtri per la ricerca"}
                />
                <CardContent>
                    <Row>
                        <Col sm={2}>
                            <Form.Group controlId={"fields"}>
                                <Form.Label>Scegli un campo</Form.Label>
                                <InputGroup>
                                    <select className="browser-default custom-select" name="fields" id="fields" onChange={ (e) => onChangeDropDownFields(e.currentTarget.value) }>
                                        <option value=""></option>
                                        { staticFieldsArray.map( (field:IStaticFieldsArray, index:number) => {
                                            {
                                                let jsonString:string = JSON.stringify({"id": index,"label": field.label,"name": field.name,"type": field.inputType,"extraf": false});
                                                return (
                                                    <option key={index} value={jsonString}>{field.label}</option>
                                                )
                                            }
                                          })
                                        }
                                        {
                                            extraFields.map( (field:IField, index:number) => {
                                                {
                                                    let jsonString:string = JSON.stringify({"id": index,"label": field.label,"name": field.name,"type": field.type,"extraf": true, "id_api": field.id_api});
                                                    return (
                                                        <option key={index} value={jsonString}>{field.label}</option>
                                                    )
                                                }
                                            })
                                        }
                                    </select>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group controlId={"operations"}>
                                <Form.Label>Operazione</Form.Label>
                                <InputGroup>
                                    <select className="browser-default custom-select" name="operations" id="operations" onChange={ (e) => onChangeDropDownOperations(e.currentTarget.value) }>
                                        <option value={JSON.stringify({"operation": "equal"})}>Uguale a</option>
                                        <option value={JSON.stringify({"operation": "not_equal"})}>Diverso da</option>
                                        <option value={JSON.stringify({"operation": "contain"})}>Contiene</option>
                                    </select>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group controlId={"value"}>
                                <Form.Label>Valore</Form.Label>
                                <InputGroup>
                                    <FormControl
                                        placeholder={""}
                                        aria-label={"fieldValue"}
                                        aria-describedby={"fieldValue"}
                                        name={"fieldValue"}
                                        onChange={ (q) => onChangeFieldValue(q.currentTarget.value) }
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Button style={{marginTop: "1.9em", float: "left"}} variant={"info"} name={"add_filter"} onClick={ clickHandler }>Aggiungi Filtro</Button>
                            <Button style={{marginLeft: "1.9em", marginTop: "1.9em", float: "left"}} variant={"success"} name={"search"} onClick={ searchHandler }>Avvia Ricerca</Button>
                        </Col>
                    </Row>
                    <Grid>
                        {
                            collectFilters.map( (filter:IFilter, index:number) => {
                                return(
                                    <Chip key={index} style={{marginLeft: "1em", marginBottom: "1em"}} label={filter.label + ":" + filter.value} variant="outlined" onDelete={ (e) => onDeleteFilterHandler(index) } />
                                )
                            })
                        }
                    </Grid>
                </CardContent>
            </Card>
            <Row className={"mt-5"}>
                <RecordsTable records={records} />
            </Row>
        </Container>
    )
}

export default Search