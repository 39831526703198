import React, {useContext} from "react";
import {UserContext} from "./context/UserContext";
import {Col, Container, Row, Form, InputGroup, Button, Modal, Card} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import CheckParameters from "./reg_components/CheckParameters";
import {Page404Context} from "./context/Page404Context";
import {Redirect} from "react-router";

export interface IParams {
    email: string,
    username: string,
    password: string
}

export interface IDialog {
    show: boolean,
    message: string
}

const RegistrationForm = () => {

    //Variabile di check registrazione
    const [regUser, setRegUser] = React.useState(false)

    //User Context
    const { infos, setInfos }:any = useContext(UserContext)
    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false

    //Gestione Errori
    const [dialog, setDialog] = React.useState<IDialog>({
        show: false,
        message: ""
    })

    //Input
    const [parameters, setParameters] = React.useState<IParams>({
        email: "",
        username: "",
        password: ""
    })

    const submitHanlder = (event: React.FormEvent) => {
        event.preventDefault()
    }

    //Fase di Registrazione utente
    const registrationHandler = () => {
        if(parameters.email !== "" && parameters.username !== "" && parameters.password !== "") {
            CheckParameters(parameters).then( (response) => {
                let resp = JSON.parse(JSON.stringify(response))
                if(resp.id === 0) setRegUser(true); else setRegUser(false)
                setDialog({
                    show: true,
                    message: resp.message
                })
            })
        } else {
            setDialog({
                show: true,
                message: "Inserire i dati mancanti"
            })
        }
    }

    const hideDialogHandler =  () => {
        setDialog({
            show: false,
            message: dialog.message
        })
        if(regUser === true) {
            window.location.reload()
        }
    }

    return(
        <Container>
            {(dialog.show) ? (
                <Modal show={dialog.show} onHide={ () => hideDialogHandler() } centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Attenzione!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {dialog.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={ () => hideDialogHandler() }>
                            CHIUDI
                        </Button>
                    </Modal.Footer>
                </Modal>
            ): null}
            {(!infos.isLogged) ? (
                <div className={"mt-5"}>
                    <Row>
                        <Col sm={3}/>
                        <Col sm={6}>
                            <Card className={"shadow"}>
                                <Card.Header className={"py-3"}>
                                    <h6 className={"m-0 font-weight-bold text-secondary"}>REGISTRAZIONE</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Form onSubmit={submitHanlder}>
                                        <Form.Group controlId={"email"}>
                                            <Form.Label>
                                                Email
                                            </Form.Label>
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faEnvelope}/>
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    type={"text"}
                                                    placeholder={"Inserisci la mail"}
                                                    onChange={(event) => {
                                                        setParameters({
                                                            email: event.target.value,
                                                            username: parameters.username,
                                                            password: parameters.password
                                                        })
                                                    }}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group controlId={"username"}>
                                            <Form.Label>
                                                Username
                                            </Form.Label>
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faUser}/>
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    type={"text"}
                                                    placeholder={"Inserisci il nome utente"}
                                                    onChange={(event) => {
                                                        setParameters({
                                                            email: parameters.email,
                                                            username: event.target.value,
                                                            password: parameters.password
                                                        })
                                                    }}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group controlId={"password"}>
                                            <Form.Label>
                                                Password
                                            </Form.Label>
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faKey}/>
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    type={"password"}
                                                    placeholder={"Inserisci password"}
                                                    onChange={(event) => {
                                                        setParameters({
                                                            email: parameters.email,
                                                            username: parameters.username,
                                                            password: event.target.value
                                                        })
                                                    }}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <div className={"mt-4"}>
                                            <Button
                                                variant="lg btn-primary btn-block"
                                                onClick={(registrationEvent) => registrationHandler()}
                                            >REGISTRATI</Button>
                                        </div>
                                        <div className={"mt-4 float-right"}>
                                            <label>Se vuoi Loggarti, clicca <a href={"/login"}>qui</a></label>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={3}/>
                    </Row>
                </div>
            ) : (
                <Redirect push to={'/'}/>
            )}
        </Container>
    )
}

export default RegistrationForm