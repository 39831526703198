import React, {useEffect} from 'react';
import {Row, Col, Container, Modal, Button, Card} from 'react-bootstrap';
import {UserContext} from "./context/UserContext";
import {IDialog} from "./Profilo";
import GetTeamList, {IUser} from "./user_components/GetTeamList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import "./css/team.css";
import DeleteUser from "./user_components/DeleteUser";
import GetExtraInfos from "./user_components/GetExtraInfos";
import {Page404Context} from "./context/Page404Context";


export interface ITeamInfos {
    avaiable:  number,
    total:     number,
    used:      number
}

const Team = () => {

    //User Context
    const { infos, setInfos }:any = React.useContext(UserContext)
    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false

    //Dialog setup
    const [dialog, setDialog] = React.useState<IDialog>({
        show: false,
        message: ""
    })

    //Confirmation Dialog setup
    const [confDialog, setConfDialog] = React.useState({
        show: false,
        message: "",
        id: ""
    })

    // Single Team
    const [team] = React.useState<IUser>({
        id: "",
        name: "",
        surname: "",
        email: ""
    })

    // Array Teams
    const [teams, setTeams] = React.useState([team])

    const hideDialogHandler =  () => {
        setDialog({
            show: false,
            message: dialog.message
        })
        setConfDialog({
            show: false,
            message: dialog.message,
            id: ""
        })
    }

    // User Teams Available Infos
    const [teamInfos, setTeamInfos] = React.useState<ITeamInfos>({
        total:    0,
        avaiable: 0,
        used:     0
    })

    // Disable AddTeam Button
    const [addteamdis, setAddTeamDis] = React.useState({
        disabled: false
    })

    // Retrieve Users List
    const getTeamLists = () => {
        setTeams([]);
        GetTeamList({
            token: infos.token
        }).then( ( result ) => {
            if(result.error) {
                if(result.errnum === 2) {
                    //Errore Token relogin
                    localStorage.removeItem("credential")
                    setInfos({
                        isLogged:   false,
                        username:   "",
                        email:      "",
                        token:      "",
                        name:       "",
                        surname:    "",
                    })
                }
            } else {
                if(result.users !== undefined) {
                    let usersList:[IUser] = result.users;
                    usersList.map( (user:IUser, index:number) => {
                        setTeams( prevState => [
                            ...prevState,
                            user,
                        ]);
                    });
                }
            }
        })
    }
    // Extra Infos User Teams Available
    const getExtraInfos = () => {
        GetExtraInfos({
            token: infos.token,
            var_name: "team_available_users"
        }).then((result) => {
            if(result.id === 0){
                const jsonData = JSON.parse(result.data)
                setTeamInfos({
                    avaiable: jsonData.avaiable,
                    total:    jsonData.total,
                    used:     jsonData.used
                })
                if(jsonData.avaiable < 1) {
                    setAddTeamDis({
                        disabled: true
                    })
                } else {
                    setAddTeamDis({
                        disabled: false
                    })
                }
            } else {
                setAddTeamDis({
                    disabled: true
                })
            }
        })
    }

    // Init functions
    useEffect(getTeamLists, [])
    // Get Extra infos
    useEffect(getExtraInfos, [])

    const showMessageHandler = (id:string) => {
        setConfDialog({
            show: true,
            message: "Sei sicuro di voler eliminare il collaboratore?",
            id: id
        })
    }
    const deleteHandler = (id:string) => {
        DeleteUser({
            token: infos.token,
            user_id: id
        }).then( (result) => {
            if(!result.error) {
                setDialog({
                    show: true,
                    message: "Collaboratore eliminato!"
                })
                //Refresh lista
                getTeamLists()
                //Refresh Extra Infos
                getExtraInfos()
            }
        })
    }

    const editHandler = (id:string) => {
        window.location.href = "/edit-team-user/"+id
    }

    const addUserHandler = () => {
        window.location.href = "/add-team-user"
    }

    return(
        <Container>
            {(dialog.show) ? (
                <Modal show={dialog.show} onHide={ () => hideDialogHandler() } centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Attenzione!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {dialog.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={ () => hideDialogHandler() }>
                            CHIUDI
                        </Button>
                    </Modal.Footer>
                </Modal>
            ): null}
            {(confDialog.show) ? (
                <Modal show={confDialog.show} onHide={ () => hideDialogHandler() } centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Attenzione!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {confDialog.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={ () => hideDialogHandler() }>
                            CHIUDI
                        </Button>
                        <Button variant="success" onClick={
                            () => {
                                deleteHandler(confDialog.id)
                                hideDialogHandler()
                            }
                        }
                        >PROCEDI</Button>
                    </Modal.Footer>
                </Modal>
            ): null}
            { infos.isLogged ? (
                <Row>
                    <Col sm={12} className={"py-5"}>
                        <Card className={"shadow"}>
                            <Card.Header className={"py-3"}>
                                <h6 className={"m-0 font-weight-bold text-secondary"}>GESTIONE TEAM</h6>
                            </Card.Header>
                            <Card.Body>
                                <Row style={{marginBottom:"1rem", flexDirection: "row"}}>
                                    <Col sm={4}>
                                        <Button
                                            type={"button"}
                                            className={"btn-default shadow rounded-0"}
                                            onClick={addUserHandler}
                                            disabled={ addteamdis.disabled }
                                        >AGGIUNGI COLLABORATORE
                                        </Button>
                                    </Col>
                                    <Col sm={8}>
                                        <Row>
                                            <Col sm={4}>
                                                <span>Collaboratori registrati: {teamInfos.used}</span>
                                            </Col>
                                            <Col sm={4}>
                                                <span>Posti rimanenti: {teamInfos.avaiable}</span>
                                            </Col>
                                            <Col sm={4}>
                                                <span>Collaboratori totali: {teamInfos.total}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={"12"} className={"table-responsive"}>
                                        <table className={"table table-hover"}>
                                            <thead className={"mouse-default"}>
                                            <tr className={"table-primary"} style={{textAlign:"center"}}>
                                                <th>Nome</th>
                                                <th>Cognome</th>
                                                <th>Email</th>
                                                <th>Opzioni</th>
                                            </tr>
                                            </thead>
                                            <tbody className={"mouse-default"} style={{textAlign:"center"}}>
                                            { teams.map((user, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{user.name}</td>
                                                        <td>{user.surname}</td>
                                                        <td>{user.email}</td>
                                                        <td>
                                                            <div className={"options"}>
                                                        <span className={"mouse-cursore"} onClick={(id) => editHandler(user.id)} title={"Modifica"}>
                                                            <FontAwesomeIcon icon={faPen} />
                                                        </span>
                                                                <span className={"mouse-cursore"} onClick={(id) => showMessageHandler(user.id)} title={"Elimina"}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) }
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            ):(
                page404.forbidden = true
            )}
        </Container>
    )
}

export default Team