import React from 'react';
import {Container, Row, Col, Card, Form, InputGroup, Button, Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faKey, faUser} from "@fortawesome/free-solid-svg-icons";
import UpdateProfile from './update_components/UpdateProfile';
import {UserContext} from "./context/UserContext";
import {Page404Context} from "./context/Page404Context";

export interface IParams {
    nome:           string,
    cognome:        string,
    username:       string,
    oldpassword:    string,
    newpassword:    string,
    retypepassword: string
}

export interface IDialog {
    show: boolean,
    message: string
}

const Profilo = () => {

    //User Context
    const { infos, setInfos }:any = React.useContext(UserContext)
    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false

    //Input
    const [parameters, setParameters] = React.useState<IParams>({
        nome:           (infos.name !== "")     ? infos.name : "",
        cognome:        (infos.surname !== "")  ? infos.surname : "",
        username:       (infos.username !== "") ? infos.username : "",
        oldpassword:    "",
        newpassword:    "",
        retypepassword: ""
    })

    //Dialog setup
    const [dialog, setDialog] = React.useState<IDialog>({
        show: false,
        message: ""
    })

    const submitHanlder = (event: React.FormEvent) => {
        event.preventDefault()
    }

    const updateHandler = () => {
        if(parameters.newpassword !== parameters.retypepassword) {
            setDialog({
                show: true,
                message: "Le password non corrispondono!"
            })
        } else {
            UpdateProfile({
                username: parameters.username,
                email: infos.email,
                password: parameters.oldpassword,
                new_password: parameters.newpassword,
                name: parameters.nome,
                surname: parameters.cognome,
                token: infos.token
            }).then((result) => {
                if(!result.error) {
                    setParameters({
                        nome: parameters.nome,
                        cognome: parameters.cognome,
                        username: parameters.username,
                        oldpassword: "",
                        newpassword: "",
                        retypepassword: ""
                    })
                    setInfos({
                        isLogged: infos.isLogged,
                        username: parameters.username,
                        email: infos.email,
                        token: infos.token,
                        name: result.name,
                        surname: result.surname,
                    })
                    localStorage.setItem("credential", JSON.stringify({
                        isLogged: infos.isLogged,
                        username: parameters.username,
                        email: infos.email,
                        token: infos.token,
                        name: result.name,
                        surname: result.surname,
                    }))
                }
            })
        }
    }

    const hideDialogHandler =  () => {
        setDialog({
            show: false,
            message: dialog.message
        })
    }

    return(
        <Container fluid={"sm"}>
            {(dialog.show) ? (
                <Modal show={dialog.show} onHide={ () => hideDialogHandler() } centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Attenzione!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {dialog.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={ () => hideDialogHandler() }>
                            CHIUDI
                        </Button>
                    </Modal.Footer>
                </Modal>
            ): null}
            { infos.isLogged ? (
                <Row>
                    <Col sm={12} className={"py-5"}>
                        <Card className={"shadow"}>
                            <Card.Header className={"py-3"}>
                                <h6 className={"m-0 font-weight-bold text-secondary"}>DETTAGLI UTENTE</h6>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={submitHanlder}>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group controlId={"nome"}>
                                                <Form.Label>
                                                    Nome
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faEnvelope}/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"text"}
                                                        value={parameters.nome}
                                                        onChange={(event) => {
                                                            setParameters({
                                                                nome:           event.target.value,
                                                                cognome:        parameters.cognome,
                                                                username:       parameters.username,
                                                                oldpassword:    parameters.oldpassword,
                                                                newpassword:    parameters.newpassword,
                                                                retypepassword: parameters.retypepassword,
                                                            })
                                                        }}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group controlId={"cognome"}>
                                                <Form.Label>
                                                    Cognome
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faEnvelope}/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"text"}
                                                        value={parameters.cognome}
                                                        onChange={(event) => {
                                                            setParameters({
                                                                nome:           parameters.nome,
                                                                cognome:        event.target.value,
                                                                username:       parameters.username,
                                                                oldpassword:    parameters.oldpassword,
                                                                newpassword:    parameters.newpassword,
                                                                retypepassword: parameters.retypepassword,
                                                            })
                                                        }}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group controlId={"username"}>
                                                <Form.Label>
                                                    Username
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faUser}/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"text"}
                                                        placeholder={"Inserisci il nome utente"}
                                                        value={parameters.username}
                                                        onChange={(event) => {
                                                            setParameters({
                                                                nome:           parameters.nome,
                                                                cognome:        parameters.cognome,
                                                                username:       event.target.value,
                                                                oldpassword:    parameters.oldpassword,
                                                                newpassword:    parameters.newpassword,
                                                                retypepassword: parameters.retypepassword,
                                                            })
                                                        }}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group controlId={"email"}>
                                                <Form.Label>
                                                    Email
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faEnvelope}/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"text"}
                                                        disabled
                                                        value={infos.email}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group controlId={"password"}>
                                                <Form.Label>
                                                    Password Attuale
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faKey}/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"password"}
                                                        placeholder={"Inserisci password"}
                                                        onChange={(event) => {
                                                            setParameters({
                                                                nome:           parameters.nome,
                                                                cognome:        parameters.cognome,
                                                                username:       parameters.username,
                                                                oldpassword:    event.target.value,
                                                                newpassword:    parameters.newpassword,
                                                                retypepassword: parameters.retypepassword,
                                                            })
                                                        }}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group controlId={"newpassword"}>
                                                <Form.Label>
                                                    Nuova Password
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faKey}/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"password"}
                                                        placeholder={"Inserisci la nuova password"}
                                                        onChange={(event) => {
                                                            setParameters({
                                                                nome:           parameters.nome,
                                                                cognome:        parameters.cognome,
                                                                username:       parameters.username,
                                                                oldpassword:    parameters.oldpassword,
                                                                newpassword:    event.target.value,
                                                                retypepassword: parameters.retypepassword,
                                                            })
                                                        }}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group controlId={"retypepassword"}>
                                                <Form.Label>
                                                    Reinserimento Nuova Password
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faKey}/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"password"}
                                                        placeholder={"Inserisci nuovamente la password"}
                                                        onChange={(event) => {
                                                            setParameters({
                                                                nome:           parameters.nome,
                                                                cognome:        parameters.cognome,
                                                                username:       parameters.username,
                                                                oldpassword:    parameters.oldpassword,
                                                                newpassword:    parameters.newpassword,
                                                                retypepassword: event.target.value,
                                                            })
                                                        }}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={6}>
                                            <Button
                                                variant="lg btn-primary btn-block"
                                                onClick={(updateEvent) => updateHandler()}
                                            >AGGIORNA I DATI</Button>
                                        </Col>
                                        <Col sm={3}></Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            ): page404.forbidden = true }
        </Container>
    )
}

export default Profilo