import React, {useEffect} from "react";
import {UserContext} from "../context/UserContext";
import {useParams} from "react-router";
import {Page404Context} from "../context/Page404Context";
import {IDialog} from "./AddTeamUser";
import {Button, Card, Col, Container, Form, InputGroup, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faKey, faUser, faChalkboardTeacher} from "@fortawesome/free-solid-svg-icons";
import GetSingleUserInfos from "../user_components/GetSingleUserInfos";
import ModifySingleUserInfos from "../user_components/ModifySingleUserInfos";

export interface RouteParams {
    id: string
}

export interface IParams {
    email:      string,
    username:   string,
    password:   string,
    name:       string,
    surname:    string,
    token:      string,
    active:     boolean,
    level:      number,
}

const EditTeamUser = () => {

    //Page404 Context
    const page404 = React.useContext(Page404Context)
    page404.not_found = false

    //Infos
    const { infos, setInfos }:any = React.useContext(UserContext)

    //Gestione Errori
    const [dialog, setDialog] = React.useState<IDialog>({
        show: false,
        message: ""
    })

    //Input
    const [parameters, setParameters] = React.useState<IParams>({
        email:      "",
        username:   "",
        password:   "",
        name:       "",
        surname:    "",
        token:      infos.token,
        active:     false,
        level:      0,
    })

    //Nascondi finestra di dialogo
    const hideDialogHandler =  () => {
        setDialog({
            show: false,
            message: dialog.message
        })
    }

    const submitHanlder = (event: React.FormEvent) => {
        event.preventDefault()
    }
    //Router Parameters
    const params = useParams<RouteParams>()

    //Modify Collaborator
    const editingHandler = () => {
        ModifySingleUserInfos({
            token:      infos.token,
            user_id:    parseInt(params.id),
            email:      parameters.email,
            username:   parameters.username,
            password:   parameters.password,
            name:       parameters.name,
            surname:    parameters.surname,
            active:     Boolean(parameters.active),
            level:      parameters.level,
        }).then((result) => {
            setDialog({
                show: true,
                message: result.message
            })
        })
    }

    //Fetch Collaborator
    const fetchCollab = () => {
        GetSingleUserInfos({
            token:      infos.token,
            user_id:    parseInt(params.id)
        }).then((result) => {
            if(result.id === 0) {
                let dati = JSON.parse(result.data)
                let active_status:boolean = false
                if(dati.active == 1) active_status = true
                if(dati.active == 0) active_status = false;
                setParameters({
                    username: dati.username,
                    name:     dati.name,
                    surname:  dati.surname,
                    email:    dati.email,
                    password: parameters.password,
                    token:    infos.token,
                    active:   active_status,
                    level:    parseInt(dati.level),
                })
            }
        })
    }

    // Init functions
    useEffect(fetchCollab, [])

    return (
        <Container>
            {(dialog.show) ? (
                <Modal show={dialog.show} onHide={ () => hideDialogHandler() } centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Attenzione!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {dialog.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={ () => hideDialogHandler() }>
                            CHIUDI
                        </Button>
                    </Modal.Footer>
                </Modal>
            ): null}
            {(infos.isLogged) ? (
                <Row>
                    <Col sm={2}></Col>
                    <Col sm={8} className={"py-5"}>
                        <Card className={"shadow"}>
                            <Card.Header className={"py-3"}>
                                <h6 className={"m-0 font-weight-bold text-secondary"}>
                                    MODIFICA COLLABORATORE
                                </h6>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={submitHanlder}>
                                    <div className="custom-control custom-switch float-right">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customSwitch1"
                                            checked={parameters.active}
                                            onChange={(event) => {
                                                setParameters({
                                                    email:      parameters.email,
                                                    username:   parameters.username,
                                                    password:   parameters.password,
                                                    name:       parameters.name,
                                                    surname:    parameters.surname,
                                                    token:      parameters.token,
                                                    active:     event.target.checked,
                                                    level:      parameters.level,
                                                })
                                            }}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="customSwitch1">Attivato/Disattivato
                                        </label>
                                    </div>
                                    <Form.Group controlId={"email"}>
                                        <Form.Label>
                                            Email
                                        </Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faEnvelope}/>
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type={"text"}
                                                placeholder={"Inserisci la mail"}
                                                disabled={true}
                                                onChange={(event) => {
                                                    setParameters({
                                                        email:      parameters.email,
                                                        username:   parameters.username,
                                                        password:   parameters.password,
                                                        name:       parameters.name,
                                                        surname:    parameters.surname,
                                                        token:      parameters.token,
                                                        active:     parameters.active,
                                                        level:      parameters.level,
                                                    })
                                                }}
                                                value={parameters.email}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Row>
                                        <Col sm={9}>
                                            <Form.Group controlId={"username"}>
                                                <Form.Label>
                                                    Username
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faUser}/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"text"}
                                                        placeholder={"Inserisci il nome utente"}
                                                        onChange={(event) => {
                                                            setParameters({
                                                                email:      parameters.email,
                                                                username:   event.target.value,
                                                                password:   parameters.password,
                                                                name:       parameters.name,
                                                                surname:    parameters.surname,
                                                                token:      parameters.token,
                                                                active:     parameters.active,
                                                                level:      parameters.level,
                                                            })
                                                        }}
                                                        value={parameters.username}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Group controlId={"level"}>
                                                <Form.Label>
                                                    Livello
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faChalkboardTeacher}/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"text"}
                                                        placeholder={"Livello Utente"}
                                                        onChange={(event) => {
                                                            setParameters({
                                                                email:      parameters.email,
                                                                username:   parameters.username,
                                                                password:   parameters.password,
                                                                name:       parameters.name,
                                                                surname:    parameters.surname,
                                                                token:      parameters.token,
                                                                active:     parameters.active,
                                                                level:      (event.target.value == "" ? 1 : parseInt(event.target.value)),
                                                            })
                                                        }}
                                                        value={parameters.level == 0 ? parameters.level = 1 : parameters.level}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group controlId={"password"}>
                                        <Form.Label>
                                            Password
                                        </Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faKey}/>
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type={"password"}
                                                placeholder={"****************"}
                                                onChange={(event) => {
                                                    setParameters({
                                                        email:      parameters.email,
                                                        username:   parameters.username,
                                                        password:   event.target.value,
                                                        name:       parameters.name,
                                                        surname:    parameters.surname,
                                                        token:      parameters.token,
                                                        active:     parameters.active,
                                                        level:      parameters.level,
                                                    })
                                                }}
                                                value={parameters.password}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group controlId={"nome"}>
                                                <Form.Label>
                                                    Nome
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faUser}/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"text"}
                                                        placeholder={"Inserisci nome"}
                                                        onChange={(event) => {
                                                            setParameters({
                                                                email:      parameters.email,
                                                                username:   parameters.username,
                                                                password:   parameters.password,
                                                                name:       event.target.value,
                                                                surname:    parameters.surname,
                                                                token:      parameters.token,
                                                                active:     parameters.active,
                                                                level:      parameters.level,
                                                            })
                                                        }}
                                                        value={parameters.name}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group controlId={"cognome"}>
                                                <Form.Label>
                                                    Cognome
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faUser}/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type={"text"}
                                                        placeholder={"Inserisci cognome"}
                                                        onChange={(event) => {
                                                            setParameters({
                                                                email:      parameters.email,
                                                                username:   parameters.username,
                                                                password:   parameters.password,
                                                                name:       parameters.name,
                                                                surname:    event.target.value,
                                                                token:      parameters.token,
                                                                active:     parameters.active,
                                                                level:      parameters.level,
                                                            })
                                                        }}
                                                        value={parameters.surname}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                            <Card.Footer className={"footer-card"}>
                                <Button
                                    variant={"lg btn-secondary"}
                                    onClick={ () => { window.location.href = "/team" }  }
                                >CHIUDI</Button>
                                <Button
                                    variant="lg btn-primary"
                                    onClick={(editingEvent) => editingHandler()}
                                >MODIFICA DATI</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
            ) : page404.forbidden = true }
        </Container>
    )
}

export default EditTeamUser;