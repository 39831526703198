import React, {ChangeEvent} from "react";
import {Control} from "react-hook-form";
import {Container, Form, InputGroup} from "react-bootstrap";
import GetQueryListDropDown from "../querybuilder_components/AjaxGetQueryListDropDown";

interface IProps {
    token: string,
    label: string,
    querySelected?: React.Dispatch<React.SetStateAction<IQuery | undefined>>
    control?: Control<Record<string, any>>,
}

interface IQuery {
    id:   number,
    name: string,
}

const RetrieveQueryListDropDown = (props:IProps) => {

    const [selectedQuery, setSelectedQuery] = React.useState<IQuery>();
    const [queries, setQueries] = React.useState<IQuery[]>([]);

    const selectedHandle = (name: string, id: string) => {
        setSelectedQuery({
            id: parseInt(id),
            name: name
        })
        if(props.querySelected != undefined) {
            props.querySelected({
                id: parseInt(id),
                name: name
            })
        };
        props.control?.setValue(
            "query",
            id
        );
    }

    const retrieveQueryList = () => {
        GetQueryListDropDown({
            token: props.token,
        }).then( result => {
            if(result.type === "Success") {
                setQueries(JSON.parse(result.data))
            }
        })
    }
    React.useEffect(retrieveQueryList, []);

    return(
        <Container>
            <Form.Group controlId={"queriesList"}>
                <Form.Label>{props.label}</Form.Label>
                <InputGroup>
                    <select
                        name={"queriesList"}
                        className={"browser-default custom-select"}
                        onChange={(data: ChangeEvent<HTMLSelectElement>) => selectedHandle(data.target.selectedOptions[0].innerText, data.target.value)}
                    >
                        <option></option>
                        {
                            queries.map((query: IQuery, index: number) => {
                                return <option value={query.id}
                                               key={index}>{query.name}</option>
                            })
                        }
                    </select>
                </InputGroup>
            </Form.Group>
        </Container>
    )

}

export default RetrieveQueryListDropDown