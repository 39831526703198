import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    token:  string,
    data:   string,
}

interface IMessage {
    id:         number,
    type:       string,
    message:    string,
    data:       string
}

interface IFilter {
    id:    string,
    name:  string,
    type:  string,
    label: string,
    value: string,
}

async function SearchStart(props: IProps) {

    let results:IMessage = {
        id:         0,
        type:       "",
        message:    "",
        data:       ""
    }

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "search",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token:  props.token,
                    data:   props.data
                })
            }).then((response) => {
                if(response.statusText === 'OK' || response.status == 200) {
                    results = response.data
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default SearchStart;