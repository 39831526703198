import React from 'react';
import ReactDOM from 'react-dom';
import Home from './Home';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

ReactDOM.render(
    <React.StrictMode>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Titillium+Web:100,200,300,400,500,700|Material+Icons"></link>
        <Home />
    </React.StrictMode>,
    document.getElementById('root')
);
