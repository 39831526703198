import React from 'react';
import styled from "styled-components";
import {FadeLoader} from "react-spinners";

interface IProps {
    isLoading: boolean
}

const LoadingPage = (props:IProps) => {

    const LoaderWrapper = styled.div`
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        background: rgba(255, 255, 255, 0.95);
        z-index: 1000;
    `;

    const ContentLoader = styled.div`
        display: flex;
        flex: 0 auto;
        flex-direction: column;
        flex-wrap: wrap;
        height: 80%;
        justify-content: center;
        align-items: center;
        align-content: center;
        color: #00A5CF;
    `;

    const TitleLoader = styled.div`
        display: flex;
        flex: 0 auto;
        align-content: center;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 50px;
        margin-left: 3%;
    `;

    return(
        <div>
            {
                props.isLoading ?
                    <LoaderWrapper id="loader-wrapper">
                        <ContentLoader>
                            <FadeLoader
                                height={50}
                                width={10}
                                radius={50}
                                margin={50}
                                color={"#00A5CF"}
                            />
                            <TitleLoader><h4>CARICAMENTO IN CORSO</h4></TitleLoader>
                        </ContentLoader>
                    </LoaderWrapper>
                    : null
            }
        </div>
    )
}

export default LoadingPage