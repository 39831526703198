import React from 'react';
import MUIDataTable, {MUIDataTableColumnDef} from "mui-datatables";
import {CircularProgress, Grid, Tooltip} from "@material-ui/core";
import {ArrowRight, DeleteForever} from "@material-ui/icons";
import AjaxGetPipeData from "./AjaxGetPipeDataFromDB";
import AjaxRetrievePipeData from "./AjaxRetrievePipeData";
import { confirmAlert } from 'react-confirm-alert';
import '../css/globalCSS.css';
import AjaxSendFormsValues from "../forms_components/AjaxSendFormsValues";
import {Col, Row} from "react-bootstrap";
import AjaxDeletePipeDateFromDB from "./AjaxDeletePipeDateFromDB";

interface IProps {
    token: string,
    records: React.Dispatch<React.SetStateAction<any>>,
    extraInfo: IExtra,
    extraInfoValues: React.Dispatch<React.SetStateAction<IExtra>>
}

interface IExtra {
    total:      number
    used:       number,
    available:  number,
}

const TableDataPipeDrive = (props:IProps) => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const [records, setRecords] = React.useState<any>(props.records);

    const GetPipeData = () => {
        AjaxGetPipeData({
            token: props.token
        }).then( result => {
            if(result != undefined && result.type == "Success") {
                let recs:[] = JSON.parse(result.data);
                setRecords(recs);
            }
        });
    }

    React.useEffect(GetPipeData, []);

    const columns: MUIDataTableColumnDef[] = [
        {
            name: "id",
            label: "ID",
        },
        {
            name: "name",
            label: "Nome PipeData",
        },
        {
            name: "filter_id",
            label: "Filtro ID",
        },
        {
            name: "user_email",
            label: "Utente",
        },
        {
            name: "form_id",
            label: "Maschera",
        },
        {
            name: "endpoint",
            label: "EndPoint",
            options: {
                display: false
            }
        },
        {
            name: "operations",
            label: "Operazioni",
            options: {
                sort: false,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <Grid
                            container
                            item
                            xs={8}
                            alignItems={"center"}
                            justify={"space-between"}
                            direction={"row"}
                        >
                            <Tooltip onClick={() => pipeDataPlay(tableMeta.rowData[0], tableMeta.rowData[1], tableMeta.rowData[2], tableMeta)} title={"Avvia"}>
                                <ArrowRight />
                            </Tooltip>
                            <div onClick={() => pipeDataDel(tableMeta.rowData[0], tableMeta.rowData[1])}>
                                <Tooltip title={"Elimina"}>
                                    <DeleteForever color={"error"}/>
                                </Tooltip>
                            </div>
                        </Grid>
                    )
                },
            },
        }
    ]

    const pipeDataPlay = (index: number, pipeDataName: string, filter_id: number, allData: any) => {
        setLoading(true);
        let user_email:string = allData.rowData[3];
        let form_id:number = allData.rowData[4];
        AjaxRetrievePipeData({
            idprogram: index,
            filterid: filter_id,
            name: pipeDataName,
            affidate2: user_email,
            endpoint: allData.rowData[5],
        }).then( (result:any) => {
            if(result != undefined) {
                if(result.type == "S") {
                    Save2DbPipeData(form_id, result.data);
                } else {
                    confirmAlert({
                        title: 'Errore',
                        message: result.message,
                        buttons: [
                            {
                                label: 'Chiudi',
                                onClick: () => null
                            }
                        ]
                    })
                }
            } else {
                confirmAlert({
                    title: 'Errore',
                    message: "Si sono riscontrati problemi.",
                    buttons: [
                        {
                            label: 'Chiudi',
                            onClick: () => null
                        }
                    ]
                })
            }
        }).finally( () => {
            setLoading(false);
        });
    }

    const Save2DbPipeData = ( form_id:number, data:any ) => {
        let records:any = data;
        records.map( (record:any) => {
            AjaxSendFormsValues({
                form_id: form_id,
                token: props.token,
                values: record
            }).then( (results:any) => {
            }).catch( (errors:any) => {
                confirmAlert({
                    title: 'Errore',
                    message: "Si sono riscontrati problemi.",
                    buttons: [
                        {
                            label: 'Chiudi',
                            onClick: () => {
                            }
                        }
                    ]
                });
            }).finally( () => {
                setLoading(false);
            });
        })
        confirmAlert({
            title: 'Info',
            message: "Operazione conclusa.",
            buttons: [
                {
                    label: 'Chiudi',
                    onClick: () => null
                }
            ]
        })
    }

    const pipeDataDel = (index: number, name: string) => {
        let data2send:{[key: string]: any}= {
            id: index,
            name: name
        };
        AjaxDeletePipeDateFromDB({
            token: props.token,
            data: JSON.stringify(data2send)
        }).then( (result:any) => {
            props.extraInfoValues({
                used: props.extraInfo.used - 1,
                available: props.extraInfo.available + 1,
                total: props.extraInfo.total
            });
            confirmAlert({
                title: 'Info',
                message: result.message,
                buttons: [
                    {
                        label: 'Chiudi',
                        onClick: () => null
                    }
                ]
            });
            GetPipeData();
        }).catch( (errors:any) => {
            confirmAlert({
                title: 'Info',
                message: "Si riscontrano errori.",
                buttons: [
                    {
                        label: 'Chiudi',
                        onClick: () => null
                    }
                ]
            })
        }).finally( () => {

        })
    }

    return(
        <>{loading ? <Row>
            <Col sm={4}></Col>
            <Col sm={4}>
                <div className="d-flex justify-content-center"><CircularProgress /></div>
                <div className="d-flex justify-content-center"><span>CERCO NUOVE PRATICHE DA SCARICARE</span></div>
            </Col>
            <Col sm={4}></Col>
        </Row> :
            <MUIDataTable
                title={"LISTA CONFIGURAZIONI"}
                data={records}
                columns={columns}
                options={{
                    selectableRows: "none",
                    filter: true,
                    filterType: 'dropdown',
                    responsive: 'vertical',
                    fixedHeader: true,
                    fixedSelectColumn: true,
                    tableBodyHeight: '100%',
                    draggableColumns: {
                        enabled: true
                    },
                    expandableRowsOnClick: true,
                    textLabels: {
                        body: {
                            noMatch: (loading ? <CircularProgress/> : "Mi dispiace, nessuna corrispondenza trovata"),
                            toolTip: "Filtra",
                            //columnHeaderTooltip: column => `Sort for ${column.label}`
                        },
                        pagination: {
                            next: "Pagina Seguente",
                            previous: "Pagina Precedente",
                            rowsPerPage: "Righe per pagina:",
                            displayRows: "di",
                        },
                        toolbar: {
                            search: "Cerca",
                            downloadCsv: "Download CSV",
                            print: "Stampa",
                            viewColumns: "Schermata Colonne",
                            filterTable: "Filtra Tabella",
                        },
                        filter: {
                            all: "Tutto",
                            title: "FILTRI",
                            reset: "RESET",
                        },
                        viewColumns: {
                            title: "Mostra Colonne",
                            titleAria: "Mostra/Nascondi Colonne Tabella",
                        },
                        selectedRows: {
                            text: "riga(righe) selezionata/e",
                            // delete: "Elimina",
                            deleteAria: "Elimina righe selezionate",
                        },
                    }
                }}
            ></MUIDataTable>
        }
        </>
    )
}

export default TableDataPipeDrive