import React from 'react';
import Modal from "react-bootstrap/cjs/Modal";
import {Button} from "@material-ui/core";

interface IProps {
    show: boolean,
    title: string,
    message: string,
    OkBtn: boolean,
    TextOkBtn: string,
    CancelBtn: boolean,
    TextCancelBtn: string,
    onHide: () => void,
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const MessageInfo = (props: IProps) => {
    return(
        <Modal show={ props.show } onHide={ props.onHide }>
            <Modal.Header closeButton>
                <Modal.Title>{ props.title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { props.message }
            </Modal.Body>
            <Modal.Footer>
                { (props.OkBtn) ? <Button onClick={ props.onClick }>{ props.TextOkBtn }</Button> : null }
                { (props.CancelBtn) ? <Button onClick={ props.onHide }>{ props.TextCancelBtn }</Button> : null }
            </Modal.Footer>
        </Modal>
    )
}

export default MessageInfo