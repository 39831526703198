import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

interface IProps {
    form_id:    number,
    token:      string,
    record_id?: number
}

interface IField {
    id:             number,
    id_api:         string,
    name:           string,
    type:           string,
    label:          string,
    pholder:        string,
    required:       boolean,
    extra_options:  string,
    form_name:      string,
    ext_field:      boolean
}

async function GetExtraFieldsList(props: IProps) {

    let results:IField[] = [{
        id: 0,
        id_api: "",
        name: "",
        type: "",
        label: "",
        pholder: "",
        required: false,
        extra_options: "",
        form_name: "",
        ext_field: true
    }]

    try {
        await
            Axios.request({
                method: "POST",
                url: sosglobal.apiUrl + "api/fields_list",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    form_id: props.form_id,
                    token:   props.token,
                    record_id: props.record_id
                })
            }).then((response) => {
                if(response.statusText === 'OK' || response.statusText === '') {
                    results = response.data
                }
            })
    } catch(err) {
        console.log(err)
    }

    return (results)
}

export default GetExtraFieldsList;