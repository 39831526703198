import React from 'react';
import "./css/page404.css";
import {Page404Context} from "./context/Page404Context";

const Page404 = () => {

    //404 Context
    const page404 = React.useContext(Page404Context)

    return(
        <div>
            {
                (page404.not_found && !page404.forbidden) ? (
                    <div>
                        <div id="notfound">
                            <div className="notfound">
                                <div className="notfound-404">
                                    <h1>:(</h1>
                                </div>
                                <h2>404 - Pagina non trovata</h2>
                                <p>Il contenuto che stai cercando non è stato trovato o al momento non è disponibile.
                                    Ti preghiamo, pertanto, di riprovare.</p>
                                <a href="/">Home Page</a>
                            </div>
                        </div>
                    </div>
                ): (!page404.not_found && page404.forbidden) ? (
                    <div>
                        <div id="notfound">
                            <div className="notfound">
                                <div className="notfound-404">
                                    <h1>:(</h1>
                                </div>
                                <h2>403 - Contenuto Bloccato</h2>
                                <p>Non hai l'autorizzazione giusta per vedere il contenuto di questa pagina.<br />
                                    Ti preghiamo, pertanto, di riloggarti.</p>
                                <a href="/login">LOGIN PAGE</a>
                            </div>
                        </div>
                    </div>
                ):(null)
            }
        </div>
    )
}

export default Page404;