import Axios from 'axios';
import {sosglobal} from "../../sosGlobal";

export interface IProps {
    isLogged: boolean,
    username: string,
    email: string,
    password: string,
    token: string
    name: string,
    surname: string,
    error?: boolean,
    message: string
}

async function CheckCredential(props:IProps) {
    try {
        await
        Axios.request({
            method: 'POST',
            url: sosglobal.apiUrl + 'api/check-login',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=UTF-8",
            },
            data: JSON.stringify({
                email: props.email,
                password: props.password
            })
        }).then((response) => {
            if(response.data !== "" && response.data.id === 0) {
                props.isLogged = true;
                props.token    = response.data.token;
                props.username = response.data.username;
                const data = JSON.parse(response.data.message)
                localStorage.setItem("credential", JSON.stringify({
                    isLogged: props.isLogged,
                    email: props.email,
                    username: data.username,
                    token: data.token,
                    name: data.name,
                    surname: data.surname,
                }));
                props.error = false
            } else {
                props.isLogged = false;
                props.token    = "";
                if(response.data.id !== 0) {
                    props.error = true
                    props.message = response.data.message
                }
            }
            // console.log(response.data);
        })
    } catch (err) {
        console.log(err);
    }

    return(props)
}

export default CheckCredential;