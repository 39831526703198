import Axios from 'axios';
import {sosglobal} from "../../sosGlobal";

export interface IProps {
    username:     string,
    email:        string,
    password:     string,
    new_password: string,
    name:         string,
    surname:      string,
    token:        string,
    error?:       boolean
}

async function UpdateProfile(props:IProps) {

    try {
        await
            Axios.request({
                method: 'POST',
                url: sosglobal.apiUrl + 'api/user-update',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    username:     props.username,
                    email:        props.email,
                    password:     props.password,
                    new_password: props.new_password,
                    name:         props.name,
                    surname:      props.surname,
                    token:        props.token
                })
            }).then((response) => {
                if(response.data !== "" && response.data.id === 0) {
                    props.error = false
                    console.log(response.data)
                } else {
                    props.error = true
                    console.log(response.data)
                }
            })
    } catch (err) {
        console.log(err);
    }

    return(props)
}

export default UpdateProfile;