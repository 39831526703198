import Axios from "axios";
import {sosglobal} from "../../sosGlobal";

export interface IProps {
    isLogged: boolean,
    username: string,
    email: string,
    token: string,
    error?: boolean
}

async function LogOut(props:IProps) {
    try {
        await
            Axios.request({
                method: 'POST',
                url: sosglobal.apiUrl + 'api/logout',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    email: props.email,
                    token: props.token
                })
            }).then((response) => {
                if(response.data.id === 0){
                    props.isLogged  = false;
                    props.email     = "";
                    props.username  = "";
                    props.token     = "";
                    props.error     = false;
                    localStorage.removeItem("credential")
                } else {
                    props.error = true;
                    console.log(response.data);
                }
            })
    } catch (err) {
        console.log(err);
    }

    return (props);
}

export default LogOut;