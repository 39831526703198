import React, {ChangeEvent} from 'react';
import RetrieveUsersList from "../user_components/AjaxRetriveUsersList";
import {Container, Form, InputGroup} from "react-bootstrap";
import {Control} from "react-hook-form";

interface IProps {
    token: string,
    label: string,
    userSelected: React.Dispatch<React.SetStateAction<IUser | undefined>>
    control?: Control<Record<string, any>>,
}

interface IUser {
    name: string,
    email: string,
}

interface IUserStruct {
    email: string,
    name: string,
    surname: string,
    fullName: string
}

const UsersListDropDown = (props:IProps) => {

    const [selectedUser, setSelectedUser] = React.useState<IUser>();
    const [users, setUsers] = React.useState<IUserStruct[]>([]);

    const selectedHandle = (name: string, id: string) => {
        setSelectedUser({
            name: name,
            email: id
        })
        props.userSelected({
            name: name,
            email: id
        });
        props.control?.setValue(
            "user",
            id
        );
    }

    const retrieveUsersList = () => {
        RetrieveUsersList({
            token: props.token,
        }).then(result => {
            setUsers([]);
            if (result !== undefined) {
                if (result.type === "Success") {
                    const userData: IUserStruct[] = result.data;
                    userData.map((user: IUserStruct, index: number) => {
                        setUsers(prevState => [
                            ...prevState,
                            user
                        ]);
                    })
                }
            }
        })
    }
    React.useEffect(retrieveUsersList, []);

    return(
        <Container>
            <Form.Group controlId={"userList"}>
                <Form.Label>{props.label}</Form.Label>
                <InputGroup>
                    <select
                        className={"browser-default custom-select"}
                        onChange={(data: ChangeEvent<HTMLSelectElement>) => selectedHandle(data.target.selectedOptions[0].innerText, data.target.value)}
                    >
                        <option></option>
                        {
                            users.map((user: IUserStruct, index: number) => {
                                return <option value={user.email}
                                               key={index}>{user.fullName + " - " + user.email}</option>
                            })
                        }
                    </select>
                </InputGroup>
            </Form.Group>
        </Container>
    )

}

export default UsersListDropDown