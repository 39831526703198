import Axios from 'axios';
import {sosglobal} from "../../sosGlobal";

export interface IProps {
    token:        string,
    users?:       [IUser],
    error?:       boolean,
    errnum?:      number
}

export interface IUser {
    id: string,
    name: string,
    surname: string,
    email: string
}

async function GetTeamList(props:IProps) {

    try {
        await
            Axios.request({
                method: 'POST',
                url: sosglobal.apiUrl + 'api/get-team-list',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                data: JSON.stringify({
                    token: props.token
                })
            }).then((response) => {
                if(response.data !== "" && response.data.id === 0) {
                    props.error  = false
                    props.errnum = 0
                    props.users  = JSON.parse(response.data.message)
                    // console.log(response.data)
                } else {
                    props.error  = true
                    props.errnum = response.data.id
                    // console.log(response.data)
                }
            })
    } catch (err) {
        console.log(err);
        if (Axios.isCancel(err)) {
            //cancelled
        } else {
            throw err;
        }
    }

    return(props)
}

export default GetTeamList;