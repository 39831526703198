import React from 'react';
import AjaxGetDropDownBaseForm from "./AjaxGetDropDownBaseForm";
import {UserContext} from "../context/UserContext";
import {useForm} from "react-hook-form";
import FormDropDownView from "./FormDropDownView";

interface IDropDownField {
    id: number,
    field_name: string,
    value: string
}

interface IResult {
    id: number,
    type: string,
    message: string,
    data: []
}

const FormDropDownManager = () => {

    //User Context
    const {infos, setInfos}: any = React.useContext(UserContext)
    //Init UseForm
    const {control} = useForm()

    const [dropValues, setDropValues] = React.useState<IDropDownField[]>(
        [
            {
                id: 1,
                field_name: "tematica",
                value: ""
            },
            {
                id: 2,
                field_name: "problematica",
                value: ""
            },
            {
                id: 3,
                field_name: "provenienza",
                value: ""
            },
            {
                id: 4,
                field_name: "stato_pratica",
                value: ""
            },
            {
                id: 5,
                field_name: "procura",
                value: ""
            },
            {
                id: 6,
                field_name: "priorita",
                value: ""
            },
            {
                id: 7,
                field_name: "fan",
                value: ""
            },
            {
                id: 8,
                field_name: "avvocato",
                value: ""
            },
            {
                id: 9,
                field_name: "domiciliatario",
                value: ""
            }
        ])

    const GetDropDownValues = () => {
        AjaxGetDropDownBaseForm({
            token: infos.token
        }).then((result: IResult) => {
            if (result.type === "Success") {
                result.data.map((dropdownvalues: IDropDownField, index: number) => {
                    dropValues.filter((filteredValue: IDropDownField) => filteredValue.field_name === dropdownvalues.field_name).map(
                        (dropField: IDropDownField, index: number) => {
                            dropField.id = dropdownvalues.id;
                            dropField.value = dropdownvalues.value;
                            control.setValue(dropField.field_name, dropField.value);
                            return dropField;
                        })
                    return dropdownvalues;
                })
                setDropValues(dropValues);
            }
        })
    }

    React.useEffect(GetDropDownValues, []);

    return (
        <FormDropDownView
            dropValues={dropValues}
            control={control}
        />
    )
}

export default FormDropDownManager;