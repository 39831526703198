import React from 'react';
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {Control, Controller} from "react-hook-form";
import UpdateDropDownBaseForm from "./AjaxDropDownBaseFormUpdate";
import {UserContext} from "../context/UserContext";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

interface IProps {
    dropValues: IDropDownField[],
    control: Control<Record<string, any>>,

}

interface IDropDownField {
    id: number,
    field_name: string,
    value: string
}

const FormDropDownView = (props:IProps) => {

    //User Context
    const {infos, setInfos}: any = React.useContext(UserContext)

    const UpdateHandler = (id: number, field_name: string) => {
        let arrayFields  = props.control.getValues();
        let value:string = arrayFields[field_name];
        UpdateDropDownBaseForm({
            token: infos.token,
            data: {
                id: id,
                field_name: field_name,
                value:      value
            }
        }).then(result => {
            confirmAlert({
                title: 'Info',
                message: result.message,
                buttons: [
                    {
                        label: 'Chiudi',
                        onClick: () => null
                    }
                ]
            })
        })
    }

    return (
        <Form className={"forms-view"}>
            {
                props.dropValues.map((dropField: IDropDownField, index: number) => {
                    return (
                        <Row>
                            <Col sm={2}>
                                <Form.Group controlId={dropField.id.toString()} key={index}>
                                    <Form.Label>Nome Campo</Form.Label>
                                    <InputGroup>
                                        <Controller
                                            name={dropField.id.toString()}
                                            defaultValue={dropField.field_name}
                                            control={props.control}
                                            render={({onChange, value}) => (
                                                <input
                                                    name="Nome Campo"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Nome Campo"
                                                    aria-label="Nome Campo"
                                                    aria-describedby="basic-addon1"
                                                    value={value}
                                                    disabled
                                                />
                                            )}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col sm={8}>
                                <Form.Group controlId={dropField.field_name} key={index}>
                                    <Form.Label>Valore</Form.Label>
                                    <InputGroup>
                                        <Controller
                                            name={dropField.field_name}
                                            defaultValue={dropField.value}
                                            control={props.control}
                                            render={({onChange, value}) => (
                                                <input
                                                    name="value"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Lista valori separati da ;"
                                                    aria-label="Lista Valori"
                                                    aria-describedby="basic-addon1"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col sm={2} className={"pt-4"}>
                                <div className={"pt-2"}>
                                    <Button
                                        type={"button"}
                                        variant={"primary"}
                                        name={"btn_"+dropField.field_name}
                                        onClick={() => UpdateHandler(dropField.id, dropField.field_name)}
                                    >Modifica
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    )
                })
            }
        </Form>
    )
}

export default FormDropDownView;