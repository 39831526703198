//Global Variables
type sosGlobaVars = {
    apiUrl: string
}

export const sosglobal: sosGlobaVars = {
    // apiUrl: "http://localhost/sosavvocato/"
    apiUrl: "https://sosavvocato.difensoreconsumatori.it/services/"
    // apiUrl: "https://apisosavvocato.difensoreconsumatori.it/"
};

Object.freeze(sosglobal);